:root {
    --theme-subcolor: #0071e3;
    --common-color: #0071e3;
    --theme-color: #0f4fa1;
    --common-button-bgcolor: #0f4fa1;
    --common-button-txtcolor: #fff;
    --focus-input-color: #0071e3;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins-SemiBold.eot");
    src: url("../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-SemiBold.woff") format("woff"), url("../fonts/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins-Light.eot");
    src: url("../fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Light.woff") format("woff"), url("../fonts/Poppins-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins-Regular.eot");
    src: url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Regular.woff") format("woff"), url("../fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins-Bold.eot");
    src: url("../fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Bold.woff") format("woff"), url("../fonts/Poppins-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins-Medium.eot");
    src: url("../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Medium.woff") format("woff"), url("../fonts/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

* {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: "Arial", Helvetica, Helvetica Neue, sans-serif;
    color: #616161;
    font-size: 13px;
}

.mentalHeader {
    overflow: visible;
    width: 100%;
    position: relative;
    z-index: 3;
}

.headerTop {
    background: #32a857;
    padding: 5px 20px;
    text-align: right;
    color: #fff;
    font-size: 15px;
    font-weight: 300;
}

.headerTop a {
    color: #fff;
    text-decoration: none;
}

.mentalToggle {
    display: none;
    cursor: pointer;
}

.mentalLogo {
    /* text-align: center; */
    padding: 6px 0;
}

.mentalLogo a {
    outline: 0;
}

.subMenuList {
    font-size: 13px;
}

.metalContent {
    clear: both;
}

.mentalMenu {
    width: 100%;
    text-align: right;
    /* padding: 0 0 30px; */
    /* margin-bottom: 5px; */
    background-color: #0f4fa1;
}

.mentalMenu li {
    padding: 9px;
    display: inline-block;
    position: relative;
    font-size: 13px;
}


/* .mentalMenuAtive:hover{
  background-color: #002754;
 
} */


/* .mentalMenu li:hover, */

.mentalMenu li a:hover {
    background-color: #002754;
}

.mentalLogo img {
    width: 185px;
}

.mentalMenu ul {
    padding: 0;
    margin: 0;
    list-style: none;
    justify-content: right;
}

.mentalMenu li:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    height: 22px;
    width: 1px;
    background: #fff;
    top: 50%;
    transform: translateY(-50%);
}

.mentalMenu li:nth-child(1)::after,
.mentalSubMenu li:after {
    content: unset;
}

.mentalMenu li a {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    padding: 10px 15px;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Arial", Helvetica, Helvetica Neue, sans-serif;
}

.mentalMenu li .rhealth-headTexttrnfrm {
    text-transform: none;
}

.mentalSubMenu li a:hover {
    color: #000;
}

.dropArrow:after {
    content: "";
    position: absolute;
    top: 7px;
    width: 0;
    right: 0;
    margin: auto;
    left: -11px;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #170f07 transparent transparent transparent;
}

.mentalMenu li:hover .dropArrow:after {
    border-color: #2698a0 transparent transparent transparent;
}

.dropArrow {
    width: 16px;
    height: 18px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    margin: 0 0 0 -10px;
    cursor: pointer;
}

.mentalSubMenu {
    position: absolute;
    /* background: #2698a0; */
    width: 200px;
    right: 0;
    text-align: left;
    display: none;
    z-index: 1;
    top: 100%;
    background-color: #000;
    /* border-radius: 10px;
  box-shadow: 2px 2px 10px #c3c3c3; */
}

li.menu-role .menu-role-link {
    background-color: unset;
    color: #fff;
    /* padding: 1px 13px; */
    padding: 10px 15px;
    position: relative;
}

.mentalSubMenu li {
    display: block;
    font-size: 15px;
    text-align: left;
    padding: 0;
}

.mentalSubMenu li:hover {
    background-color: #0f4fa1;
    ;
    /* padding: 7px 0; */
}

.mentalSubMenu li a:hover {
    background-color: #0f4fa1;
    color: #fff;
    text-transform: capitalize;
}

.mentalSubMenu li a {
    display: block;
    text-transform: capitalize;
    /* color: #fff; */
    font-weight: 400;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}

.footermargin {
    margin-top: 64px;
}

.mentalBannerImage img {
    min-height: 500px;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.contentCoverWrapper {
    /* min-height: 600px; */
    display: block !important;
    padding-top: 0;
}

.whiteParallax {
    /* height: 450px; */
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
}

.parallaxContent {
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 2;
    /* top: 50%; */
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}

.parallaxContent h2 {
    color: #1a4ea0;
    font-size: 37px;
    width: 45%;
    line-height: 47px;
    text-align: left;
    padding-left: 80px;
    font-weight: 400;
    font-family: inherit;
    margin-bottom: 30px;
}

.bannerSearchBar {
    width: 45%;
    margin: auto;
    background: rgba(255, 255, 255, 0.81);
    padding: 20px;
    border-radius: 10px;
    margin-top: 30px;
    position: relative;
}

.bannerSearchIcon {
    position: absolute;
    font-size: 23px;
    top: 18px;
    right: 20px;
}

.bannerSearchBar .spotLightSearchDrop3 {
    width: 100%;
    margin: 0;
}

.spotLightSearchDrop3 {
    position: relative;
    display: inline-block;
    width: 45%;
    text-align: left;
    margin-top: -6px;
    margin-bottom: 0;
    margin-left: 10px;
}

.bannerSearchBar input[type="text"] {
    border-radius: 5px;
    border: 1px solid #2e5fa7;
    border-style: solid;
    color: #000008;
    font-size: 16px;
    font-family: "Arial", Helvetica, Helvetica Neue, sans-serif;
    height: 55px;
    padding: 3px 15px 3px 30px;
    width: 100%;
    text-align: left;
    margin-bottom: 0;
    background: transparent;
    position: relative;
}

.bannerLogo,
.footerParallaxLogo {
    width: 180px;
    position: absolute;
    right: 80px;
    top: -15%;
    padding: 10px;
    height: 180px;
    background: #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    z-index: 2;
}

.bannerLogo img,
.footerParallaxLogo img {
    width: 100%;
    margin-top: 5px;
}

.homeSpotlightCover {
    width: 100%;
    background: #f3f3f3;
    /* padding: 20px; */
}

.homeSpotlightCover .homeSpotlightBlck {
    font-size: 0;
    text-align: center;
    margin-top: -108px;
}

.homeSpotlightBlck {
    width: 100%;
    background: #f4f3f3;
    padding: 20px 60px;
    border-bottom: 0;
}

.homeSpotlightCover .homeSpotList {
    display: inline-block;
    padding: 5px 17px;
    width: 20%;
    vertical-align: top;
    font-size: 14px;
    opacity: .9;
    height: 216px;
    background: #0f4fa1;
}

.homeSpotList {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-size: 14px;
    opacity: .9;
    height: 216px;
    background: var(--common-bg-color);
}

.homeSpotlightCover .homeSpotList a {
    color: #fff;
    text-decoration: none;
}

.homeSpotlightCover .homeSpotList:nth-child(even) {
    opacity: .7;
}

.homeSpotlightCover .homeSpotlightBlck .homeSpotList .homeSpotHead {
    font-size: 120%;
    text-transform: uppercase;
    display: block;
    color: #fff;
}

.homeSpotlightCover .homeSpotlightBlck .homeSpotList .homeSpotPara {
    margin-bottom: 10px;
    font-size: 100%;
    display: block;
    margin-top: 15px;
    color: #fff;
}

.homeSpotlightBlck .homeSpotList .homeSpotIcon {
    margin: auto;
    font-size: 38px;
    /* margin-bottom: 20px; */
}

.homeSpotlightBlck .homeSpotList .homeSpotIcon img {
    width: 38px;
}

.specialitiesWrapper {
    padding-top: 30px;
    background: #f3f3f3;
}

.specialityMainHead {
    padding: 6px 0 0;
    text-align: center;
}

.specialityMainHead h3 {
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    position: relative;
    display: inline-block;
    padding-bottom: 15px;
    margin: 20px 0;
    color: #0f4fa1;
}

.specialityMainHead h3:after {
    content: "";
    position: absolute;
    background: #f15a22;
    width: 32%;
    height: 3px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
}

.specialtyImg img {
    width: 98px;
    text-align: center;
    margin: 0 auto;
}

.specialtySubHead h4 {
    font-weight: 600;
    font-size: 17px;
    color: #000;
    padding: 10px 0;
    text-align: center;
}

.specialityContent {
    display: inline-block;
    /* width: 24%; */
    /* text-align: center; */
}

.specialityContent a .specialtySubHead h4:hover {
    color: #0f4fa1;
    text-decoration: transparent;
}

.specialityContent a {
    text-decoration: none;
}

.bg-whiteExperts {
    background-color: #fff;
}

.expertWraper {
    background-color: #f3f2f4;
    padding-top: 70px;
    padding-bottom: 30px;
}

.expertHeadDiv h3 span {
    background-color: #a6a6a6;
    padding: 5px 50px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-size: 18px;
    font-weight: 300;
    color: #000;
}

.expertHeadDiv {
    position: relative;
    top: -15px;
    text-align: center;
}

.teamContent {
    /* display: inline-block; */
    margin: 0 15px 20px;
    text-align: center;
    min-height: 85px;
    clear: both;
    overflow: hidden;
}

.teamContent a {
    color: #000;
}

.teamContent a:hover {
    color: #0f4fa1;
    text-decoration: none;
}

.teamContent h3 {
    font-size: 15px;
    margin: 18px 0 0;
    font-weight: 600;
}

.teamContent h4 {
    font-size: 13px;
    margin: 10px 0;
}

.teamContentOuter {
    position: relative;
}

.slick-slide .teamContentOuter:before {
    position: absolute;
    content: "";
    background-color: #bfbfbf;
    width: 2px;
    height: 230px;
    top: 4px;
    left: -2px;
}

.aboutVideo {
    display: inline-block;
    width: 50%;
    vertical-align: top;
}

.aboutContent {
    display: inline-block;
    width: 46%;
    vertical-align: top;
    padding-left: 15px;
}

.abouttext p span {
    font-weight: 600;
}

.headAbout {
    text-align: center;
    position: relative;
    padding: 0 0 15px;
}

.headAbout .aboutPgmHead {
    font-size: 20px;
    font-weight: 600;
    color: #0f4fa1;
}

.headAbout:after {
    content: "";
    position: absolute;
    background: #f15a22;
    width: 12%;
    height: 3px;
    left: 0;
    right: 0;
    top: 32px;
    margin: 0 auto;
    bottom: 0;
}

.abouttext p {
    margin: 0 0 15px;
    font-size: 14px;
}

.aboutVdobordr {
    background: #0f4fa1;
    margin-top: 25px;
    margin-bottom: 30px!important;
    float: left;
    padding: 10px 2px;
    min-height: 170px;
    position: relative;
}

.bannerRightLogo,
.footerParallaxLogo {
    top: auto;
    bottom: 10%;
}

.bouquetHead {
    text-align: center;
    position: relative;
}

.bouquetHead h2 {
    font-size: 20px;
    color: #000;
    font-weight: 600;
}

.bouquetWrapper {
    background-color: #fdfdfd;
}

.bouquetHead:after {
    content: "";
    position: absolute;
    background: #f15a22;
    width: 5%;
    height: 3px;
    left: 0;
    right: 0;
    top: 32px;
    margin: 0 auto;
    bottom: 0;
}

.bg-whitebouquet {
    background-color: #fff;
    padding: 0;
}

.bouquetContent {
    display: inline-block;
    width: 20%;
    text-align: center;
}

.bouquetContent img {
    width: 100%;
}

.footerWrapper {
    width: 100%;
    background: #484848;
    padding: 15px 0;
    margin-top: auto;
}

.footerLinks {
    text-align: center;
    position: relative;
}

.footerLinks ul li {
    list-style-type: none;
    display: inline;
    padding: 5px 5px;
    position: relative;
}

.footerLinks ul li a, .footerLinks ul li .footer-a {
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    padding: 0 9px;
}

.footerLinks ul li:after {
    content: "";
    position: absolute;
    background: #fff;
    width: 1px;
    height: 24px;
    /* top: 0;
  bottom: 0; */
    margin: 0 auto;
    bottom: 0;
}

.footerLinks ul li:nth-last-child(1)::after {
    content: '';
    width: 0;
}

.powererdbyouter {
    display: inline-block;
    width: 85px;
    padding-left: 5px;
}

.imgResponsive {
    width: 100%;
    height: auto;
    filter: grayscale(100%);
}

.powerdBy a span img {
    opacity: 1;
}

.teamImg img {
    text-align: center;
    margin: 0 auto;
}

.teamText {
    text-align: center;
}

.powerdBy {
    padding-left: 15px;
    font-size: 12px;
    color: #7f7f7f;
    position: relative;
    top: 17px;
}

.aboutWrapper {
    padding-bottom: 40px;
}

.teamImg img {
    padding: 0 30px;
    width: 100%;
    object-fit: cover;
    height: 190px;
}

.teamImg {
    border-bottom: 1px solid #f7f7f7;
}

.specialitiesWrapper .slick-next:before,
.bouquetWrapper .slick-next:before {
    display: none !important;
}

.specialitiesWrapper .slick-next,
.specialitiesWrapper .slick-prev,
.bouquetWrapper .slick-next,
.bouquetWrapper .slick-prev {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 1;
    width: 30px;
    height: 45px;
    opacity: 0.5;
}

.specialitiesWrapper .slick-next,
.bouquetWrapper .slick-next {
    right: -40px;
    background-image: url("https://purplehealthfiles.s3.amazonaws.com/test/rHealthAssistant/next.png");
}

.specialitiesWrapper .slick-next:hover,
.bouquetWrapper .slick-next:hover,
.specialitiesWrapper .slick-next:active,
.bouquetWrapper .slick-next:active,
.specialitiesWrapper .slick-next:focus,
.bouquetWrapper .slick-next:focus {
    background-image: url(https://purplehealthfiles.s3.amazonaws.com/test/rHealthAssistant/next.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 1;
    width: 30px;
    height: 45px;
    opacity: 0.5;
}

.specialitiesWrapper .slick-prev:before,
.bouquetWrapper .slick-prev:before {
    display: none !important;
}

.specialitiesWrapper .slick-prev,
.bouquetWrapper .slick-prev {
    background-image: url("https://purplehealthfiles.s3.amazonaws.com/test/rHealthAssistant/prev.png");
}

.specialitiesWrapper .slick-prev:hover,
.bouquetWrapper .slick-prev:hover,
.specialitiesWrapper .slick-prev:focus,
.bouquetWrapper .slick-prev:focus {
    background-image: url("https://purplehealthfiles.s3.amazonaws.com/test/rHealthAssistant/prev.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 1;
    width: 30px;
    height: 45px;
    opacity: 0.5;
}

.bg-whiteExperts .slick-next:before {
    display: none !important;
}

.bg-whiteExperts .slick-next {
    right: -11px;
    /* background-color: #555555; */
    width: 30px;
    height: 45px;
    background-image: url("https://purplehealthfiles.s3.amazonaws.com/test/rHealthAssistant/nextarow.png");
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
}

.bg-whiteExperts .slick-next:hover,
.bg-whiteExperts .slick-next:focus {
    background-image: url("https://purplehealthfiles.s3.amazonaws.com/test/rHealthAssistant/nextarow.png");
    width: 30px;
    height: 45px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
}

.bg-whiteExperts .slick-prev:before {
    display: none !important;
}

.bg-whiteExperts .slick-prev {
    left: -11px;
    /* background-color: #555555; */
    width: 30px;
    height: 45px;
    background-image: url("https://purplehealthfiles.s3.amazonaws.com/test/rHealthAssistant/prevarow.png");
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
}

.bg-whiteExperts .slick-prev:hover {
    background-image: url("https://purplehealthfiles.s3.amazonaws.com/test/rHealthAssistant/prevarow.png");
    z-index: 1;
}

.bannerSerchboxDiv {
    width: 100%;
    margin: auto;
    background: rgba(255, 255, 255, 0.85);
    text-align: left;
    display: none;
    position: absolute;
    left: 0;
    display: none;
    min-width: 100%;
    max-width: 100%;
    top: 75.5px;
    z-index: 9999;
}

.bannerSerchboxDiv .simpleSearchBlock .searchBlock {
    padding: 2px 5px;
    text-transform: capitalize;
    font-size: 13px;
    color: #393939;
    float: left;
    width: 100%;
}

.bannerSerchboxDiv .simpleSearchBlock .searchBlock:hover {
    background: #fafafa;
    cursor: pointer;
}

.bannerSerchboxDiv .simpleSearchBlock {
    overflow-y: scroll;
    height: 185px;
    z-index: 1;
}

.bannerSerchboxDiv .simpleSearchBlock .searchText {
    display: inline-block;
    color: #393939;
    float: left;
    padding: 5px;
    text-align: left;
    font-size: .9em;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 69%;
    letter-spacing: 0.6px;
}

.bannerSerchboxDiv .simpleSearchBlock .searchTextLink {
    display: inline-block;
    color: #bebbbb;
    float: right;
    padding: 5px;
    text-align: left;
    font-size: .9em;
    line-height: 20px;
}

.bg {
    display: none;
}

.subMenuMain {
    background-color: #181919;
    color: #000;
    text-transform: capitalize;
    border-radius: 0;
    box-shadow: none;
    top: 100%;
    padding: 0;
    left: 0;
}

.arrow-up:before,
.arrow-up:after {
    content: unset;
}

.mentalMenu .subMenuList a {
    padding: 7px 10px;
    text-transform: capitalize;
    font-size: 15px;
}

.footerParallax {
    background-image: url("https://purplehealthfiles.s3.amazonaws.com/test/rHealthAssistant/banner2.jpg");
    background-attachment: fixed;
    position: relative;
    height: 450px;
    background-position: center;
}

.main-wrapper1 .react-datepicker-wrapper {
    width: 100% !important;
}

.support_div {
    margin-right: 35px;
}

.QckLinksNew img {
    margin-right: 10px;
}

.dashboard-right .form-section {
    margin-bottom: 25px;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.purpleWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.imgshowhide span {
    display: inline-block;
}

.imgshowhide i {
    margin-left: 5px;
}

.custom_datepicker .react-datepicker-wrapper {
    width: 100% !important;
}

.appntmnt-list-tab.all-appntmnt {
    margin-bottom: 5px;
}

li.menu-role .menu-role-link {
    border-radius: 0px;
}

.tablehead-hspmng {
    border-top: 0px !important;
}

.reactdate_pick .react-date-dctr-width {
    width: 260px !important;
}

.profile_page {
    padding: 30px 0px;
    width: 80%;
    margin: auto;
}

.profile_page .border_btn {
    font-size: 14px;
    padding: 8px 30px;
    min-width: auto;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.profile_page .border_btn:last-child {
    margin-right: 0px;
}

.profile_page .btn_gray {
    border: 1px solid #393939 !important;
    color: #393939;
}

.form-section .label-text {
    font-size: 13px;
}

.form_section_start {
    border: 1px solid gainsboro;
    padding: 10px 10px;
    margin-top: 18px !important;
}

.memberHead {
    font-size: 19px;
    color: grey;
    padding: 10px 10px;
}

.form_section_start input {
    border: 1px solid #dddd;
    padding: 7px 5px;
    border-radius: 5px;
    font-size: 12px
}

.form_section_start input[disabled=""],
.form_section_start input[readonly=""] {
    background: #fff;
}

.form_section_start .profileHeadTitle {
    width: 50%;
}

.form-section label.text-uppercase {
    font-size: 12px;
    font-weight: 600;
}

.border_edit:hover {
    background: #0c4da2;
    color: #fff;
}

.form-section {
    padding: 10px 10px;
    padding-bottom: 4px;
}

.reactdate_pick .react-datepicker-wrapper {
    width: 260px !important;
}

.hspinfodoctor-contentdiv,
.hsptaltimeHead {
    position: relative;
}

.absolue_adding {
    right: -14px;
    position: absolute;
}

.videocall_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(18 16 16 / 40%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    backdrop-filter: blur(100px);
}

.videocall_div {
    background: #fff;
    width: 400px;
    border-radius: 5px;
}

.videocall_footer button {
    border: 0;
    color: #fff;
    padding: 5px 20px;
    border-radius: 3px;
    margin-right: 10px;
    border: 1px solid transparent;
    transition: .5s;
    min-width: 90px;
}

.videocall_footer button:last-child {
    margin-right: 0px;
}

.bttn_ok {
    background: #d20000;
}

.bttn_ok:hover {
    border: 1px solid #0d6efd;
    background: #fff;
    color: #000;
}

.videocall_body {
    padding: 10px 15px;
    min-height: 80px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
}

.videocall_footer {
    padding: 4px 15px 15px;
    text-align: center;
    border-top: 1px solid rgb(146 135 135 / 10%);
}

.bttn_close {
    color: #fff;
    background-color: #6c757d;
}

.bttn_close:hover {
    border: 1px solid #6c757d;
    background: #fff;
    color: #000;
}

.videocall_header {
    padding: 6px 15px;
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px solid rgb(146 135 135 / 10%);
    background: #5482c7;
    color: #fff;
    border-radius: 5px 5px 0px 0px;
}

.label_smssetngbold {
    padding: 0 5px;
    width: 180px !important;
}

.smssetngsTop select {
    height: 32px;
}

.smssetngsWrap {
    width: 100%;
}

.AddNewHspbtnDiv button {
    font-size: 14px;
    font-weight: 700;
}

.closepopupqual {
    position: absolute;
    top: 10px;
    right: 10px;
}

.formButton:hover {
    background: var(--common-button-bgcolor);
    color: #fff;
}

.formButton-grey:hover {
    background: #cccccc;
    border: 1px solid #ccc;
}

.formButton {
    transition: .5s;
}

.bttn_Refresh {
    background: #138746;
}

.bttn_Refresh:hover {
    border: 1px solid #0d6efd;
    background: #fff;
    color: #000;
}

.custom_btnupload {
    position: relative;
    width: auto;
}

.custom_btnupload input {
    position: absolute;
    width: 100%;
    left: 0;
    opacity: 0;
}

.popup-overlay {
    z-index: 1060;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.addbanerhspfieldinput,
.addrepfieldinput,
.qualifcatin-popupInput {
    border: 1px solid #d3d3d3;
}


/* .input-design, */

.react-datepicker__input-container input {
    margin-bottom: 0px;
}

.width_custom {
    width: 91%;
}

.qualifcatin-popupInput {
    width: 100%;
    padding: 6px 10px
}

.office_hourhead {
    font-size: 24px;
    background: #f2f2f2;
    font-weight: 500;
    margin-bottom: 20px !important;
}

.main_head_doc {
    font-size: 25px;
}

.serviceWrap ul li button {
    padding: 3px 30px;
    font-size: 16px;
}

.serviceWrap ul li label {
    font-size: 12px;
    font-weight: 600;
}

.input_numberhidden input[type="number"]::-webkit-inner-spin-button,
.input_numberhidden input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.bg-clr-wthHead {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.healthpageDocName {
    font-size: 17px;
}

.dr-profileImg {
    width: 35px;
    height: 35px;
}

.LinksList li span {
    font-size: 15px;
}

.read_onlywhite[readonly=""] {
    background: #fff;
}

.quick_content {
    background: #f4f4f4;
    padding: 5px 10px;
    text-align: center;
    font-weight: 500;
    width: 94%;
}

.body span.dashMenuNotfcn {
    width: 25px;
    height: 25px;
}

.delete-medicine {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.3;
}

.main-wrapper-smwidth1,
.main-wrapper-smwidth,
.main-wrapper,
.main-wrapper1,
.drListMain,
.blog-wrapper {
    height: calc(100% - 50px);
}
.main-wrapper1{
    margin-bottom: 50px;
    padding-bottom: 5px;
}
.appnt-btn {
    width: auto;
    padding: 1px 8px;
}

.pendingLink {
    color: var(--common-button-bgcolor);
    padding: 5px 25px;
    border-radius: 20px;
    text-transform: capitalize;
    border: 1px solid var(--common-button-bgcolor);
    background-color: #fff;
    text-decoration: none;
    font-size: 14px;
    display: inline-block;
    float: right;
}

.chat_li a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat_li img {
    width: 24px;
    filter: invert(1);
}

.chat_li .dashMenuNotfcn {
    top: 0;
    right: -10px
}

.chat_li::after {
    background: transparent !important;
}

.appntmnt-list-tab {
    display: flex;
    align-items: center;
}

.simple_step {
    width: 450px;
    margin: 50px auto;
}

.simple_stepheader {
    text-align: center;
    font-size: 23px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 0 40px;
    color: #0e4ea0;
    font-family: sans-serif;
}

.step_list {
    margin: 0 0 50px;
    position: relative;
    height: auto;
    color: #404040;
    font-size: 20px;
    font-family: sans-serif;
    display: flex;
}

.step_count {
    width: 80px;
    background: #0e4ea0;
    color: #fff;
    font-size: 35px;
    text-align: center;
    font-weight: 600;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.step_content {
    width: calc(100% - 80px);
    padding: 0px 20px;
}

.step_button {
    text-align: center;
    padding: 20px 0 0;
}

.step_button a {
    text-decoration: none;
    display: inline-block;
    padding: 10px 45px;
    background: #0f4fa1;
    color: #fff;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    font-size: 15px;
    letter-spacing: 1px;
}

.popup_content {
    padding: 0 !important;
    border-radius: 3px !important;
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.popup_content .header {
    font-size: 20px;
    color: #3498db !important;
}

.modal_content h2 {
    font-size: 40px;
    color: #0c4da2;
    margin: 0 0 15px;
    font-family: "open_sansbold", sans-serif;
    font-weight: bolder;
    text-align: center;
}

.modal_content {
    text-align: center;
    font-family: Poppins;
}

.modal_content h3 {
    text-align: left;
    color: #404040;
    font-size: 18px;
    font-weight: 500;
    font-family: system-ui;
}

.modal_content .mediaWrap {
    margin-top: 60px;
}

.modal_content p b {
    font-size: 16px;
    padding-left: 0;
    padding-top: 10px;
    line-height: 30px;
    color: #555;
}

.modal_content p {
    font-size: 16px;
    padding-left: 0;
    padding-top: 10px;
    line-height: 30px;
    color: #555;
}

.popup_content .close-popup {
    background-color: #0c4da2;
    width: 35px;
    height: 35px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 18px;
    font-weight: bold;
}

.text-decoration-none:focus,
.textwrappergroup:active {
    outline: none;
}

.LinksList li span.dashMenuNotfcn {
    font-size: 10px;
    width: 25px;
    height: 25px;
}

.blg-wrapr-height .blog-wrapper {
    height: auto;
}

.delete-medicine-popup {
    position: absolute;
    right: -11px;
    top: -14px;
}

.delete-medicine-popup-img {
    width: 28px;
    height: 28px;
}

.btn-edit-bg {
    border: none;
    background: transparent;
}

.doctorDetails-favourite img {
    background-color: #fff;
    padding: 3px;
    object-fit: cover;
    width: 150px;
    height: 150px;
    margin-top: 8%;
    margin-bottom: 5%;
}

.Dr-details a {
    text-decoration: none;
    color: #000;
}

.Dr-details {
    padding-bottom: 50px;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    max-width: 33.33%;
    flex: 1;
    margin: 40px;
}

.drListHearts {
    display: block;
    position: absolute;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    top: 20px;
}

.drListHearts input,
.checkbox-main input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.drListHearts .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAlCAYAAAA0lXuOAAAABHNCSVQICAgIfAhkiAAAAwlJREFUWEfVmK1zFEEQxV8EIggQRCBAgAABAoMIAgQIDP8vIhFEBBEEAkQigkAkVIEAAQJD/bamr/rm5mNndjcJbS65m+l5/fpzZktluSHpriQ+b0q6Fpb/lPRD0nn4rKgZ9t2RdEvSjtPzXdLfoOdb+DupaytzAgofBKU1EBz2WdKvzEL03HfgcvoAfCrpJLUgBfSepMfRYpiDRRPYxRhjmO8/Svrq1vDbbvCEfW2eABTCmtuSrrt9rHkfsxsDfRJczT6UHUv6kqGAQzDKs2VgtyU9cwAIk08F1jH6YTCe435LOpT0x872QIlFgCJJqzKAid+nDhRgMQDWEQDmjI1VEiYANgwHMVAYeBFc0QLS9MDuy0QcxuGQsXXtax96xCzxL2PUu/xdwUWlg2AWY02I66MxyBJr8BCxS/jt8wlQ2HwVFpMMsNAr5roer/gzPSby5ASgPjb3fAB3oiUMLKs7VQzbjFWMPgDo2hdTNM+8d41AgFJGKA+rwJ35wF51YAIbcgjQN+GfIRZ6tS60z7Ad/S9AjwH6OtS/q8YoSQm2lestRqeWprm9vxGjVuzprxTXqyK+Q73F9XQAShTS25WWMO55mBcYaIas9336TNKHJU5t1OndPgw11uv91MJ4hRWXKcyx3ATWej2APKtT+/RUA31srqav3Dx6WRXAT2BDjzer4wnf+j6/98ySU9j0XsXlJHZywrcQ8PeciwIb3682zk1d7vy0fxHMVkECInddJlboWHbLXIrZGGQ2N3JAMWJpsHiOnLBLYDGBS0BTYOcaXGISqrNwDaiBZR4YZfmItKfrwGRTWI0BmqoGPOPQalvvRv560ZSoY4EaWJhliEEoyFyHV7Wuwuaj8KrCMgykVefeqzZUtQC1zfGzT+1AXAxI2EQYJzFwNMhSeaqFmnch7PCa4R/IbH9cfrrniB5GDQQhALuWFPEbU/wmhSEY1BrXw3lTgKbKl9XCOLOr5afmwqlAUxUB91opa8rsEtg5gKYqgmU2STPLED4XUCPDntPtEbgps0uM/gMbV7erTO4CfAAAAABJRU5ErkJggg==);
    background-size: contain;
    background-repeat: no-repeat;
    transition: 1s;
}

.drListHearts input:checked~.checkmark {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAgCAYAAAB+ZAqzAAAABHNCSVQICAgIfAhkiAAAAiZJREFUWEfNl+112kAQRe/gAkwHwRVEVBBcQdKBoQKOC8CSSAHGFZB0gCuwU0FIB7gC4wKiyRlZS0CWQJ+29qfYfbq8mR3NCDkrQL0eXAGegifQt60Ka4HHCO4CZJN33p6H6FeBbwojgUFyfiOwUVgp3OdpSFo4AboFRsdemvy2iuA6Lf4dHUWwdDAndBYRhAGy3d93ADZHxwq3zp0CYObgVuEyQNaJS1OBRZGzbo9FQWHiNOz5DsyggGUZwT3hGM7CJuDX0Bg692MwC5/AQxmn0i835+qcd/nrI8OdY3P0oWBOVTGj7JnJDfJDkmT/XfZ0W/sVNj5yISG6EJi29aIquhEMZY4+Al+qCLR1RiE0x57rJm3TgAp35pg2LdyA3i8Ds4p73oBYYxLOsc7mWDdvZdfqGPByg/TjT1KXSoaVCh8JYjBrUxTss/TR6ykCz1qgXXfRhS+AwOUMscv4v+1JQmo91eePsM2F0L37oFEM0H4PjPhd4RR++oj1g7uV1VobnDn36T2cy4J6E0oHEqCDHqzadi4PKhfMfmg7rMegjoI5OBss5HWMa3LFXeoxwTc5lrU5RK3gVRoyUnovNrC4klAbLCkldmtsLKvaifyJYLw/ojUCloTWpnO7FKVurE3c+gp1MNQ2BrZ3KQyuUDueLpxFE7VQjuXk3al2yfJpPEPsT5RelcFO5F2pfMqirgWWlXdV8qkVMJd3Z+D9hW3RW3cqtv8AlP/E4O3aZtIAAAAASUVORK5CYII=);
}
.added-fav-text{
    padding-left: 32px;
    color: #0fab0f;
    font-weight: 700;
}
.doctor-topheart .drListHeart{
    top: 0;
}
.remove-fav-text{
    padding-left: 32px;
    color: red;
    font-weight: 700
}

