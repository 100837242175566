.editIconSec {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.editIcon {
    background: #fff;
    display: block;
    padding: 5px 5px;
    border-radius: 5px 0 0 5px;
    box-shadow: 0px 3px 5px #929292;
    cursor: pointer;
    width: 33px;
    margin: 0 0 3px;
}
body .editIcon img, body .addHighlightIcon img {
    width: 20px;
    display: block;
    height: auto;
}
.DeleteIcon {
    top: 32px;
    right: 0 ;
}
span.deleteSpan {
    background-color: #e8e8e8;
    color: #000;
    border-radius: 50%;
    padding: 1px 7px;
    font-weight: bold;
    margin: 0 10px;
    display:inline-block;
    cursor: pointer;
}
.editpannel {
    position: absolute;
    background: #fff;
    right: 2px;
    top: 51px;
    width: 650px;
    box-shadow: 0px 0px 10px #929292;
    border-radius: 10px;
    padding: 20px 30px;
    z-index: 1;
}
.editList {
    margin: 0 0 10px;
}
body .editLabel {
    display: inline-block;
    margin: 5px 0 0;
    font-size: 15px;
    width: 140px;
    vertical-align: top;
}
.editText {
    width: 450px;
    display: inline-block;
    vertical-align: top;
    padding: 0 0 0 10px;
}
.fileUpload input[type="file"] {
    display: none;
}
.fileUpload  label {
    display: inline-block;
    text-decoration: none;
    background: #ffbe26;
    color: #fff;
    position: relative;
    padding: 6px 40px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
    border-radius: 5px;
}
body .editInput, body .editTextInput {
    width: 100%;
    height: 35px;
    padding: 2px 10px;
    font-size: 13px;
    font-weight: 400;
    background: #ffffff;
    border: 1px solid #9a9a9a;
    outline: 0;
    border-radius: 5px;
    margin: 0 0 5px;
    line-height: initial;
}
.showIcon img {
    width: 100%;
    display: block;
}
body .editTextInput
{
    height: 135px;
    padding-top: 10px;
    resize: none;
}
.editPanelButton {
    text-align: center;
    margin: 10px 0;
}
body .mentalHealthWrap  .saveButton, body .mentalHealthWrap  .cancelButton, body .mentalHealthWrap  .draftButton, body .mentalHealthWrap  .editBanner, body .mentalHealthWrap  .approveButton {
    display: inline-block;
    text-decoration: none;
    background: #478800;
    color: #fff;
    padding: 5px 13px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    margin: 0 3px;
    line-height: initial;
    border: 0;
    min-width: auto;
    position: initial;
    transform: translateY(0);
    top: 0;
}
body .mentalHealthWrap  .cancelButton {
    background: #7d7d7d;
}
body .mentalHealthWrap  .draftButton
{
    background: #ff8201;
}
.loginSection {
    padding: 70px 0;
}
body .editBanner {
    margin: 0px;
    position: absolute;
    left: 3px;
    z-index: 1;
    top: 3px;
    background: #c39900;
}
body .approveButton {
    position: absolute;
    z-index: 1;
    top: 5px;
    left: 0;
}
.uploadImageItem img {
    width: 50px;
    height: 50px;
}
.uploadImageItem {
    border: 1px solid #cacaca;
    display: inline-block;
    margin: 0 5px 5px 0;
    position: relative;
}
.uploadClose {
    background: #ff0000;
    color: #fff;
    font-size: 12px;
    width: 15px;
    display: inline-block;
    height: 15px;
    border-radius: 50%;
    text-align: center;
    line-height: 13px;
    position: absolute;
    top: -3px;
    right: -4px;
    cursor: pointer;
}
.errorMsg {
    color: #cc0000;
    margin-bottom: 12px;
}
.editIconSec .editInput {
    width: 300px;
    right: 35px;
    bottom: -3px;
    position: absolute;
    display: none;
}
.editActive .bannerContent {
    font-size: 0;
}
.fileUpload, .fileUpload:hover {
    width: auto;
    padding: 0;
    border: 0;
}
.bannerEdit {
    height: 225px;
    text-align: center;
    padding: 45px 20px 0;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: rgba(255, 255, 255, 0.52);
}
.editActive .bannerEdit {
    display: block;
}
.showIcon {
    display: inline-block;
    background: #fff;
    padding: 5px 5px;
    border-radius: 5px;
    box-shadow: 0px 3px 5px #929292;
    cursor: default;
    width: 33px;
    margin: 0 3px 5px;
}
.showIconSec {
    position: absolute;
    bottom: 5px;
    z-index: 1;
    left: 5px;
}
.showIconSec p {
    margin: 0 0 0 3px;
    background: rgba(0, 0, 0, 0.68);
    padding: 2px 10px;
    font-size: 12px;
    color: #fff;
}
.busiSec, .gridSection {
    position: relative;
}
.editIconSmall .editIcon {
    width: 27px;
}
.busiSec .editIconSec {
    right: -10px;
}
.editContent {
    display: none;
    text-align: center;
    width: 100%;
}
.editActive, .editActive .readAll span {
    font-size: 0;
}
.busiSec.editActive {
    min-height: 150px;
}
.editActive .editContent {
    display: block;
}
.addContentSection.editActive {
    display: block;
}
.editActive .editContent {
    position: absolute;
    z-index: 9;
    background: rgba(255, 255, 255, 0.74);
    top: 0;
    padding: 30px 30%;
    bottom: 0;
    left: 0;
}
.editActive .editContentPosChange
{
    position: unset;
}
.editActive .editInput, .editActive .editTextInput {
    display: block;
}
.editInput.rejectInput {
    display: none;
}
.editIcon amp-img {
    display: block;
}
body .addIcon, body .deleteIcon {
    width: 20px;
    display: none;
    position: absolute;
    right: 45px;
    top: 8px;
    height: 20px;
    background: #2096f3;
    border-radius: 50%;
    color: #fff;
    font-size: 17px;
    text-align: center;
    line-height: 20px;
    letter-spacing: -1px;
    cursor: pointer;
}
body .deleteIcon {
    right: 22px;
    transform: rotate(45deg);
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0px;
    background: #242424;
}
.editActive .atmTag, .nearLocal.editActive li {
    position: relative;
    padding: 5px 70px 5px 5px;
    font-size: 0;
    background: #eee;
    border-radius: 0;
    border: 0;
    display: block;
}
.editActive .addIcon, .editActive .deleteIcon {
    display: inline-block;
}

.editActive .editIcon img, .editActive .addHighlightIcon img {
    display: block;
}
.editActive ul li {
    font-size: 0;
    padding-right: 10px;
    line-height: 0;
}
.editActive p {
    font-size: 0;
    min-height: auto;
    display: none;
}
body .smallButton {
    padding: 5px 6px;
    font-size: 11px;
    margin: 0 1px;
}
.editIconWrap {
    display: inline-block;
}
/************* cms edit **************/
.dashBanner {
    min-height: 450px;
    position: relative;
}
.dashContent
{
    /* min-height: 350px; */
    position: relative;
}
.dashContent.editActive, .dashContent .editActive, .editActive .dashContent
{
    min-height: 350px;
}
.dashContentSmall
{
    min-height: 150px;
    position: relative;
}
.editPanelButtonBottom
{
    display: none;
}
.editActive .editPanelButtonBottom {
    display: block;
}
.dashBanner .editTextInput, .dashBanner .editInput {
    width: 75%;
    margin: 0 auto 10px;
}
body .bannerImageAlert {
    font-size: 13px;
    display: block;
    color: #545454;
    font-weight: 400;
    margin-bottom: 15px;
}
body .bannerSizeText {
    display: inline-block;
    color: #000;
    font-weight: 400;
    font-style: italic;
    margin: 0 5px;
    font-size: 11px;
}
.editActive .editContentFull {
    position: relative;
    width: 80%;
    float: right;
    margin: 0 20px 0 0;
    padding: 15px;
    background: #fff;
}
.editActive .visionTitle {
    display: none;
}
.dashContentList {
    min-height: 245px;
    position: relative;
}
.excellenceItem .editIcon {
    position: relative;
    left: auto;
    right: 0;
    top: 0;
    transform: translateY(0);
}
.excellenceItem .editTextInput {
    height: 75px;
    margin: 60px 0 0;
}
.dashContentList .editTextInput {
    height: 100px;
}
.editActive .mentalCategoryImage, .editActive .mentalCategoryContent, .editActive .excellenceItemName {
    display: none;
}
.cmsWrapper .editInput, .cmsWrapper .editTextInput {
    width: 75%;
    margin: 0 auto 10px;
}
.themeSubCover {
    min-height: 440px;
    background: #e2e2e2;
    position: relative;
}
.themeSubCover:nth-child(odd) {
    background: #fff;
}
.editIconSecTop {
    top: 35px;
}
.visionContent .editIconSec, .visionContent .cmsContentDiv {
    transform: skewX(-215deg);
}
.vistionList .editIconSec, .visionContentBg .editIconSecTop{
    transform: skewX(0deg);
}

/************* cms edit **************/

.visionContent.dashContent.editActive, .visionContent.dashContent .editActive, .editActive .visionContent.dashContent {
    min-height: 400px;
}
.dashContentMedium.editActive {
    min-height: 230px;
    position: relative;
}
/************* cms edit **************/
/************* cms edit **************/


.divBtn {
    color: var(--common-button-bgcolor);
    padding: 5px 25px;
    border-radius: 20px;
    text-transform: capitalize;
    border: 1px solid var(--common-button-bgcolor);
    margin: 10px 10px;
    background-color: #fff;
    text-decoration: none;
    font-size: 14px;
    min-width: 140px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
}
.tableBorderOnly table, .tableBorderOnly thead th, .tableBorderOnly td
{
    border: 1px solid #e3e3e3;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
}
.tableBorderOnly thead, .tableBorderOnly tr:nth-child(even)
{
    background-color: transparent;
}
.popup-contentDiv {
    padding: 10px;
    border-radius: 10px;
    position: relative;
    background: rgb(255, 255, 255);
    width: 50%;
    margin: auto;
    border: 1px solid rgb(187, 187, 187);
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}
.mentalCategorySection .dashContentList {
    min-height: 415px;
}
.galleryList {
    position: relative;
}
.sectionItemDivBorder {
    margin: 50px 0;
    text-align: center;    
    position: relative;
    min-height: 400px;
}
.sectionItemContentBorder {
    border: 1px solid #ddd;
    border-radius: 7px;
    padding: 50px 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    content: "";
}

body .sectionTag {
    display: inline-block;
}
@media (max-width:992px)
{
    body .sectionTag {
        padding: 25px;
        font-size: 14px;
        margin: 10px 10px;
    }
}
@media (max-width:767px)
{
    .editActive .editContent {
        padding: 30px 10%;
    }
    body .sectionTag {
        width: 100%;
        text-align: center;
        display: block;
        margin: 10px 0;
    }
}