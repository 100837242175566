:root {
    --theme-color: #8405ff;
    --common-color: #8405ff;
    --cancel-button: #de1d1d;
    --common-button-bgcolor: #29a1e6;
    --common-button-txtcolor: #fff;
    --focus-input-color: #3ac451;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins-Bold.ttf");
}

@font-face {
    font-family: "Poppins Light";
    src: url("../fonts/Poppins-Light.ttf");
}

@font-face {
    font-family: "Poppins SemiBold";
    src: url("../fonts/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: "Poppins Medium";
    src: url("../fonts/Poppins-Medium.ttf");
}

@font-face {
    font-family: "Montserrat Regular";
    src: url("../fonts/Montserrat-Regular.woff");
}

@font-face {
    font-family: "OpenSans Regular";
    src: url("../fonts/OpenSans-Regular-webfont.ttf");
}

body {
    color: #0f0f10;
    font-size: 13px;
}

.bg-clr-wthHead header {
    background-color: #f8f8fc;
}

p,
ul.ul-liike-p {
    font-size: 18px;
    color: #464646;
}

p.p-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

h1.headd {
    font-weight: 400;
}

.color-maintext,
.color-maintext:hover {
    color: var(--common-color);
    text-decoration: none;
}

.bg-grey {
    background-color: #f7f7f7;
}

.purpleWrap {
    float: left;
    width: 100%;
}

.bold-700,
.h5-700 {
    font-weight: 700;
}

.contactusbtn {
    margin-top: 3rem;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.w-40 {
    width: 40%;
}

.w-10 {
    width: 10%;
}

i.fa.fa-rupee {
    font-size: 14px;
    margin-right: 4px;
}


/* .signInWrap {
  width: 430px;
  margin: 0 auto 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  border: 1px solid #eaeaea;
  background-color: #fff;
  padding: 10px 34px;
  position: absolute;
  right: 5px;
  top: 50px;
  z-index: 3;
  box-shadow: 2px 2px 10px #e0e0e0;
} */

.signInHead {
    padding: 10px 0;
}

.signInHead h2 {
    color: #3f3d56;
    font-size: 24px;
    text-align: center;
    font-weight: 400;
    letter-spacing: 1px;
}

.signInHead p {
    text-align: center;
    font-size: 14px;
}

.signInForgot {
    text-align: left;
    padding: 10px 0;
    overflow: hidden;
}

.signInWrap .signInForgot a {
    text-transform: capitalize;
    color: var(--theme-color);
    cursor: pointer;
    float: right;
}

.signInButton .formButtonBg {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    font-size: inherit;
}

.signUp h3 {
    text-align: center;
    font-size: 17px;
    color: #3f3d56;
    margin: 10px 0;
}

.signInWrap .signUp a {
    color: var(--theme-color);
    display: block;
    margin: 10px 0;
    cursor: pointer;
    text-transform: capitalize;
    font-weight: 600;
}

.signInWrap .formButtonBg {
    background-color: var(--common-button-bgcolor);
}

.signInWrap .popup-content {
    padding: 40px 70px;
    width: 450px;
}

.signInWrap .captchaOverText {
    background-color: #c4c4c4;
    color: #fff;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 14px;
}

.fa-refresh,
.cursor-pointer {
    cursor: pointer;
}


/*** home page ***/

.watch-text:hover {
    color: #000;
}

.home-about-bg {
    position: relative;
    padding-top: 45px;
}

.home-about-bg:after {
    background-color: #f2f1fa;
    content: "";
    position: absolute;
    top: -20px;
    bottom: -20px;
    left: -209px;
    right: 0;
    z-index: -1;
}

.home-padding {
    padding: 100px 0;
}

.img-height350 {
    height: 350px;
}

.image-center {
    text-align: center;
}

.why-purple-list {
    text-align: center;
    padding: 0 40px;
}

.why-purple-list .why-purple-list-img {
    width: 100px;
    height: 98px;
}

.price-section-list-Main {
    width: 90%;
    margin: auto;
}

.price-section-list {
    border-radius: 30px;
    width: 350px;
    border: 1px solid #a2a2a2;
    overflow: hidden;
    margin: 20px auto;
    height: 450px;
}

.price-section-list-tlhlth .price-section-list-newrate {
    margin-bottom: 20px;
}

.price-section-list-head {
    padding: 15px 40px;
    text-align: center;
    font-size: 26px;
    background-color: var(--theme-subcolor);
    color: #fff;
    line-height: 35px;
    font-weight: 700;
    letter-spacing: 1px;
}

.price-section-list-oldrate {
    text-align: center;
    font-size: 50px;
    margin-top: 25px;
    position: relative;
}

.cross-price::after {
    position: absolute;
    content: "";
    /* background-image: url(/static/media/cross.f13ae1b5.png); */
    top: 8px;
    left: 5%;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    width: 90%;
}

.price-section-list-newrate {
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 50px;
}

.price-section-list-MainDiv {
    padding-left: 30px;
}

.price-section-list-MainDiv p {
    color: #6b6b6b;
    font-weight: 300;
    margin-bottom: 7px;
    font-size: 18px;
}

.price-section-list-contact {
    margin-top: 80px;
}


/**************contact us****************/

.contactrightcontent {
    width: 90%;
}

.contactrightfield {
    width: 100%;
    margin: 10px 0;
    border-radius: 10px;
    border: 1px solid #ddd;
    font-size: 16px;
    text-indent: 13px;
    padding: 8px 0;
    color: #757575;
}

textarea.contactrightfield {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ddd;
    color: #ddd;
    text-indent: 10px;
    min-height: 75px;
    max-height: 75px;
}

p.upperpara {
    width: 100%;
    font-size: 15px;
    color: grey;
}

.contactleftcontent h3 {
    font-weight: 400;
}

.contactleftcontent {
    width: 79%;
}

.fullcontactus {
    width: 90%;
    margin: auto;
    padding: 25px 0 100px;
    clear: both;
}

.contactleftcontent p {
    font-size: 20px;
}

span.commonButton {
    background-color: var(--theme-color);
    padding: 5px 15px;
    border-radius: 20px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
}

.contactusbtn {
    text-align: center;
    cursor: pointer;
}

span.commonButton:hover {
    background-color: #6d6b6b;
}

.noselect {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none !important;
    user-select: none;
}

.captchaBox .captchaOverTexr {
    display: block;
    color: #393939;
    text-align: center;
    line-height: 50px;
    font-size: 1.3333333333em;
    letter-spacing: 2px;
}

.captchaBox {
    max-width: 38%;
    height: 50px;
    border: 1px solid gainsboro;
    background-image: url("../image/theme/captchaBg.png");
    background-repeat: repeat;
    margin: 10px 0;
}

.captchaBox .captchaRefresh {
    font-size: 13px;
    color: #adadad;
    text-decoration: underline;
    clear: both;
    cursor: pointer;
    margin-top: 7px;
}

.captchaBox .captchaRefresh i {
    margin-left: 7px;
}

.captchaBox .captchaRefresh:hover {
    color: var(--theme-color);
}


/************************online brand and clinic management************************/

.bgbannerImg {
    background-image: url("../image/theme/bg-remove.png");
    background-size: 100% 100%;
    background-color: var(--theme-subcolor);
    text-align: center;
    z-index: 1;
    padding: 60px 18% 100px;
    width: 100%;
    color: #fff;
}

.bgbannerImg-text {
    color: #fff;
    text-align: center;
    font-size: 42px;
    font-weight: 600;
}

.commonButton-whitebg {
    background-color: #fff;
    border-radius: 20px;
    padding: 10px 30px;
    text-transform: uppercase;
    color: var(--theme-subcolor);
    border: 1px solid #fff;
}

.commonButton-whitebg:hover {
    background-color: transparent;
    color: #fff;
}


/************************telehealth************************/

.telehealthrightimg {
    width: 100%;
}

.telehealthrightimg img {
    width: 100%;
}

.telehealthhead {
    text-align: center;
    font-size: 35px;
    margin: 10px 0 30px;
}

.telehealthleftcontent p.upperpara {
    text-align: justify;
    font-size: 16px;
}

.fulltelehealth {
    width: 90%;
    margin: 0 auto;
    clear: both;
}

.telehealthleftcontent p {
    font-size: 20px;
}

.telehealthleftcontent {
    margin-top: 70px;
    padding: 20px;
}

.featureleftimg {
    background-color: #8200ff;
    padding: 12px;
    border-radius: 50%;
    float: left;
    width: 65px;
    height: 65px;
}

.featureleftimg img {
    width: 100%;
}

.fullfeatureelement {
    margin: 10px 0;
}

p.featurepara {
    padding-left: 73px;
    font-size: 16px;
}

span.featuretoppara {
    font-weight: 500;
}

.fulltelehealthfeature {
    margin: auto;
    width: 88%;
    margin-bottom: 70px;
    clear: both;
}

.telehealthfeaturehead {
    text-align: center;
    font-size: 36px;
    font-weight: 300;
    padding: 15px 0 5px;
    /* float: left; */
    width: 100%;
    line-height: 1.3;
}

.telehealthfeaturehead-mb {
    margin-bottom: 40px;
}

.telehealthmiddlecontent p {
    font-size: 18px;
}

.telehealthmiddlecontent span.commonButton {
    background-color: #fff;
    color: red;
}

.telehealthmiddlecontent span.commonButton:hover {
    background-color: #6d6b6b;
    color: #fff;
}

.container.telehealthtrusted {
    margin-bottom: 70px;
}

.telehealthbckgrund img {
    width: 100%;
}

span.commonButton {
    cursor: pointer;
}

.telehealthtrusted h4 {
    text-align: center;
    font-weight: 400;
    font-size: 35px;
}

.bottomimg img {
    width: 80%;
    opacity: 0.5;
}

.bottomimg img:hover {
    width: 80%;
    opacity: 10;
}

.fulltrusted {
    width: 70%;
    margin: auto;
}

.trustedbottomimg {
    padding: 15px;
}

.footercontent2 {
    margin-top: 70px;
}

.footercontent2:last-child label {
    /* font-weight: normal;
  text-align: center; */
}

span.socialmedia {
    cursor: pointer;
}

.columndvd {
    columns: 3;
}

label.footercontent {
    display: block;
    margin-left: 70px;
    font-size: 16px;
    font-weight: 500;
}

label.footercontent a {
    color: #212529;
}

.socialmedia img {
    width: 35px;
    padding: 3px;
    background-color: var(--theme-subcolor);
    border-radius: 50%;
    margin: 0 3px;
}

.socialmedia img:hover {
    background-color: #010001;
}

.footercontent2:last-child label.footercontent,
.fullsocialmedia {
    /* margin-left: 0px; */
    text-align: center;
}

.footercontent1 p {
    font-size: 16px;
    margin-top: 15px;
}

.footerlogoimg img {
    width: 150px;
}

.footer {
    background-image: url("../image/theme/Union3.png");
    background-size: 100% 100%;
    padding: 70px 50px 50px;
}

.copyright {
    text-align: center;
    margin-top: 2%;
    font-size: 18px;
    margin-bottom: -33px;
}

.col-md-3.footercontent1 {
    margin-top: 20px;
}


/****************ABOUT US************************/

img.side-image {
    width: 85%;
}

.about-img {
    margin-top: 60px;
}

.downparaaboutus {
    width: 75%;
    margin: auto;
    font-size: 22px;
    font-weight: 100;
}

.rightaboutuspara {
    margin-top: 2%;
    padding-right: 60px;
}

.right-text-about {
    max-width: 41%;
}

.bgAbtClr {
    background-color: #f8f8fd;
}

.aboutus {
    background-image: url("../image/theme/Union2.png");
    background-size: 100% 100%;
    padding-bottom: 40px;
    padding-top: 1px;
}

.home-about-bgImg {
    background-image: url("../image/theme/Union.png");
    background-size: 100% 100%;
    padding: 75px;
}

.abtusparabottom2 p {
    width: 75%;
    margin: auto;
    font-size: 22px;
    font-weight: 100;
    margin-top: 50px;
}

.ourtteamhead {
    text-align: center;
    font-size: 25px;
    margin: 30px;
}

.row.bckgrndreviewer {
    background-color: #f5f4fb;
    padding: 10px;
    margin: auto;
    padding: 23px 18px;
    margin-bottom: 45px;
}

p.reviewerrightpara {
    padding: 20px 40px;
    font-size: 18px;
}

.reviewimg {
    text-align: center;
}

.reviewpicture img {
    width: 100px;
    margin-top: 25px;
}

.reviewname {
    font-weight: 700;
    text-align: center;
    font-size: 22px;
    line-height: 25px;
    margin-top: 10px;
}


/***************************header**********************/

header {
    width: 100%;
    min-height: 71px;
    border-bottom: 1px solid #dedede;
}

li.menu-role {
    padding: 10px 0;
    margin-left: 5px;
}

span.menuIcon {
    background-color: var(--theme-subcolor);
    display: none;
    float: right;
    padding: 6px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

span.menuIcon:hover {
    background-color: #444;
}

span.menuIcon img {
    width: 100%;
}

.header span.commonButton {
    cursor: pointer;
    text-transform: uppercase;
    background-color: #0071e3;
}

.mentalHeader span.commonButton {
    background-color: #0071e3;
    margin-left: 5px;
}

.menu {
    text-align: center;
}

.menu .menu-ul {
    text-align: right;
}

.menu li {
    display: inline-block;
    padding: 20px;
    position: relative;
}

.menu li:last-child {
    float: right;
    padding-right: 0;
}

.logo img {
    width: 100%;
}

.logo {
    float: left;
    padding: 10px;
    width: 170px;
}

.menu li a {
    color: black;
    font-weight: 500;
    text-decoration: none;
    font-size: 16px;
    text-transform: uppercase;
}

li.menu-role .menu-role-link {
    color: var(--theme-color);
    background-color: #f3e6ff;
    padding: 5px 20px;
    border-radius: 20px;
    cursor: pointer;
}

.menu li .subMenuList a {
    font-size: 14px;
}

.subMenuList a {
    display: block !important;
}

.menu li a:hover {
    color: var(--theme-subcolor);
    border-bottom: 1px solid var(--theme-subcolor);
}

.menu li:last-child a:hover {
    border-bottom: 0px;
}

.subMenuMain {
    position: absolute;
    top: 56px;
    width: 270px;
    background-color: #fff;
    box-shadow: 2px 2px 10px #c3c3c3;
    padding: 10px;
    z-index: 99;
    text-align: left;
    border-radius: 10px;
    right: 0;
}

.subMenuMainList {
    display: none;
    top: 35px;
    z-index: 999;
}

.menu li:hover .subMenuMain {
    display: block;
}

.subMenuList {
    display: block;
    /* padding: 10px 0; */
}

.arrow-up:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    top: -10px;
    position: absolute;
    right: 20px;
}

.arrow-up:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid #ededed;
    top: -13px;
    position: absolute;
    right: 17px;
}

.arrw-img-mob {
    display: none;
}


/* .container.header {
    width: 74%;
} */


/**********************homepage**********************/

.homefirstsection label {
    font-size: 30px;
}

span.watchvedio {
    color: #f0504f;
    margin-left: 20px;
}

.fullhomepagefirstsec {
    background-image: url("../image/theme/Union2.png");
    background-size: 100% 100%;
}

.backgrndclr {
    background-color: #f4f3fc;
    padding: 50px 10px 50px 150px;
}

.homerightaboutuspara {
    position: absolute;
    right: 100px;
}

.home {
    position: relative;
    z-index: 999;
}


/*** Ramsi Style Start ***/


/*** Price Page Style  ***/

.bgPriceClr {
    background-color: #ebe7e8;
}

.priceMain {
    padding: 50px 150px;
}

.priceMain .priceSub {
    width: 42%;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    display: inline-block;
    margin: 0 4%;
}

.priceSub .priceTopPart img {
    width: 100%;
}

.priceSub .priceMiddlePart {
    background-color: #8200ff;
    color: #fff;
    text-align: center;
    padding: 10px;
}

.priceSub .priceMiddlePart .priceMiddleHead {
    font-size: 34px;
    font-weight: normal;
}

.priceSub .priceMiddlePart .priceMiddleRate {
    font-size: 30px;
    font-weight: normal;
}

.priceSub .priceMiddlePart .priceMiddleRateSpan {
    font-size: 16px;
}

.priceSub .priceMiddlePart .priceMiddleToggle {
    font-size: 24px;
    font-weight: normal;
}

.priceSub .priceBottomPart {
    padding: 20px;
}

.priceSub .priceBottomPart p {
    font-size: 20px;
}

.priceSub .priceMiddleToggle .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 0;
    line-height: 17px;
    margin-right: 10px;
}

.priceSub .priceMiddleToggle .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.priceSub .priceMiddleToggle .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.priceSub .priceMiddleToggle .toggle-slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
}

.priceSub .priceMiddleToggle input:checked+.toggle-slider {
    background-color: var(--theme-subcolor);
}

.priceSub .priceMiddleToggle input:focus+.toggle-slider {
    box-shadow: 0 0 1px var(--theme-subcolor);
}

.priceSub .priceMiddleToggle input:checked+.toggle-slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}


/* Rounded sliders */

.priceSub .priceMiddleToggle .toggle-slider.toggle-round {
    border-radius: 34px;
}

.priceSub .priceMiddleToggle .toggle-slider.toggle-round:before {
    border-radius: 50%;
}

.priceSub .priceButtonPart {
    text-align: center;
    padding: 0 0 20px;
}

.priceSub span.commonButton.priceBtn {
    padding: 10px 30px;
    border-radius: 20px;
}


/*** Doctor Detail Page Style  ***/

.doctorDetailMain {
    float: left;
    width: 100%;
}

.doctorDetailMain .doctorDetailStatement {
    width: 50%;
    display: inline-block;
    padding-top: 20px;
    float: right;
    padding-left: 40px;
}

.doctorDetailStatement h4 {
    color: #000;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.doctorDetailMain .doctorDetailStatement p {
    line-height: 25px;
    text-align: justify;
    font-size: 14px;
}


/* .doctorDetailStatement p,
.drDetailHospital .drDetailHospitalDet p {
  font-size: 18px;
} */

.doctorDetailStatement .iconUl {
    list-style: none;
    padding: 0;
}

.doctorDetailStatement .iconUl li {
    display: inline-block;
}

.doctorDetailStatement .iconOuter {
    background-color: #efefef;
    padding: 4px 7px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
}

.doctorDetailStatement .iconUl .iconOuter img {
    width: 15px;
    vertical-align: baseline;
}

.doctorDetailStatement .iconUl.icon-head-text {
    position: absolute;
    right: 0;
    top: 0;
}

.doctorDetailStatement .icon-head-text .iconOuter {
    padding: 4px 9px;
    width: 30px;
    height: 30px;
    display: inline-block;
    line-height: 20px;
}

.doctorDetailStatement .icon-head-text .iconSocial {
    float: left;
}

.doctorDetailStatement .iconSocial {
    display: none;
}

.doctorDetailStatement #iconOuterSocial:hover .iconSocial {
    display: inline-block;
}

.doctorDetailStatement .iconSocial img {
    width: 20px;
    margin-right: 5px;
    cursor: pointer;
}

.doctorDetailProfile {
    background-color: #fff;
    box-shadow: 2px 2px 10px #e0e0e0;
    border-radius: 20px;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.doctorDetailProfile .doctorDetailProfileImg {
    margin-bottom: 5px;
}

.doctorDetailProfile .doctorDetailProfileImg img {
    border-radius: 50%;
    background-color: #fff;
    border: 4px solid #f8f8f8;
    padding: 3px;
    object-fit: cover;
}

.doctorListProfile a {
    color: #212529;
}

.doctorDetailLeft {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.drListHeart {
    display: block;
    position: absolute;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    top: 20px;
    left: 30px;
}

.drListdrActive {
    display: block;
    position: absolute;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    top: 20px;
    right: 30px;
}


/* Hide the browser's default checkbox */

.drListHeart input,
.checkbox-main input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.drListHeart .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-image: url("../image/theme/heartg.png");
    background-size: contain;
    background-repeat: no-repeat;
    transition: 1s;
}


/* When the checkbox is checked, add a blue background */

.drListHeart input:checked~.checkmark {
    background-image: url("../image/theme/heart1.png");
}


/* Create the checkmark/indicator (hidden when not checked) */

.drListHeart .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.drListHeart input:checked~.checkmark:after {
    display: block;
}

.checkbox-main {
    padding: 0 13px;
    position: relative;
}


/* Create a custom checkbox */

.checkbox-main .checkbox {
    position: absolute;
    top: -5px;
    left: 0;
    height: 17px;
    width: 17px;
    background-image: url("../image/theme/checkbox.png");
    background-size: contain;
    background-repeat: no-repeat;
    transition: 1s;
}


/* When the checkbox is checked, add a blue background */

.checkbox-main input:checked~.checkbox {
    background-image: url("../image/theme/checkbox-act.png");
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkbox-main .checkbox:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.checkbox-main input:checked~.checkbox:after {
    display: block;
}

.doctors-list-tabmain {
    margin-bottom: 20px;
    text-align: right;
    border-bottom: 1px solid #f3e6ff;
    padding-bottom: 15px;
    height: 45px;
    margin-top: -43px;
}

.doctors-list-tab {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    padding: 5px 20px;
    display: inline-block;
    color: #000;
    background-color: #f3e6ff;
    border-radius: 20px;
    margin-left: 10px;
    vertical-align: top;
}

.doctors-list-tab-active {
    color: var(--theme-color);
    font-weight: 500;
    background-color: #e5cbfe;
}

.doctors-list-tab:hover {
    color: var(--theme-color);
    background-color: #e5cbfe;
    text-decoration: none;
}

.doctorDetailProfile .drName {
    font-weight: 400;
    color: #000;
    font-size: 1.1rem;
    margin-bottom: 0.25rem;
}

.drName-info img {
    width: 16px;
    height: 16px;
    margin-left: 5px;
}

.drspecialist-hospital {
    border: 1px solid #3f3d56;
    color: #000;
    border-radius: 5px;
    font-size: 14px;
    text-transform: uppercase;
    max-width: 100%;
    margin-top: 10px;
    min-width: auto;
    width: auto;
    padding: 0.35rem 1rem;
}

.doctorDetailProfile .drspecialist {
    background-color: #e5e5e5;
    padding: 8px 15px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
    color: #000;
    display: block;
    margin-bottom: 5px;
}

.drspecialist-text {
    font-size: 14px;
    color: #000;
}

.doctorDetailProfile .profileAvailable {
    background-color: #f2f1fa;
    padding: 8px 15px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    color: var(--theme-color);
    display: block;
    cursor: pointer;
}

.doctorDetailProfile .profileAvailable img {
    vertical-align: sub;
    margin-right: 5px;
}

.drDetailServicemain {
    text-align: center;
    float: left;
    width: 100%;
    margin: 0px 0;
}

.drDetailServicemain .drDetailServiceSpan {
    background-color: #fff;
    display: inline-block;
    color: #a7a7a7;
    padding: 10px 5px;
    border-radius: 0px;
    /* width: 164px; */
    text-align: left;
    text-transform: capitalize;
    font-size: 14px;
    margin: 10px 20px;
    margin-left: 0;
    cursor: pointer;
}

.drDetailServiceSpan.drDetailServiceSpan-active {
    color: #0071e3;
}

.drDetailServicemain .drDetailServiceSpan img {
    vertical-align: bottom;
    margin-right: 5px;
    filter: grayscale(1);
}

.drDetailServicemain .drDetailServiceSpan.drDetailServiceSpan-active img {
    filter: unset;
}


/* .drDetailServiceSpan.drDetailServiceSpan-active .drDetSpanUnderline {
  text-decoration: underline;
} */

.drDetailServiceSpan.drDetSpanUnderline {
    border-bottom: 1px solid #0071e3;
}

.drDetailServiceSpan:hover .drDetSpanUnderline {
    text-decoration: underline;
}

.drDetailServicemainTab .drDetSpanUnderline {
    text-transform: capitalize;
}

.drDetImgBtm {
    display: inline-block;
    width: 23%;
    border: 1px solid #f2f1fa;
    border-radius: 5px;
    margin: 1% 2% 1% 0;
}

.drDetImgBtm img {
    width: 100%;
}

.drDetailHospital {
    background-color: #f2f1fa;
    padding: 60px;
    margin-bottom: 40px;
    border-radius: 10px;
    position: relative;
    min-height: 300px;
}

.drDetailHospital .drDetailHospitalImg {
    padding: 0;
    display: inline-block;
    vertical-align: top;
}

.drDetailHospital .drDetailHospitalImg img {
    width: 100%;
    /* padding: 70px 0; */
    border-radius: 50%;
    background-color: #fff;
}

.drDetailHospital .drDetailHospitalHead {
    line-height: 25px;
}

.drDetailHospitalDet {
    display: inline-block;
    vertical-align: top;
    padding-left: 54px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.drDetailHospital .drDetailHospitalDet p {
    margin-bottom: 0;
}

.drDetailServicemainTab {
    background-color: #fff;
    border: 1px solid #f3f3f3;
    padding: 20px;
    border-radius: 10px;
}

.drDetailServicemainTab .react-tabs__tab-list {
    border: 0;
}

.drDetailServicemainTab .react-tabs__tab {
    margin-right: 20px;
    font-size: 14px;
    border: 0;
    border-bottom: 1px solid #fff;
}

.drDetailServicemainTab .react-tabs__tab--selected {
    color: #0071e3;
    border: 0;
    border-bottom: 1px solid #0071e3;
}

.drDetailServicemainTab .react-tabs__tab:hover {
    color: #0071e3;
    border-bottom: 1px solid #0071e3;
}

.drDetailServicemainTab .react-tabs__tab:hover img {
    filter: none;
}

.drDetailServicemainTab .react-tabs__tab img {
    filter: grayscale(1);
}

.drDetailServicemainTab .react-tabs__tab--selected img {
    filter: none;
}

.drDetailServicemainTab .react-tabs__tab:focus,
.video-appntmnt-list .react-tabs__tab:focus {
    border-color: unset;
    box-shadow: unset;
    outline: none;
    border: none;
}

.drDetailServicemainTab p {
    font-size: 14px;
}

.drDetailServicemainTab .form-head {
    margin-bottom: 5px;
    margin-top: 25px;
    text-transform: capitalize;
}

.imgshowhide .imghide {
    display: none;
}

.imgshowhide:hover .imghide {
    display: inline-block;
}

.imgshowhide:hover .imgshow {
    display: none;
}

.calendarDiv {
    width: 100%;
    border: 0;
    padding: 0;
    border-radius: 5px;
    position: relative;
    box-shadow: 2px 2px 10px #cecece;
    overflow: hidden;
    height: 335px;
    left: 50%;
    position: relative;
    background-color: #fff;
}

.calendarLoading:after {
    position: absolute;
    background-image: url("../image/media/loadingg.gif");
    left: 60%;
    transform: translateX(-60%);
    top: 30%;
    bottom: 0;
    right: 0;
    content: "";
    background-size: 150px;
    background-repeat: no-repeat;
}

.calendarDiv .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 0;
}

.calendarDiv .react-datepicker__day-name,
.calendarDiv .react-datepicker__day,
.calendarDiv .react-datepicker__time-name {
    width: 12.8%;
}

.calendarDiv .react-datepicker__day {
    color: #212529;
}

.react-datepicker__time-container {
    display: none;
}

.react-datepicker .react-datepicker__time-container {
    float: none;
    border-left: 0;
    width: 100%;
    position: absolute;
    background-color: #fff;
}

.react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100%;
}

.calendarDiv .react-datepicker__day--selected,
.calendarDiv .react-datepicker__day--in-selecting-range,
.calendarDiv .react-datepicker__day--in-range,
.calendarDiv .react-datepicker__month-text--selected,
.calendarDiv .react-datepicker__month-text--in-selecting-range,
.calendarDiv .react-datepicker__month-text--in-range,
.calendarDiv .react-datepicker__quarter-text--selected,
.calendarDiv .react-datepicker__quarter-text--in-selecting-range,
.calendarDiv .react-datepicker__quarter-text--in-range,
.calendarDiv .react-datepicker__day--selected:hover {
    background: transparent;
    color: var(--theme-color);
}

.calendarDiv .react-datepicker,
.calendarDiv .react-datepicker__month-container {
    width: 100%;
    border: 0;
    font-size: 0.85em;
}

.calendarDiv .react-datepicker__day-name {
    color: #c3c3c3;
    font-weight: 100;
    text-transform: uppercase;
}

.calendarDiv .react-datepicker__header {
    background-color: transparent;
    border: 0;
}

.calendarDiv .react-datepicker__current-month,
.calendarDiv .react-datepicker-time__header,
.calendarDiv .react-datepicker-year-header {
    color: #000;
    font-weight: 100;
}

.calendarDiv .react-datepicker__time-container {
    color: var(--theme-color);
}

.calendarDiv .react-datepicker-time__header {
    color: var(--theme-color);
    font-weight: 700;
    text-transform: capitalize;
    width: 95%;
}

.calendarDiv ul.react-datepicker__time-list {
    font-size: 12px;
}

.calendarDiv .react-datepicker__day:focus {
    outline: 0;
}

.calendarDiv .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.calendarDiv .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: transparent;
    color: var(--theme-color);
}

.timeClose {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 1;
    display: none;
}

.filter-search {
    border: 1px solid #3f3d56;
    color: #000;
    border-radius: 5px;
    font-size: 12px;
    text-transform: uppercase;
    padding: 7px 10px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

.filter-search img {
    width: 15px;
    margin-right: 5px;
}

.drSearch {
    display: inline-block;
    position: relative;
    width: 500px;
    vertical-align: top;
    margin-left: 15px;
    margin-bottom: 10px;
}

.drSearch .drSearchBox {
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    height: auto;
    padding: 8px 25px 8px 20px;
    font-size: 14px;
    width: 100%;
    outline: none !important;
    vertical-align: top;
    font-weight: 500;
}

.drSearch img {
    position: absolute;
    right: 10px;
    top: 11px;
    cursor: pointer;
}

.specltyBox {
    background-color: var(--theme-subcolorLight);
    padding: 25px 20px;
    display: block;
    border-radius: 5px;
    float: left;
    width: 100%;
}

.specltyBox .specltyList {
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 2px 5px;
    transition: 0.5s;
    cursor: pointer;
    width: auto;
    outline: 0;
    display: inline-block;
    margin-bottom: 10px;
}

.specltyBox .slick-slide {
    width: auto;
}

.specltyBox .specltyList.specltyactv,
.specltyBox .specltyList:hover {
    background-color: var(--theme-color);
    color: #fff;
}

.doctorListProfile {
    width: 48%;
    margin: 1%;
    min-height: 322px;
}

.doctorDetailProfile .drServiceMain {
    padding: 20px 10px;
    width: 46%;
    display: inline-block;
    vertical-align: top;
    /* display: none; */
    border: 1px solid #f7f7f8;
    border-radius: 20px;
    height: auto;
    float: right;
}

.doctorListProfile .drServiceMain {
    height: 262px;
}

.det-drServiceMain {
    background-color: rgba(0, 0, 0, 0.1);
}

.drServiceSp {
    background-color: #fff;
}

.drServiceSpSpan {}

.drService-rate {
    float: right;
}

.drService-subhead {
    font-size: 16px;
    font-weight: 600;
    color: #4d4d4d;
}


/* .doctorDetailProfile .drServiceMain .drServiceSp {
    background-color: #fff;
    padding: 5px 20px;
    border-radius: 5px;
    color: var(--theme-color);
    text-transform: uppercase;
    display: block;
    margin: 9px 0;
    font-size: 12px;
    text-align: left;
    line-height: 25px;
} */

.doctorDetailProfile .drServiceMain .drServiceSp {
    margin: 3% 1%;
    position: relative;
    border-radius: 2px;
}

.doctorDetailProfile .drServiceMain .drServiceSp a,
.doctorDetailProfile .drServiceMain .drServiceSp .drServiceSpSpan {
    /* background-color: #fff; */
    padding: 5px;
    border-radius: 5px;
    color: var(--theme-color);
    text-transform: uppercase;
    display: inline-block;
    font-size: 12px;
    line-height: 25px;
    width: 100%;
    cursor: pointer;
    text-align: left;
}

.drServiceSp:after {
    position: absolute;
    content: "";
    right: 65px;
    width: 1px;
    height: 16px;
    background-color: rgba(63, 61, 86, 0.2);
    top: 10px;
}

.drServiceSp-book {
    background-color: #ede6ff;
}

.drServiceSp-videochat {
    background-color: #dae7ff;
}

.drServiceSp-call {
    background-color: #ffe4dd;
}

.drServiceSp-chat {
    background-color: #daffe1;
}

.doctorDetailProfile .drServiceMain .drServiceSp a,
.doctorDetailProfile .drServiceMain .drServiceSp .drServiceSpSpan {
    color: #3f3d56;
    text-decoration: none;
}


/* .doctorDetailProfile .drServiceMain .drServiceSp img {
    width: 20px;
    margin-right: 10px;
} */

.doctorDetailProfile .drServiceMain .drServiceSp img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
}

.closeMainDiv {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.pagination>li {
    display: inline;
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 7px 6px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #000;
    border: 1px solid #ddd;
    margin-left: -1px;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    text-align: center;
    margin: 0 4px;
    background-color: #e2e2e2;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    z-index: 3;
    color: #fff;
    background-color: #3f3d56;
    cursor: pointer;
}

.main-wrapper,
.main-wrapper-smwidth {
    border: 1px solid #dedede;
    padding: 20px 5%;
    margin: 40px 0 60px;
    border-radius: 20px;
    padding: 30px 50px;
    min-height: 400px;
}

.main-wrapper-smwidth {
    width: 80%;
    margin: 40px auto 60px;
}

.main-wrapper-smwidth1 {
    width: 80%;
    margin: 40px auto 60px;
}

.main-wrapper1 {
    margin: 20px 0 60px;
    padding: 20px 0;
    /* padding-top: 0; */
}

.memberHeadGrayBg {
    padding: 15px;
    background: #f2f2f2;
    border: 1px solid #f0f0f0;
}

.doctorDetail {
    padding: 20px 30px;
    background: #eaeaea;
    border-radius: 20px;
}

.head-text {
    color: var(--common-color);
    font-size: 1.4em;
    line-height: 25px;
}

.doctor-name {
    font-size: 1.4em;
    line-height: 25px;
    font-weight: 700;
}

.doctorDetail .doctoAddress {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 600;
    line-height: 20px;
}

.form-section {
    font-size: 14px;
    position: relative;
}

.form-section-padng {
    padding-left: 15px;
    padding-right: 15px;
}

.form-section-brdr {
    border: 1px solid #dedede;
    border-radius: 20px;
    padding: 10px;
    margin: 20px 0;
}

.health-record-head {
    color: #6b6b6b;
    border-bottom: 1px solid #dedede;
    font-weight: 600;
}

.react-datepicker-wrapper {
    width: 100%;
    position: relative;
}


/* .react-datepicker-wrapper:after {
  content: "";
  background-image: url(../image/theme/calendar1.png);
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 1;
  width: 25px;
  height: 25px;
  background-size: contain;
} */

.react-date-picker .react-datepicker__day--selected,
.react-date-picker .react-datepicker__day--in-selecting-range,
.react-date-picker .react-datepicker__day--in-range,
.react-date-picker .react-datepicker__month-text--selected,
.react-date-picker .react-datepicker__month-text--in-selecting-range,
.react-date-picker .react-datepicker__month-text--in-range,
.react-date-picker .react-datepicker__quarter-text--selected,
.react-date-picker .react-datepicker__quarter-text--in-selecting-range,
.react-date-picker .react-datepicker__quarter-text--in-range,
.react-date-picker .react-datepicker__day--keyboard-selected {
    border-radius: 50% !important;
    background-color: var(--focus-input-color) !important;
}

.react-date-picker.react-datepicker .react-datepicker__header {
    background-color: #ffffff;
    border-bottom: 0;
}

.react-date-picker.react-datepicker .react-datepicker__day-name {
    font-weight: 700;
}

.input-design,
.react-datepicker__input-container input {
    border: 1px solid #dcdcdc;
    background-color: #fff;
    width: 100%;
    padding: 0.25rem 1rem;
    resize: none;
    border-radius: 20px;
    color: #545454;
    margin-bottom: 15px;
}

.purpleWrap .app-popup-time {
    width: 200px;
    display: block;
    margin: auto;
}

.purpleWrap .app-popup-time .rc-time-picker-input {
    width: 100%;
}

.input-designBg {
    border: 1px solid #eaeaea;
    background-color: #f3f3f3;
}

select.input-design {
    padding: 0.35rem 1rem;
}

.input-designBold {
    font-weight: 500;
}

.week-text {
    color: #989898;
    position: relative;
    margin-bottom: 5px;
    font-weight: 500;
    padding-left: 40px;
}

.form-section .label-text,
.form-section-smwidth .label-text {
    color: #606060;
    position: relative;
    margin-bottom: 5px;
    font-weight: 500;
}

.form-section .label-text-cln:after,
.form-section-smwidth .label-text-cln:after,
.doctor-pay-box .label-text-cln:after {
    position: absolute;
    content: ":";
    right: 0;
}

.form-section .form-check-inline,
.form-section-smwidth .form-check-inline {
    /* min-width: 150px; */
    margin-right: 40px;
}

.radio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 10px 0 0;
}

.radio-item input[type="radio"] {
    display: none;
}

.radio-item label {
    color: #989898;
    font-weight: normal;
}

.radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 4px;
    margin: 0 5px 0 0;
    width: 18px;
    height: 18px;
    border-radius: 11px;
    /* border: 1px solid #989898; */
    background-color: transparent;
    background-image: url("../image/theme/radio.png");
    background-size: contain;
}

.radio-item input[type="radio"]:checked+label:before {
    /* border-color: var(--theme-color); */
    background-image: url("../image/theme/radioact.png");
}


/* .radio-item input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 8px;
  left: 9px;
  content: " ";
  display: block;
  background: var(--theme-color);
} */

.radio-item-active {
    border: 1px solid #989898;
    border-radius: 20px;
}


/* .radio-item-active .form-check-label {
  color: var(--theme-subcolor1);
} */

.form-head {
    font-size: 16px;
    font-weight: 700;
    color: #4d4d4d;
    margin-bottom: 25px;
}

.form-subhead {
    font-size: 18px;
    font-weight: 700;
    color: #4d4d4d;
    margin-bottom: 25px;
}

.form-subhead-brdr {
    position: relative;
    font-size: 18px;
    font-weight: 700;
    color: #4d4d4d;
    margin-bottom: 25px;
}

.form-subhead-brdr:after {
    position: absolute;
    content: "";
    bottom: -5px;
    left: 47%;
    right: 47%;
    width: auto;
    height: 3px;
    background-color: #b7b7b7;
    border-radius: 20px;
}

.form-head-bg {
    background-color: #f5f5f5;
    color: #7a7a7a;
    font-size: 1.1rem;
    font-weight: 700;
    width: auto;
    padding: 10px 30px;
    border-radius: 20px;
    margin: 30px 0;
    max-width: max-content;
}

.formButton {
    color: var(--common-button-bgcolor);
    padding: 5px 25px;
    border-radius: 20px;
    text-transform: capitalize;
    border: 1px solid var(--common-button-bgcolor);
    margin: 10px 10px;
    background-color: #fff;
    text-decoration: none;
    font-size: 14px;
    min-width: 140px;
    cursor: pointer;
}

.formButton-grey {
    color: #000;
    padding: 5px 25px;
    border-radius: 20px;
    text-transform: capitalize;
    border: 1px solid #cccccc;
    margin: 10px 10px;
    background-color: #fff;
    text-decoration: none;
    font-size: 14px;
    min-width: 140px;
    font-weight: 500;
}

.formButtonBg {
    background-color: var(--common-button-bgcolor);
    color: var(--common-button-txtcolor);
    padding: 7px 20px;
    border-radius: 20px;
    font-weight: 600;
    border: 0;
    font-size: 14px;
    line-height: unset;
}

.formButtonBg:hover {
    background-color: var(--common-button-bgcolor);
    color: var(--common-button-txtcolor);
    text-decoration: none;
}

.cancelButton {
    color: var(--cancel-button);
    border: 1px solid var(--cancel-button);
    padding: 5px 25px;
    border-radius: 20px;
    text-transform: capitalize;
    margin: 10px 10px;
    background-color: #fff;
    text-decoration: none;
    font-size: 14px;
    min-width: 140px;
}

.cancelButton-black {
    color: #707070;
    border: 1px solid #a3a3a3;
    padding: 7px 25px;
    border-radius: 20px;
    text-transform: capitalize;
    margin: 10px 10px;
    background-color: #fff;
    text-decoration: none;
    font-size: 14px;
    min-width: 140px;
}

.cancelButtonBg,
.cancelButtonBg:hover {
    background-color: var(--cancel-button);
    border-color: var(--cancel-color);
    color: #fff;
}

.formButton-size {
    font-size: 12px;
}

.formButton-sm {
    font-size: 10px;
    min-width: auto;
    padding: 3px 15px;
    font-weight: 600;
}

.formButton-apply {
    color: #000;
    padding: 5px 25px;
    padding-bottom: 7px;
    border-radius: 20px;
    text-transform: capitalize;
    border: 1px solid #cccccc;
    background-color: #ededed;
    margin: 10px 10px;
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;
}

.formButtonsearch {
    min-width: 90px;
    padding: 5px 10px;
    color: var(--common-button-bgcolor);
    border-radius: 20px;
    text-transform: capitalize;
    border: 1px solid var(--common-button-bgcolor);
    margin: 0 0 10px 10px;
    background-color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
}

.form-section-bgcolor {
    background-color: #f5f5f5;
    border-radius: 20px;
    padding: 20px 0;
}

.form-section-smwidth {
    width: 600px;
    margin: auto;
    font-size: 16px;
    position: relative;
}

.form-section .label-text-right,
.form-section-smwidth .label-text-right {
    text-align: right;
    color: #606060;
    position: relative;
    margin-bottom: 5px;
    font-weight: 500;
}

.form-section .input-box-w-150,
.form-section-smwidth .input-box-w-150 {
    width: 150px;
}

.form-section .selecttime,
.form-section-smwidth .selecttime {
    background-color: #e8f0ff;
    border: 0;
    color: #989898;
    border-radius: 0;
}

.form-section .selecttime:nth-child(1),
.form-section-smwidth .selecttime:nth-child(1) {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right: 1px solid #dedede;
}

.form-section .selecttime:nth-last-child(1),
.form-section-smwidth .selecttime:nth-last-child(1) {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 1px solid #dedede;
}

.font-bold {
    font-weight: 700;
}

.fileUpload,
.fileUpload:hover {
    position: relative;
    overflow: hidden;
    background: transparent;
    border: 1px solid var(--theme-color);
    text-align: center;
    padding: 5px 10px;
    border-radius: 20px;
    color: var(--theme-color);
    height: auto;
    width: 200px;
    font-weight: 500;
    cursor: pointer;
}

.fileUpload-medical,
.fileUpload-medical:hover {
    position: relative;
    overflow: hidden;
    background: transparent;
    border: 1px solid var(--theme-color);
    text-align: center;
    padding: 5px 10px;
    border-radius: 20px;
    color: var(--theme-color);
    height: auto;
    width: auto;
    float: left;
    font-weight: 500;
    cursor: pointer;
    min-width: 140px;
}

.fileUpload input.upload,
.fileUpload-medical input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.fileUpload-medicalImg {
    display: inline-block;
    width: 70px;
    height: 70px;
    margin-left: 10px;
}

.fileUpload-medical-sm {
    font-size: 12px;
}

.attchedDelete {
    background: url(../image/theme/closered.png) no-repeat scroll 0 0 transparent;
    float: right;
    border: 0;
    width: 25px;
    height: 24px !important;
    box-shadow: none;
    margin-top: 3px;
    display: inline-block;
    object-fit: contain;
}

.fileAttachment {
    clear: both;
    width: 350px;
}

.fileAttachmentList {
    margin: 20px 0;
    clear: both;
}

.note {
    font-size: 14px;
    color: #393939;
    float: left;
    margin-top: 5px;
    font-style: italic;
}

.link-color,
.link-color:hover {
    color: var(--common-color);
    text-decoration: none;
    cursor: pointer;
}

.row-margin {
    margin-bottom: 15px;
}


/* .chat-outer {
  border: 1px solid #adadad;
} */

.chat-outer .chat-header {
    /* border-bottom: 1px solid #adadad; */
    padding: 5px 15px;
    overflow: hidden;
}

.chat-outer .chat-span {
    float: right;
    margin-right: 10px;
    font-size: 14px;
}

.btn-default {
    border: 1px solid #ccc;
    padding: 4px 20px;
    background-color: #fff;
    border-radius: 20px;
    font-weight: 500;
}

.chat-span-head {
    line-height: 33px;
}

.chat-body {
    position: relative;
    padding-bottom: 20px;
    /* background-color: #eeeeee; */
    border: 1px solid #dedede;
    border-radius: 20px;
}

.messages {
    position: relative;
    list-style: none;
    padding: 20px 10px 0 10px;
    margin: 15px 0 0 0;
    max-height: 347px;
    overflow-y: auto;
}

.message {
    clear: both;
    overflow: hidden;
    margin-bottom: 15px;
    transition: all 0.5s linear;
    opacity: 1;
    position: relative;
}

.message.message-brdr {
    border: 1px solid #dedede;
    border-radius: 20px;
    padding: 10px;
}

.textchat-namehead {
    font-size: 16px;
    color: #606060;
    text-transform: uppercase;
    font-weight: 700;
}

.message.left .avatar {
    float: left;
}

.message.left .text_wrapper {
    margin-left: 20px;
    float: left;
}

.message.left .text_wrapper::after,
.message.left .text_wrapper::before {
    right: 100%;
    border-right-color: #f1f1f1;
}

.message.right .avatar {
    float: right;
}

.message.right .text_wrapper {
    margin-right: 20px;
    float: right;
    text-align: right;
    background-color: #80ccf3;
    color: #fff;
}

.message.right .text_wrapper::after,
.message.right .text_wrapper::before {
    left: 100%;
    border-left-color: #80ccf3;
}

.message.right .text-header,
.message.right .text,
.message.right .text-header .message-time {
    color: #fff;
}

.message .avatar {
    display: inline-block;
    position: relative;
}

.message .textchat-name {
    font-size: 12px;
}

.message .avatar .avatarimg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-size: contain;
    border: 3px solid #cfcfcf;
    object-fit: contain;
}

.chatBubleOnline {
    position: absolute;
    right: 0;
    top: -10px;
    width: 20px;
    height: 20px;
    background-color: #32a857;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    padding: 3px 0;
}

.avatar .chatBubleOnline img {
    width: 20px;
}

.message .text_wrapper {
    display: inline-block;
    padding: 10px;
    border-radius: 6px;
    width: auto;
    min-width: 100px;
    position: relative;
    background-color: #f1f1f1;
}

.message .text_wrapper::after,
.message .text_wrapper:before {
    top: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.message .text_wrapper::after {
    border-width: 5px;
    margin-top: 0px;
}

.message .text_wrapper::before {
    border-width: 5px;
    margin-top: -2px;
}

.message .text,
.text-chat-container .ots-text-chat .ots-messages-holder .ots-message-item .ots-item-text span,
.video-chat-container .ots-text-chat .ots-messages-holder .ots-message-item .ots-item-text span {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    display: block;
}

.message .text-header {
    overflow: hidden;
}

.message .text-header h6 {
    float: left;
    font-weight: 700;
}

.message .text-header .message-time {
    float: right;
    color: #6c757d;
    font-size: 0.9em;
}

.message.right .text-header h6 {
    float: right;
    font-weight: 700;
}

.message.right .text-header .message-time {
    float: left;
    color: #6c757d;
    font-size: 0.7em;
}

.textarea-wrapper {
    margin-bottom: 10px;
}

textarea.textarea {
    width: 100%;
    height: 100px;
    font-size: 22px;
    padding: 15px;
    /* border: 0; */
    background-color: #fff;
}

textarea.textarea:focus {
    /* border: 1px solid var(--common-color); */
    border: 1px solid var(--focus-input-color);
    outline: 0 !important;
}

.textarea-count {
    text-align: right;
    margin-bottom: 5px;
    font-size: 12px;
}

.chat-bottom-main {
    position: relative;
    border: 1px solid #dedede;
    border-radius: 20px;
    width: 95%;
    margin: auto;
    padding: 20px 15px 0;
}

.chat-buttonmain {
    padding: 10px;
    overflow: auto;
}

.chat-buttonmain .chat-send {
    background-color: #fff;
    padding: 5px 15px;
    border: 0;
    border-radius: 5px;
    font-size: 14px;
    margin: 0;
}

.chat-buttonmain .attatch-icon {
    color: #fff;
    font-size: 28px;
}

.chat-buttonmain .messageFileUpload {
    background: transparent url(../image/theme/attachpin.png) no-repeat scroll 0 0 !important;
    background-size: contain !important;
}

.chat-buttonmain .chat-sendbtn {
    min-width: auto;
    float: right;
    padding: 3px 25px;
    font-size: 12px;
    margin: 0;
}

.chat-bottom-main .textarea {
    border: 0;
    font-size: 14px;
    padding-top: 0;
    padding-bottom: 0;
    resize: unset;
    height: 70px;
}

.chat-bottom-main .textarea:focus {
    border: unset;
}

.chat-bottom {
    position: absolute;
    padding: 10px;
    background-color: #000;
    bottom: 0;
}

.chat-bottom .chat-send {
    background-color: #fff;
    padding: 5px 15px;
    border: 0;
    border-radius: 5px;
    font-size: 14px;
}

.chat-bottom .attatch-icon {
    color: #fff;
    font-size: 28px;
}

.messageFileUpload {
    background: transparent url(../image/media/attach.png) no-repeat scroll 0 0 !important;
    width: 35px;
    height: 20px;
    float: left;
    margin-right: 5px;
    padding: 5px;
    margin-top: 5px;
    cursor: pointer;
    margin-bottom: 5px;
    margin-left: 5px;
    position: relative;
}

.messageFileUpload input.uploadattach {
    position: absolute;
    width: 18px;
    left: 0;
    top: 0;
    opacity: 0;
    overflow: hidden;
}

.textwrappergroup {
    margin-left: 20px;
    padding: 10px;
    width: calc(100% - 85px);
    display: inline-block;
}

.divider {
    height: 1px;
    background: gainsboro;
    width: 100%;
    clear: both;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

.chat-bodygroup {
    border: 1px solid #dedede;
    border-radius: 20px;
}

.chat-bodygroup .message .text {
    font-size: 14px;
}

.chat-bodygroup .message .avatar {
    margin-top: 10px;
    vertical-align: top;
}

.chat-bodygroup .message .text-header .message-time {
    margin-top: -20px;
}

.breadcrumb {
    background-color: #ffffff;
    border-radius: 20px;
    border: 1px solid #dedede;
    padding: 15px 25px;
}

.breadcrumb li,
.breadcrumb a {
    color: #4d4d4d;
    text-decoration: none;
}

.breadcrumb i.fa {
    margin: 0 8px;
    font-size: 10px;
    color: #4d4d4d;
}

table {
    font-size: 14px;
    color: #313131;
}

.table thead th,
.table tbody td {
    vertical-align: middle;
}

.table thead th {
    border-bottom: 1px solid #bebebe;
    border-top: 1px solid #bebebe;
}

.main-wrapper-padding {
    padding: 30px 10px;
}

.table-section {
    padding: 15px;
}

.chatbottom-box {
    border: 1px solid #ced4da;
    position: absolute;
    bottom: 0;
    right: 5px;
    left: 5px;
}

.chatbottom-box .chat-head {
    padding: 5px;
    border-bottom: 1px solid #eeeeee;
}

.chatbottom-box .chat-body {
    padding-left: 5px;
    padding-right: 5px;
    max-height: 323px;
    overflow-y: auto;
}

.video-mainwrapper {
    background-color: #2f2933;
    padding: 20px;
}

.video-wrapper {
    position: relative;
}

.video-wrapper .videoBig {
    width: 100%;
    height: 415px;
    background-color: #444;
}

.video-wrapper .videosmall {
    width: 200px;
    height: 150px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: #c3c3c3;
}

.time-counting {
    width: auto;
    background-color: #5f5f5f;
    color: #fff;
    display: inline-block;
    padding: 2px 10px;
    margin-bottom: 10px;
    font-size: 12px;
}

.end-chatbtn {
    background-color: #c83929;
    color: #fff;
    border-color: #c83929;
    text-transform: capitalize;
    margin-right: 0;
    font-weight: 400;
    padding: 5px 20px;
    border-radius: 5px;
    margin: 0 10px;
    text-decoration: none;
}

.video-left-wrapper {
    border: 1px solid #ebebeb;
    padding: 10px;
    position: relative;
    height: 550px;
}

.video-chat-left {
    margin-bottom: 30px;
    text-align: left;
    padding-left: 20px;
    border-bottom: 1px solid #e6ecf0;
    background-color: #e4e4e4;
    line-height: 15px;
    padding-top: 5px;
}

.vChatSpecial {
    margin: 0 0 5px;
    font-size: 14px;
}

.rxspan {
    background-color: #fff;
    padding: 2px 3px;
    float: right;
    margin-right: 5px;
}

.rxspan img {
    background-size: contain;
    width: 20px;
    height: 20px;
}

.chat-upload {
    background-color: #80ccf3;
    border: 0;
    color: #fff;
    padding: 2px 9px;
}

.prescribe-wrapper {
    background-color: #fff;
    border: 1px solid #c3c3c3;
    position: absolute;
    padding: 10px;
    bottom: 5px;
    right: 3px;
    left: 3px;
}

.prescribe-wrapper textarea {
    height: 150px;
    font-size: 14px;
}

.fa.close-icon {
    position: absolute;
    top: 15px;
    right: 5px;
    background-color: red;
    color: #fff;
    padding: 2px;
    cursor: pointer;
    font-size: 12px;
}

.chatbtn {
    float: right;
    background-color: #254fa0;
    color: #fff;
    border: 0;
    font-size: 14px;
    padding: 4px 10px;
}

.time-doctor {
    background-color: #fff;
    display: block;
    width: 100%;
    padding: 0 5px;
    font-size: 14px;
}

.textchat-wrapper {
    padding: 5px;
}

.custom-search select,
.custom-search .input-design,
.custom-search .react-datepicker__input-container input {
    font-size: 12px;
    padding: 0.4rem 1rem;
}

.btn-brdr {
    border: 1px solid var(--common-button-bgcolor);
    color: var(--common-button-bgcolor);
    padding: 4px 10px;
    border-radius: 50%;
    background-color: transparent;
    margin-right: 10px;
}

.btn-grbrdr {
    border: 1px solid #bebebe;
    color: #bebebe;
    padding: 4px 10px;
    border-radius: 50%;
    background-color: transparent;
}

input[type="text"]:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus,
select:focus,
:focus,
.bootstrap-select .dropdown-toggle:focus,
textarea:focus {
    outline: 0;
    border-color: var(--focus-input-color);
}

input:read-only:focus,
textarea:read-only:focus {
    border-color: #afafaf;
    cursor: default;
}

button:focus,
input[type="button"]:focus,
input[type="submit"]:focus {
    outline: 0;
    border-color: #000;
}

.btn-bg,
.btn-bg:hover {
    background-color: var(--common-color);
    color: #fff;
    margin-right: 10px;
    padding: 0.22rem 0.75rem;
    border: 0;
}

.btn-bg:focus {
    box-shadow: unset;
}

.practiceTimeline-panel {
    width: 100%;
    float: right;
    border-radius: 20px;
    padding: 30px 0;
    position: relative;
    background: white;
    margin-bottom: 20px;
    box-shadow: 2px 2px 10px #dcdcdc;
    padding-right: 10px;
}

.practiceTimeSpecific {
    display: block;
    width: 50px;
    height: 100%;
    background: #3498db;
    min-height: 100%;
    float: left;
}

.practiceTimeSpecific .timeSpecificBlock {
    font-size: 26px;
    clear: both;
    display: block;
    text-align: center;
    font-weight: bold;
    color: white;
    margin: 0;
}

.practiceTimeSpecific .timeSpecificSubBlock {
    display: block;
    font-size: 10px;
    text-align: center;
    margin-top: -6px;
    margin-bottom: 3px;
}

.practiceTimelineDivision {
    float: right;
    width: 92%;
    display: inline-block;
    margin-left: 10px;
}

.practiceTimeline-heading {
    position: relative;
}

.practiceTimeline-title {
    margin-top: 0;
    margin-bottom: 0;
    color: #616161;
    font-size: 14px;
    clear: both;
    overflow: hidden;
}

.practiceTimelineWrap .practiceTitleTop span {
    width: 33.333%;
    line-height: 20px;
    display: inline-block;
    color: black;
    font-weight: 400;
}

.mrgRgtPrctmngt {
    margin-right: 30px;
}

.practiceTimelineWrap .statusPrctmngt {
    margin-left: 0;
    display: inline-block;
    float: left;
    text-align: center;
    color: #777;
    margin-right: 10px;
    font-size: 14px;
}

.appnt-brdr {
    position: relative;
    padding-top: 10px;
    font-size: 13px;
}

.appnt-date-time {
    padding-top: 0;
}

.appmt-month {
    color: #c1c1c1;
    font-weight: 600;
    font-size: 16px;
}

.appmt-date {
    color: #2ba1e5;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
}

.appmt-time {
    color: #221f1f;
    font-weight: 600;
    font-size: 13px;
    position: relative;
}

.appmt-edit {
    color: #494949;
    font-size: 14px;
}

.appnt-drName {
    font-weight: 500;
    color: #373737;
}

.appnt-hospitalName {
    font-size: 13px;
    color: #373737;
}

.appnt-center {
    color: #373737;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
}

.appnt-span-det {
    color: #494949;
    font-weight: 400;
    float: right;
    text-align: left;
    width: 125px;
}

.appnt-type {
    color: #b0b0b0;
    /* font-size: 18px; */
    font-weight: 500;
    padding-right: 0;
}

.appnt-type-text {
    display: block;
    color: #4d4d4d;
}

.appnt-status {
    color: #b0b0b0;
    /* font-size: 18px; */
    font-weight: 500;
    position: relative;
    padding-right: 0;
}

.appnt-status-text {
    display: block;
    color: #3ac451;
    text-transform: lowercase;
    /* font-size: 18px; */
}

.appnt-status-canceltext {
    color: var(--cancel-button);
}

.appnt-status-confirmtext {
    color: #2ba1e5;
}

.appnt-status-completetext {
    color: #737373;
}

.appnt-btn {
    width: 75px;
    padding: 1px 5px;
    font-size: 12px;
    min-width: auto;
    font-weight: 400;
    margin: 0;
    margin-top: 7px;
}

.appnt-brdr:after {
    position: absolute;
    content: "";
    right: 0;
    width: 1px;
    height: 70px;
    top: 7px;
    background-color: #dedede;
}

.appnt-brdr.appnt-status:after {
    display: none;
}

.time-date-popup .popup-content {
    padding: 40px 50px;
    width: 500px;
}

.time-date-popup .popup-content .header,
.time-date-popup .newsfeed-popup .header {
    font-size: 18px;
}

.time-date-popup .react-datepicker-wrapper,
.time-date-popup .app-popup-time {
    width: 150px;
    display: block;
}

.time-date-popup .popup-content .content {
    overflow: inherit;
}

.appointmentEditicon {
    position: absolute;
    right: 10px;
    top: 10px;
}

.statusPrctmngt.confirm.mrgRgtPrctmngt {
    color: #710600;
    font-weight: 600;
    margin: 0 0 0 40px;
    width: auto;
}

.list-section {
    margin-top: 40px;
}

.elipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pendingLink {
    text-align: right;
    margin-bottom: 10px;
}

.pendingLink a {
    text-decoration: none;
    color: #6c6c6c;
    font-weight: 600;
    font-size: 13px;
}

.linktextclr,
.linktextclr:hover {
    color: var(--common-color);
    text-decoration: none;
}

.memberHead {
    padding: 15px 20px;
    margin: 0 auto;
    border: 1px solid gainsboro;
    background: white;
    border-radius: 20px;
}

.memberHead img {
    width: 30px;
    margin-right: 20px;
    vertical-align: bottom;
}

.memberHead h4 {
    color: var(--common-color);
}

.messageCenterDiv {
    margin: 5px auto;
    background: white;
    padding: 15px;
}

.messageCenterDiv ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dashBoard .messageCenterDiv {
    padding: 15px;
    top: 10px;
    display: block;
    width: 100%;
    border: 1px solid gainsboro;
}

.messageCenterDiv .sent_btn a,
.messageCenterDiv .inbox_btn a {
    display: block;
    padding: 12px;
    text-align: center;
    color: #7a7a7a;
    background: 0;
    font-size: 16px;
    text-decoration: none;
    font-weight: 500;
    border-radius: 20px;
}

.messageCenterDiv .messageBlock li.active a {
    background: #f2f2f2;
    font-weight: 700;
}

.messageCenterDiv .searchMessage {
    padding: 6px 10px;
    margin-bottom: 10px;
    position: relative;
}

.messageCenterDiv .search_message {
    padding: 0 12px 3px;
    font-size: 0.8333333333em;
    color: #333;
    width: 60%;
    height: 35px;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}

.emailbox-brdr {
    border-radius: 20px;
    border: 1px solid #dedede;
}

.messageCenterDiv .emailBox {
    padding: 8px;
    border: 1px solid #dedede;
    margin-bottom: 10px;
    margin-top: 20px;
    border-radius: 20px;
}

.messageCenterDiv .emailBox li {
    border: 1px solid #dedede;
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 10px;
}

.messageCenterDiv .emailBox .sender {
    width: 40%;
    display: inline-block;
}

.messageCenterDiv .emailBox .subject {
    width: 60%;
    display: inline-block;
}

.messageCenterDiv .emailBox .subject a {
    text-decoration: none;
}

.messageCenterDiv .emailBox .inbox-name {
    font-size: 1em;
    color: #000;
    margin-bottom: 3px;
    text-transform: uppercase;
    font-weight: 500;
}

.messageCenterDiv .emailBox .inboxdate-time,
.messageCenterDiv .emailBox .inbox-text {
    color: #4d4d4d;
    font-size: 0.88em;
}

.messageCenterDiv .emailBox .inbox-sendto {
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
    color: #000;
    text-transform: capitalize;
}

.messageCenterDiv .emailBox .inbox-askdr {
    font-size: 1em;
    color: #000;
    margin-bottom: 3px;
    text-transform: capitalize;
}

.addAttachment {
    margin-bottom: 10px;
    padding-right: 25px;
    font-size: 0.8888888889em;
    display: inline-block;
    position: relative;
    background: #686868 none repeat scroll 0 0;
    color: #fff !important;
    border-radius: 4px;
    text-align: center;
    padding: 5px;
    float: left;
    margin-right: 10px;
}

.mailArea table {
    background: #fff;
    border: 1px solid #e7e7e7;
    margin-bottom: 10px;
    width: 100%;
}

.mailArea td,
.mailArea th {
    padding: 10px;
}

.mailArea td {
    border: 1px solid #e7e7e7;
}

.info.infoDivSec {
    width: 50%;
    float: left;
    padding-right: 5px;
    word-break: break-all;
}

.attachment.attachmentDivSec {
    width: 50%;
    float: left;
    padding-left: 5px;
    word-break: break-all;
}

.mailArea {
    margin-top: 20px;
}

.mailArea .name {
    font-size: 1em;
    color: #000;
    display: block;
    margin-bottom: 5px;
}

.mailArea .date {
    color: #585656;
    font-size: 0.9em;
    display: block;
}

.mailArea h5 {
    font-size: 1em;
    font-weight: normal;
    color: #585656;
}

.practiceTimeline-heading small {
    font-weight: 500;
}

.healthpageDocName {
    font-weight: 700;
    font-size: 14px;
}

.dr-profileImg {
    width: 25px;
    /* border-radius: 50%; */
    height: 25px;
    /* margin-top: 10px; */
    object-fit: contain;
}

.dashBoardLeftMenu-active {
    font-weight: 700;
    background-color: #f3e6ff;
    border-radius: 20px;
}

.drpagedetail {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    background-color: #d3edff;
    border-radius: 20px;
    width: 100%;
    padding: 10px 20px;
    align-items: center;
}

.drpagedetailName {
    vertical-align: top;
    padding-left: 15px;
}

.dashbrd-menu-leftt {
    border-right: 1px solid #dedede;
    padding-top: 30px;
    margin-bottom: 40px;
    min-height: 400px;
    padding: 30px 15px;
}

.healthpageDocSpl {
    font-weight: 600;
    font-size: 13px;
}

.LinksList img {
    width: 24px;
}

.LinksList li span {
    font-size: 12px;
    white-space: nowrap;
}

.LinksList .dashBoardMenu li a {
    color: #616161;
    text-decoration: none;
    position: relative;
    outline: 0px;
}

.LinksList ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

body span.dashMenuNotfcn {
    position: absolute;
    right: 0;
    top: 15px;
    width: 20px;
    height: 20px;
    background-color: #32a857;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    padding: 3px 0;
}

.QuickHead {
    padding: 20px 0;
    width: 100%;
    border-bottom: #ccc solid 1px;
    margin: 0 auto;
    color: #333;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
}

.dashboard-right {
    border: 1px solid gainsboro;
    padding: 20px;
    border-radius: 20px;
}

.dashboard-right.dashbrd-rght-bottom-padng-hsp {
    border-radius: 0;
    margin-top: 10px;
    padding: 0px 20px;
}

.dashboard-rightBg {
    background-color: #f4f4f4;
}

.accordion__heading .accordion__button {
    position: relative;
}

.accordion__heading .accordion__button:before {
    position: absolute;
    right: 0;
    top: 16px;
    border: none;
    content: "+";
    transform: rotate(0deg);
    font-size: 18px;
    font-weight: 900;
    cursor: pointer;
}

.accordion__heading .accordion__button[aria-expanded="true"]::before,
.accordion__heading .accordion__button[aria-selected="true"]::before {
    transform: rotate(0deg);
    content: "-";
}

.scrollDiv {
    min-height: 200px;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 5px;
    margin-bottom: 30px;
}


/* .dashboard-right .accordion {
  background-color: #f2f2f2;
} */

.dashboard-right .accordion__item {
    margin-bottom: 10px;
}

.accordion__button:focus {
    outline: 0;
}

.accordion .accordion__button {
    font-weight: 500;
    border-radius: 20px;
}

.accordion .accordion__button[aria-expanded="true"],
.accordion .accordion__panel {
    border-bottom: 1px solid #d9d9d9;
}

.accordion .accordion__button[aria-expanded="true"] .dashbrd-rght-bottom-padng-hsp,
.dashbrd-rght-bottom-padng-hsp .accordion .accordion__panel {
    border: 1px solid #d9d9d9;
    font-size: 14px;
}

.accordion .accordion__panel[hidden] {
    border-bottom: 0px solid #d9d9d9;
}

.accordion,
.accordion__item+.accordion__item {
    border: 0 !important;
}

.serviceWrap ul {
    margin: 0px auto 0;
    text-align: center;
    padding: 0;
}

.serviceWrap ul li {
    margin: 0 1%;
    width: 18%;
    vertical-align: top;
    padding: 10px 1% 30px;
    display: inline-block;
    text-align: center;
    margin-bottom: 20px;
    border: 1px solid #c5c5c5;
    border-radius: 20px;
}

.serviceWrap ul li .tabIcon img {
    width: 40px;
    margin-bottom: 10px;
    height: 40px;
    object-fit: contain;
}

.tabIcon span {
    font-weight: 500;
    width: 100%;
    clear: both;
    text-align: center;
    display: block;
    font-size: 16px;
    margin: 10px 0;
}

.serviceWrap ul li button {
    padding: 3px 30px;
}

.hospBox {
    margin: 8px 0;
    padding: 30px 5px 10px;
    padding-bottom: 16px;
    height: 115px;
    margin-top: 20px;
    position: relative;
}

.hospBox:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    right: 10%;
    height: 1px;
    background-color: #dedede;
}

.priceCover {
    margin-bottom: 10px;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
}

.serviceWrap ul li label {
    font-size: 11px;
    margin-bottom: 0;
    width: 40%;
    font-weight: 400;
}

.priceCover .popField {
    width: 60%;
    vertical-align: top;
    font-size: 12px;
    border: 1px solid #e4e4e4;
    padding-left: 10px;
    border-radius: 20px;
    background-color: #e4e4e4;
    color: #000;
    height: 25px;
}

.tabIcon {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: #4c4c4c;
    opacity: 0.9;
}

.tabIcon.tabeActiveClass {
    opacity: 0.9;
}

a.tabIcon:hover {
    color: #4c4c4c;
    text-decoration: none;
}

.serviceEnable {
    opacity: 0.4;
}

.adminDashboardBox .adminLeftBox {
    float: left;
    border: 1px solid gainsboro;
    width: 100%;
    margin: 10px 0;
}

.adminDashboardBox .adminLeftBox .adminLeftBoxBlock {
    padding: 15px;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    width: 100%;
}

.adminDashboardBox .adminLeftBox .adminLeftBoxBlock p {
    width: 100%;
    float: left;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #393939;
    cursor: pointer;
}

.adminDashboardBox .adminRightBox {
    border: 1px solid gainsboro;
    padding: 10px;
    display: inline-block;
    width: 100%;
    margin: 10px 0;
}

.adminDashboardBox .adminRightBox .requirementBox {
    display: block;
    clear: both;
    padding-left: 6px;
}

.adminDashboardBox .adminRightBox .requirementBox {
    display: block;
    clear: both;
    padding-left: 6px;
}

.main-wrapper p,
.main-wrapper1 p,
.main-wrapper li,
.main-wrapper1 li,
.main-wrapper-smwidth1 p,
.main-wrapper-smwidth p {
    font-size: 14px;
}

.search-main {
    background-color: #f2f2f2;
    padding: 10px;
    margin-bottom: 20px;
}

.star-red {
    color: #ff4040;
}

.badge-light {
    background-color: #e6e6e6;
    margin-right: 5px;
}

.badge {
    font-weight: 400;
    font-size: 14px;
}

.main-wrapper .font-18,
.main-wrapper1 .font-18 {
    font-size: 18px;
}

.font-16 {
    font-size: 16px;
}

.modal-popup,
.modal-popup p {
    font-size: 14px;
}

.popup-overlay {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99;
    /* display: none; */
}

.signInWrap {
    display: none;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 999;
}

body .popup-content,
body .popup-attach {
    padding: 10px;
    border-radius: 10px;
    position: relative;
    background: rgb(255, 255, 255);
    width: 50%;
    margin: auto;
    border: 1px solid rgb(187, 187, 187);
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

body .popup-attach {
    width: 700px;
}

.modal-popup>.header {
    width: 100%;
    border-bottom: 1px solid gray;
    padding: 5px;
    color: #3f3d56;
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    height: auto;
}

.modal-popup>.content {
    width: 100%;
    padding: 10px 20px;
    color: #0f0f10;
    max-height: 85vh;
    overflow: auto;
}

.modal-popup>.actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.modal-popup>.close-popup {
    cursor: pointer;
    position: absolute;
    display: block;
    line-height: 0;
    right: 5px;
    top: 5px;
    /* font-size: 24px; */
    background: #3f3d56;
    border-radius: 18px;
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    opacity: 0.8;
    transition: 1s;
    padding: 11px 0;
}

.modal-popup>.close-popup:hover {
    opacity: 1;
    color: #fff;
    text-decoration: none;
}

textarea#embedText {
    height: 185px;
    width: 100%;
    resize: none;
}

.bltHeading {
    float: left;
    font-size: 20px;
    font-weight: 600;
}

.bulletinBoardHead {
    padding: 15px 0;
    width: 100%;
    margin: 0 auto;
    clear: both;
    overflow: hidden;
}

.blogVisitPage {
    display: block;
    padding: 10px;
}

.blogVisitPage .blogVisitBlock {
    display: block;
    background: white;
    margin-bottom: 10px;
    width: 85%;
}

.blogVisitPage .blogVisitBlock .blogVistAvtarBlock {
    display: table-cell;
    border: 3px solid white;
    padding-top: 0;
}

.blogVisitPage .blogVisitBlock .blogVistAvtarBlock a {
    vertical-align: middle;
    border-radius: 100%;
    width: 35px;
    height: 35px;
}

.blogVisitPage .blogVisitBlock .blogVisitAvatarInfo {
    display: table-cell;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.4;
    padding-left: 10px;
    text-rendering: auto;
}

.blogVisitPage .blogVisitBlock .blogVisitAvatarInfo span.avtarname {
    display: inline-block;
}

.smlFollow {
    color: var(--common-button-bgcolor);
    padding: 2px 15px;
    border-radius: 20px;
    text-transform: capitalize;
    border: 1px solid var(--common-button-bgcolor);
    margin: 10px 10px;
    background-color: #fff;
    text-decoration: none;
    font-size: 12px;
    min-width: 120px;
    font-weight: 600;
}

.blogVisitPage .blogVisitBlock .blogVisitAvatarInfo span.datetime {
    display: block;
    color: #b8b8b8;
    font-size: 12px;
}

.blogVisitPage .blogVisitBlock .blogVisitbannerBlock {
    width: 100%;
    margin-top: 10px;
}

.blogVisitPage .blogVisitBlock .blogVisitbannerBlock img {
    max-width: 100%;
    /* width: 100%; */
    height: auto;
    object-fit: contain;
}

.blogVisitPage .blogVisitBlock .blogVisitHead {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.9em;
    font-weight: 500;
    line-height: 35px;
}

.blogVisitPage .blogVisitBlock .blogVisitPara {
    display: block;
    clear: both;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1em;
    line-height: 30px;
    color: #393939;
    width: 98%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -10px;
    cursor: pointer;
    opacity: 0.7;
}

.blogVisitPage .blogVisitBlock .blogVisiIconsSet {
    display: block;
    width: 100%;
    position: relative;
    height: 50px;
    margin-bottom: 10px;
    clear: both;
}

.blogVisitPage .blogVisitBlock .blogVisiIconsSet span.blogVisitLike {
    position: absolute;
    top: 10px;
    cursor: pointer;
    font-size: 0.8888888889em;
    text-align: center;
}

.blogVisitPage .iconTwo span {
    color: var(--theme-color);
    margin-left: 5px;
}

.blogVisitPage .blogVisitBlock .blogVisiIconsSet span.blogVisitLike .iconTwo img {
    max-width: 25px;
    max-height: 25px;
}

.blogVisitBlock a {
    color: #393939;
    text-decoration: none;
}

.blogVisitPage .blogVisitBlock .blogVistAvtarBlock a img {
    max-width: 30px;
    max-height: 30px;
    border-radius: 100%;
}

.btn-icons {
    background-color: var(--common-color);
    color: #fff;
    margin-right: 10px;
    padding: 4px 6px;
    border: 0;
    float: right;
    line-height: 0;
    margin-top: 7px;
}

.btn-icons .fa {
    font-size: 10px;
}

.memberHeadGrayBg h3 {
    margin-bottom: 0;
}

.hrecordFileUpload {
    background: transparent url(../image/theme/img.png) no-repeat scroll 0 0 !important;
    width: 30px;
    height: 30px;
    float: left;
    margin-right: 5px;
    padding: 5px;
    margin-top: 5px;
    cursor: pointer;
    margin-bottom: 5px;
    margin-left: 5px;
    position: relative;
    cursor: pointer;
}

.hrecordFileUpload input.uploadattach {
    position: absolute;
    width: 24px;
    left: 0;
    top: 0;
    opacity: 0;
}

.fileRecordThumb {
    width: 75px;
    position: relative;
    margin: 0 20px;
    display: inline-block;
}

.fileRecordThumb img {
    width: 100%;
    object-fit: contain;
    max-height: 50px;
}

.fileRecordThumb-list .fileRecordThumbImg {
    height: 100px;
}

.fileRecordThumb .thumbClose {
    position: absolute;
    top: -4px;
    right: 5px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: #fff;
    cursor: pointer;
}

.fileRecordThumb-list {
    width: 23%;
    height: 200px;
    padding: 20px;
    border: 1px solid #eaeaea;
    margin: 1%;
    border-radius: 20px;
    text-align: center;
    vertical-align: top;
}

.fa-edit {
    cursor: pointer;
}

.treatmentTimelineBlockMain {
    margin-top: 30px;
    overflow: hidden;
}

.timelineList {
    padding: 10px 0 5px 22px;
    border-left: 4px solid rgba(130, 0, 255, 0.3);
}

.timelineListDate {
    clear: both;
    overflow: hidden;
    margin-bottom: 15px;
}

.timelineDate {
    font-size: 30px;
    color: rgba(130, 0, 255, 0.7);
    font-weight: 600;
    float: left;
    line-height: 33px;
}

.timelineDateCvr {
    float: left;
    font-size: 11px;
    padding-left: 10px;
}

.timelineMnthDate {
    color: #6f6f6f;
    margin-bottom: 2px;
}

.timelineDateCvr span {
    display: block;
}

.timelineDay {
    font-weight: 600;
    color: #000;
}

.timelineListContent {
    padding: 10px 10px 10px 25px;
    border: 1px solid #ddd;
    position: relative;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.timelineArrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #ddd transparent transparent;
    display: inline-block;
    position: absolute;
    left: -15px;
    top: 15px;
}

.timelineArrow:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 13px 9px 0;
    border-color: transparent #fff transparent transparent;
    left: 2px;
    top: -9px;
    position: absolute;
}

.timelineCircle {
    width: 15px;
    height: 15px;
    background: rgba(130, 0, 255, 0.5);
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    position: absolute;
    left: -32px;
    top: 16px;
    border: 3px solid #fff;
}

.timelineListName {
    clear: both;
    overflow: hidden;
    padding-bottom: 15px;
}

.timelineListName img {
    width: 20px;
    float: left;
    padding-top: 4px;
}

.timelineListName span {
    padding-left: 10px;
    font-size: 18px;
    float: left;
    color: #4a4a4a;
    font-weight: 500;
}

.appoinmentDateDetail {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 15px;
    padding-top: 15px;
}

.timelineListContent .appoinmentDateDetail:nth-child(2n) {
    border: 0;
    padding-top: 0;
}

.treatmentComplaintRow {
    clear: both;
    overflow: hidden;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
}

.treatmentComplaintLeft {
    width: 20%;
    color: #737373;
    float: left;
    text-align: left;
    font-size: 13px;
    padding-right: 10px;
}

.treatmentComplaintRight {
    width: 80%;
    float: left;
    padding-left: 10px;
    border-left: 1px solid #2980b9;
    position: relative;
    font-size: 13px;
}

.treatmentComplaintNoted span {
    font-weight: 700;
    color: #000;
    padding-left: 5px;
}

.treatmentComplaintRight span {
    display: block;
    margin-bottom: 5px;
    float: left;
    width: 100%;
}

.treatmentComplaintNoted {
    padding: 10px 7px;
    width: 100%;
    border: 0;
    background: #fff;
    font-weight: 500;
    font-size: 14px;
}

.treatmentTable table {
    width: 100%;
    background: #fff;
    border: 1px solid #afafaf;
}

.treatmentTable table thead,
.treatmentTable table tbody {
    border: 0 !important;
}

.treatmentTable table tr {
    border: 1px solid #afafaf;
}

.treatmentTable table td,
.treatmentTable table th,
.treatmentTable table td span {
    font-size: 11px;
    text-align: left !important;
    width: 100%;
}

.dentalProName,
.dentalProCost,
.dentalProDis,
.dentalProTotal,
.dentalProNote {
    padding: 10px 7px;
    float: left;
    width: 100%;
    position: relative;
    color: #000;
}

.treatmentTable table .dentalProPlaned {
    border: 0;
    border-bottom: 1px solid #afafaf;
    background: #f3f3f3;
}

.optionWrapper {
    float: left;
    padding-right: 5px;
    line-height: 13px;
    width: 25px;
}

.dentalProName span.elipsis {
    max-width: 150px;
    margin-top: 5px;
    margin-left: 14px;
    float: left;
}

.treatmentTimelineBlockMain th,
.treatmentTimelineBlockMain td {
    padding: 10px 15px;
}

.plannedBy {
    padding: 7px 7px;
    font-weight: 500;
}

.treatmentTable table span {
    color: #000;
}

.plannedBy span {
    float: none;
    padding-left: 5px;
    font-weight: 700;
}

.estimatedPrice {
    padding: 7px 7px;
    font-weight: 700;
    text-align: right;
}

.treatmentTable {
    width: 100%;
    overflow-x: auto;
}

.treatmentPlanDate {
    color: #272727;
    font-weight: 300;
    font-size: 12px;
    padding: 8px 10px;
    position: relative;
    text-align: center;
}

.treatmentCommonTxtWrap {
    border: 1px solid gainsboro;
    margin: 10px 0;
    text-align: center;
    width: 100%;
    padding: 50px;
}

.date-time-design {
    width: 120px;
    font-size: 12px;
    padding: 0.25rem 0;
    margin-right: 2px;
}

.purpleWrap .rc-time-picker-input {
    width: 66px;
    border: 1px solid #ced4da;
    background-color: #fff;
    border-radius: 20px;
    height: 30px;
    padding: 4px 4px;
    font-size: inherit;
}


/* .purpleWrap .rc-time-picker-clear {
  right: 3px;
  width: 14px;
  top: 5px;
} */

.formButtonBold {
    font-weight: 600 !important;
}

.addNew-btn {
    color: var(--common-button-bgcolor);
    padding: 5px 25px;
    border-radius: 20px;
    text-transform: capitalize;
    border: 1px solid var(--common-button-bgcolor);
    background-color: #fff;
    text-decoration: none;
    font-size: 14px;
    float: right;
    font-weight: 600;
    margin-bottom: 10px;
    cursor: pointer;
    margin-top: 10px;
}

.addNew-btn:hover {
    text-decoration: none;
}

.add-new-plus {
    background-color: var(--common-button-bgcolor);
    color: #fff;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    line-height: 18px;
    margin-right: 10px;
}

.table td {
    border: 0;
    padding: 15px 0.75rem;
}


/* thead,
tr:nth-child(even) {
    background-color: #f9f3ff;
} */

.success-text {
    color: var(--theme-color);
    font-weight: 600;
}

.pending-text {
    color: var(--theme-color);
    font-weight: 600;
}

.history-main .checkbox-main .checkbox {
    top: -7px;
}

.history-center,
.history-reason,
.history-type,
.history-status,
.history-type-div {
    padding-top: 10px;
}

.history-brdr {
    position: relative;
}

.history-date-time {
    padding-top: 0;
    text-align: center;
}

.history-month {
    color: #c1c1c1;
    font-weight: 600;
    font-size: 20px;
}

.history-date {
    color: #2ba1e5;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
}

.history-day {
    color: #242424;
    font-weight: 600;
    font-size: 14px;
    position: relative;
}

.history-drName {
    font-weight: 500;
    color: #373737;
}

.history-hospitalName {
    font-size: 14px;
    color: #373737;
}

.history-type,
.history-reason {
    color: #b0b0b0;
    font-size: 18px;
    font-weight: 500;
    padding-right: 0;
}

.history-type-text {
    display: block;
    color: #4d4d4d;
}

.history-status {
    color: #b0b0b0;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    padding-right: 0;
}

.history-status-text {
    display: block;
    color: #3ac451;
    text-transform: lowercase;
    font-size: 18px;
}

.history-status-canceltext {
    color: var(--cancel-button);
}

.history-status-confirmtext {
    color: #2ba1e5;
}

.history-status-completetext {
    color: #737373;
}

.history-brdr:after {
    position: absolute;
    content: "";
    right: 0;
    width: 1px;
    height: 70px;
    top: 7px;
    background-color: #dedede;
}

.history-brdr.history-status:after {
    display: none;
}

.history-type-div {
    color: #b0b0b0;
    font-weight: 500;
    font-size: 14px;
}

.history-text {
    color: #373737;
    display: block;
}

.history-type-det {
    font-weight: 500;
    font-size: 12px;
    color: #373737;
    text-transform: uppercase;
}

.history-span-det {
    color: #494949;
    font-weight: 400;
    float: right;
    text-align: left;
    width: 115px;
}

.alert-danger .close,
.error-msg {
    color: #f00;
}


/*** alert ***/

.warnalert {
    /* position: absolute; */
    z-index: 1;
    left: 1px;
    right: 1px;
    top: 1px;
}

.alert-danger {
    color: #721c24;
    background-color: #ffeaec;
    border-color: #ffd4d9;
}

.main-wrapper {
    position: relative;
}


/*** alert ***/


/*
by unni
*/

.loader {
    width: 100%;
    height: 100%;
    position: fixed;
    display: none;
    top: 0;
    z-index: 9999;
    opacity: 0.5;
    left: 0;
    right: 0;
}

.loader:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    width: 100%;
    height: 100%;
    background: url("../image/theme/loader.gif") 50% 50% no-repeat rgb(249, 249, 249);
}

.loader2 {
    width: 100%;
    height: 100%;
    position: fixed;
    display: none;
    top: 0;
    z-index: 9999;
    opacity: 0.5;
    left: 0;
    right: 0;
}

.loader2:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    width: 100%;
    height: 100%;
    background: url("../image/theme/loader.gif") 50% 50% no-repeat rgb(249, 249, 249);
}

.OT_publisher {
    /* width: 100% !important;
  height: 150px !important; */
}

.OT_subscriber {
    /* width: 100% !important; */
    /* height: 415px !important; */
}

body .otpButton {
    padding: 4px 15px;
    display: inline-block;
    font-size: 12px;
    background: var(--common-button-bgcolor);
    color: var(---common-button-txtcolor);
    border-radius: 3px;
    margin: 0;
    cursor: pointer;
    font-weight: 600;
    width: 115px;
    text-align: center;
    height: 27px;
    position: absolute;
    right: 1px;
    top: 10px;
    text-decoration: none;
    border-radius: 0 20px 20px 0;
    border: 0;
}

.border-underline {
    position: relative;
}

.border-underline:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 43%;
    height: 1px;
    background-color: var(--common-color);
    margin: 0 auto;
    right: 43%;
}

.chat-amount-outer {
    /* width: 380px; */
    background-color: #f5f5f5;
    border-radius: 20px;
    margin: 20px auto 15px;
    padding: 10px 5px 5px;
}

.term-condtn-main {
    line-height: 13px;
    font-size: 14px;
    padding-left: 10px !important;
}

.term-condtn-main input[type="checkbox"] {
    margin-right: 10px;
    vertical-align: top;
}

.term-condtn-main-postn {
    float: right;
}

.label-text-brdr {
    position: relative;
    text-align: center;
    font-weight: 500;
    padding-bottom: 5px;
}

.label-text-brdr:after {
    position: absolute;
    content: "";
    bottom: 0;
    background-color: #dedede;
    left: 36%;
    right: 36%;
    height: 1px;
}


/*** clients ***/

.client-wrapper {
    width: 800px;
    margin: auto;
}

.parteners-detail::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

.parteners-detail::-webkit-scrollbar {
    width: 2px;
    background-color: #f5f5f5;
}

.parteners-detail::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
}

.parteners-main {}

.parteners-list {
    width: 30.3%;
    border: 1px solid #8f4dce;
    border-radius: 20px;
    overflow: hidden;
    display: inline-block;
    margin: 1.5%;
    vertical-align: top;
}

.parteners-list .parteners-img {
    padding: 35px 20px;
    text-align: center;
}

.parteners-list .parteners-img img {
    width: 150px;
}

.parteners-list .parteners-detail {
    background-color: #f1e3ff;
    padding: 20px;
    text-align: center;
    line-height: 20px;
    height: 150px;
    overflow-y: auto;
}

.parteners-detail .parteners-detailhead {
    font-weight: 700;
    font-size: 16px;
    color: #2a2a2a;
}

.parteners-detail .parteners-detailtext {
    font-size: 12px;
    color: #494949;
    margin-top: 10px;
    line-height: 15px;
}


/*** clients ***/

.v-schedule-dpick .react-datepicker-wrapper {
    width: 150px;
    margin-right: 5px;
}

.v-schedule-dpick .react-datepicker-wrapper:after {
    top: 3px;
}

.react-datepicker-popper {
    z-index: 9 !important;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #989898;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #989898;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #989898;
}

.drDetailHospital .drDetailHospitalTitle {
    color: #000;
}

.drDetailHospital .drDetailHospitalContent p {
    font-size: 16px;
    font-weight: 300;
    color: #000;
}

.drDetailHospitalPadding {
    padding: 40px 20px;
}

.drDetHosptl {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 0;
}

.customCalenderDiv {
    background: #fff;
    padding: 30px 35px;
}

.dateHeadList {
    text-align: center;
    /* background: #3f3c57; */
    border-radius: 5px;
    color: #3f3c57;
    padding: 5px 0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    border-bottom: 1px solid #eaeaea;
}

.dateListItem {
    padding: 0 4px;
}

.dateHeadList p {
    color: #4e4e4e;
    font-size: 11px;
    margin: 0;
}

.dateHeadList span {
    display: block;
    margin: 0 0 2px;
}

body .timeList {
    display: block;
    text-align: center;
    border: 1px solid #ddd;
    margin: 7px 0;
    padding: 2px 4px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    color: #484848;
}

.timeListWrap span {
    display: block;
    text-align: center;
}

.timeListWrap {
    text-align: center;
}

.timeListWrapInner {
    min-height: 218px;
    position: relative;
    padding: 0px 2px 32px;
    max-height: 218px;
    overflow: hidden;
}

.dateHeadListCvr {
    margin: 0 0 5px;
    padding: 0 2px;
}

body .timeListMore {
    display: block;
    text-align: center;
    background: #e4e4e4;
    margin: 7px 0 0;
    padding: 4px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    color: #484848;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.customCalenderDiv .slick-prev,
.customCalenderDiv .slick-next {
    top: 27px;
}

.customCalenderDiv .slick-prev:before,
.customCalenderDiv .slick-next:before {
    display: none;
}

.customCalenderDiv .slick-prev,
.customCalenderDiv .slick-prev:hover,
.customCalenderDiv .slick-prev:focus {
    background: url(../image/media/arrow-left.png);
    width: 17px;
    height: 17px;
    background-size: cover;
}

.customCalenderDiv .slick-next,
.customCalenderDiv .slick-next:hover,
.customCalenderDiv .slick-next:focus {
    background: url(../image/media/arrow-right.png);
    width: 17px;
    height: 17px;
    background-size: cover;
}


/***************** dash add section**************************/

.dashSection {
    padding: 30px 0;
}

.sectionCover {
    margin: auto;
}

body .dashLabel {
    width: 25%;
    margin: 8px 0 0;
    font-size: 17px;
    color: #565656;
    text-align: left;
    padding: 0 35px 0 0;
    font-weight: 600;
}

.dashInputContent {
    width: 64%;
}

.sectionTop {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: flex-start;
    margin: 0 0 15px;
    padding: 20px 30px;
}

body .dashInput,
body .dashTextarea {
    width: 100%;
    height: 45px;
    padding: 2px 15px;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
    font-size: 17px;
    color: #565656;
}

.sectionAddBlock {
    position: relative;
    padding: 30px 30px;
    background: #f7f7f7;
    border-radius: 10px;
    margin: 0 0 20px;
}

body .addBtns {
    background-color: #33aaff;
    padding: 6px 15px;
    border-radius: 20px;
    color: #fff;
    font-weight: 400;
    position: absolute;
    right: 15px;
    cursor: pointer;
    top: 0;
}

.sectionFieldList {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    align-items: flex-start;
    margin: 0 0 20px;
    position: relative;
}

.sectionAddList {
    text-align: center;
}

.sectionFieldListSub {
    background-color: whitesmoke;
    border-radius: 10px;
    width: 48%;
    display: inline-block;
    margin: 1%;
    padding-top: 20px;
}

.sectionFieldListSub .dashLabel {
    font-size: 14px;
}

body .dashTextarea {
    resize: none;
    height: 125px;
}

.uploadButtonBlock input[type="file"] {
    display: none;
}

.uploadButtonBlock label {
    height: 40px;
    padding: 0px 25px;
    border: 1px solid #000000;
    border-radius: 30px;
    font-size: 16px;
    color: #000000;
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
}

.dashBtnSec .commonButton {
    display: inline-block;
    padding: 9px 35px;
    margin: 0 0 0 20px;
}

.dashBtnSec {
    text-align: right;
    padding: 15px 0 0;
    width: 89%;
}

body .commonButtonCancel {
    background-color: #6d6b6b;
}

body .delBtns {
    background-color: #ff4646;
    border-radius: 50%;
    color: #fff;
    font-weight: 300;
    position: absolute;
    right: 30px;
    cursor: pointer;
    width: 35px;
    height: 35px;
    line-height: 29px;
    text-align: center;
    font-size: 21px;
    top: 5px;
}

.dashPopUp {
    /* position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 1; */
    /* display: none; */
    border: 1px solid #c3c3c3;
    border-radius: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.dashPopOverLay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #0000007d;
    z-index: 1;
}

.dashPopUpContent {
    width: 80%;
    background: #fff;
    padding: 0;
    margin: 30px auto 0;
    border-radius: 10px;
    position: relative;
    z-index: 2;
}

.dashPopUpTitle {
    position: relative;
    padding: 0 0 35px;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}

.addSectionWrap {
    width: 805px;
}

body .dashPopClose {
    position: absolute;
    right: 20px;
    top: 18px;
    border: 1px solid #9e9e9e;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    font-weight: 300;
    font-size: 24px;
    cursor: pointer;
}

.seoDropDown,
.sectionDropDown {
    padding: 20px;
}

.sectionItemDiv {
    margin: 0 0 20px;
}

.sectionItemTitle {
    font-size: 26px;
    color: #5a5a5a;
    font-weight: 700;
    margin: 0 0 20px;
}

.sectionItemContent {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    flex-wrap: wrap;
}

body .sectionTag {
    border-radius: 5px;
    padding: 30px 30px;
    color: #616161;
    text-transform: uppercase;
    display: inline-flex;
    cursor: pointer;
    box-shadow: 0 0 7px #ccc;
    font-weight: 600;
    background: #fff;
    font-size: 16px;
    margin: 20px 10px;
}

body .sectionLargeTag {
    border-radius: 5px;
    padding: 40px 50px;
    color: #616161;
    text-transform: uppercase;
    margin: 0;
    display: inline-flex;
    cursor: pointer;
    box-shadow: 0 0 7px #ccc;
    font-weight: 600;
    flex-wrap: wrap;
    font-size: 16px;
    background: #fff;
}

body .sectionLargeTag:hover,
body .sectionTag:hover {
    background: #f5f5f5;
}

body .sectionTagDiv {
    margin: 0 20px 20px 0;
}

.sectionItemContentBorder {
    float: left;
    width: 100%;
}

body .sortList {
    display: block;
    width: 100%;
    font-size: 14px;
    text-transform: capitalize;
    text-align: left;
    margin: 15px 0 0;
    color: #4c4c4c;
}

.sortList input[type="text"] {
    margin: 0 0 0 10px;
    width: 30px;
    text-align: center;
    outline: 0;
    font-size: 13px;
    height: 27px;
    border: 1px solid #c7c7c7;
    border-radius: 3px;
}

.sectionFieldCover {
    position: relative;
}

.sectionBannerList .delBtns {
    right: 105px;
    top: 0;
}

.sectionFieldCover .slick-prev,
.sectionFieldCover .slick-prev:hover,
.sectionFieldCover .slick-prev:focus {
    background: url(../image/media/arrow-left.png);
    width: 30px;
    height: 30px;
    background-size: cover;
    left: 5px;
    z-index: 1;
}

.sectionFieldCover .slick-next,
.sectionFieldCover .slick-next:hover,
.sectionFieldCover .slick-next:focus {
    background: url(../image/media/arrow-right.png);
    width: 30px;
    height: 30px;
    background-size: cover;
    right: 5px;
    z-index: 1;
}

.sectionFieldCover .slick-prev:before,
.sectionFieldCover .slick-next:before {
    display: none;
}

.sectionBannerList {
    min-height: 350px;
    position: relative;
}

.thumbImage {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 20px;
}

.thumbImage img {
    width: 45px;
}


/***************** dash add section**************************/

.searchBoxDropdown {
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    background-color: #f3f3f3;
    border: 1px solid #ddd;
    border-radius: 7px;
    padding: 8px 10px;
    z-index: 1;
    display: none;
    text-align: left;
}

.noDoctorList {
    padding: 15px 0;
    font-size: 13px;
}

.searchBoxCount {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    margin: 0 0 10px;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 5px 0;
}

.searchDocImg img {
    width: 100%;
    position: initial;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.searchBoxItem {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    justify-content: space-evenly;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin: 0 0 5px;
    align-items: center;
    background: #ffffff94;
    padding: 10px;
    border-radius: 5px;
}

.searchDocImg {
    width: 11%;
    position: relative;
}

.searchDocService img {
    position: initial;
    width: auto;
    height: 18px;
}

.searchDocDeatils {
    width: 89%;
    padding: 0 0 0 20px;
    position: relative;
}

.searchDocService {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 13px;
}

.searchSplDeatils {
    width: 100%;
    padding: 5px 0;
}

.searchSplDeatils .searchDocService {
    top: 4px;
}

body .searchServiceIcon {
    margin: 0 0 0 13px;
    /* cursor: pointer; */
}

body .onlineDoc {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 10px;
    height: 10px;
    background: #059005;
    border-radius: 50%;
}

.searchDocImg a {
    display: block;
    text-decoration: none;
}

.searchDocDeatils a {
    color: #000;
    text-decoration: none;
    display: inline-block;
    width: 68%;
}

.searchDocName {
    font-size: 13px;
    color: #393939;
}

.searchDocDesignation {
    font-size: 13px;
    color: #059005;
}

.searchBoxItem:hover {
    background: #ffffff;
}

.searchBoxItem a {
    text-decoration: none;
}

.searchBoxList {
    max-height: 300px;
    overflow-y: auto;
}

.drName a {
    margin: 0 0 0 10px;
}

.timeListOpen .timeListMore {
    display: none;
}

.timeListOpen {
    overflow-y: auto;
    padding: 0 2px 0;
}

.timeListOpen::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
    width: 3px;
}

.video-chat-wrapper .ots-text-chat .ots-messages-holder::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
    width: 0px;
}

.video-chat-wrapper .timeListOpen::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
    background: #000;
}

.table-responsive::-webkit-scrollbar {
    height: 8px;
}

.sectionContentSub {
    width: 100%;
}

.notification-tag {
    background-color: green;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    color: #fff;
    text-align: center;
    line-height: 25px;
    font-size: 10px;
    font-weight: 600;
    display: inline-block;
    margin-left: 8px;
}

.notification-tag-red {
    background-color: #ff2525;
}

.drClearSearch {
    display: inline-block;
    background-color: #ffe4dd;
    padding: 10px 10px;
    border-radius: 20px;
    margin: 0 0 0 10px;
}

.drClearSearch span {
    display: inline-block;
    background-color: #f17979;
    border-radius: 50%;
    color: #fff;
    width: 20px;
    height: 20px;
    text-align: center;
    margin-left: 5px;
}

.drClearSearch span:hover {
    background-color: #ea5d5d;
    cursor: pointer;
}

.searchBoxDropdown .searchDocService img {
    cursor: default;
}

.priceCoverDiv {
    flex-wrap: wrap;
    text-align: left;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
}

body .serviceWrap .priceButton {
    cursor: pointer;
    margin: 2px 0;
    padding: 5px 20px;
}

.hospBoxCover {
    padding: 15px 5px 10px;
}

.hospName {
    font-weight: 600;
    margin: 0 0 8px;
    color: #4c4c4c;
}

.ots-messages-holder.ots-messages-holder-isExpired:after {
    content: "";
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    left: 0;
    right: 10px;
    top: 0;
    bottom: 0;
}

.doctor-pay-box {
    background-color: #f5f5f5;
    border-radius: 20px;
    padding: 20px;
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

body .expireDay {
    display: block;
    font-size: 10px;
    text-align: right;
    line-height: 15px;
    color: #f94c4c;
}

.notificationCallMain {
    width: 250px;
    position: fixed;
    right: 1px;
    top: 34px;
    text-align: center;
    z-index: 99;
}

.notificationCallMain img {
    width: 40px;
    cursor: pointer;
    margin: 0 14%;
}

.notificationCallMain .notDrName {
    display: block;
    font-weight: 600;
}

.notificationCallMain .blink-notfctn {
    animation: blink 1s step-start 0s infinite;
    -webkit-animation: blink 1s step-start 0s infinite;
}

@keyframes blink {
    50% {
        transform: scale(1.1);
    }
}

@-webkit-keyframes blink {
    50% {
        transform: scale(1);
    }
}

.video-appntmnt-list .react-tabs__tab-list {
    border: 0;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    overflow: auto;
    flex-wrap: wrap;
}

.video-appntmnt-list .react-tabs__tab {
    border: 3px solid transparent;
    background-color: #efecf3;
    border-radius: 10px;
    margin: 2px 5px;
    font-size: 14px;
    font-weight: 500;
    width: auto;
    white-space: nowrap;
}

.video-appntmnt-list .react-tabs__tab--selected {
    background-color: #fff;
    /* border: 0; */
    border-radius: 10px 10px 0 0;
    border-bottom: 3px solid #efecf3;
}

.service-menublock li {
    padding: 4px 4px;
    text-align: center;
}

.dropDownArrow:hover .mentalSubMenu {
    display: block;
}

.notificationCallMain .popup-content {
    width: 400px;
    padding: 50px 10px;
}

.notificationCallMain .popup-content .formButtonBg {
    padding: 3px 20px;
    font-size: 12px;
}

.healthDocMain {
    border-radius: 0;
    margin: 0;
    padding-top: 15px;
    border-left: 1px solid #dedede;
    padding-right: 0;
}

.healthDocMain .row-margin {
    margin-bottom: 15px;
    padding: 0;
}

.healthDocMain .react-tabs__tab {
    background-color: transparent;
    color: #7d7d7d;
    border: 0;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}

.healthDocMain .react-tabs__tab--selected {
    border-bottom: 1px solid var(--focus-input-color);
    background-color: transparent;
    color: var(--focus-input-color);
}

.healthDocMain .input-design,
.healthDocMain .react-datepicker__input-container input {
    padding: 0.15rem 1rem;
    font-size: 12px;
}

.healthDocMain .fileUpload-medical {
    border: 0;
    background-color: var(--common-button-bgcolor);
    color: var(--common-button-txtcolor);
    border: 1px solid var(--common-button-bgcolor);
    width: 100%;
    font-size: 12px;
    padding: 4px;
}

.healthDocImg {
    width: 30px;
    max-height: 30px;
    object-fit: contain;
}

.healthDocdelete {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    align-items: center;
    text-align: center;
}

.healthDocSub {
    position: relative;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    padding: 5px;
    padding-left: 20px;
    border-radius: 5px;
    margin-bottom: 5px;
    border: 1px solid #dedede;
    border-left: 3px solid var(--common-button-bgcolor);
    cursor: pointer;
}

.healthDocSubDet {
    padding-left: 5px;
    width: 88%;
    font-size: 10px;
}

.healthDocSubDel {
    position: absolute;
    right: 4px;
    top: 0;
}

.healthDocSubCheck {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.healthDocSubCheck .checkbox {
    height: 12px;
    width: 12px;
}

.healthDocSubName {
    width: 72%;
    display: inline-block;
    font-weight: 600;
}

.healthDocSubDate {
    width: 47px;
    display: inline-block;
    font-size: 8px;
    font-weight: bold;
}

.healthDocSubDesc {
    width: 100%;
}

.healthDocSubImg img {
    height: 100%;
    object-fit: contain;
}

.healthDocSub.healthDocSubActive {
    background-color: rgba(49, 160, 230, 0.3);
}

.healthDocListMain {
    height: 420px;
    overflow-y: auto;
}

.healthDocListSlider {
    padding: 0 30px;
    height: auto;
    overflow-y: hidden;
}

.healthDocListSlider .slick-next:before,
.healthDocListSlider .slick-prev:before {
    font-size: 0;
}

.healthDocListSlider .slick-next,
.healthDocListSlider .slick-next:hover,
.healthDocListSlider .slick-next:focus {
    content: "";
    color: #000;
    font-size: 0;
    background-image: url("../image/media/arrow-right.png");
    background-size: contain;
}

.healthDocListSlider .slick-prev,
.healthDocListSlider .slick-prev:hover,
.healthDocListSlider .slick-prev:focus {
    content: "";
    color: #000;
    font-size: 0;
    background-image: url("../image/media/arrow-left.png");
    background-size: contain;
}

.healthDocListSlider .healthDocSub {
    display: block;
    padding: 0;
    border-left: 0;
    height: 100%;
}

.healthDocListSlider .healthDocSubImg {
    height: 85vh;
}

.healthDocListSlider .healthDocSub img {
    width: 100%;
}

.prescribedBy span {
    padding-left: 5px;
    font-weight: 500;
}

.table-border-main {
    border: 1px solid #dee2e6;
}

body .presctooltip {
    border-color: #e6e6e6;
}

body .presctooltip::before {
    border-left: 8px solid #bebebe;
}

.presc-span {
    font-weight: 500;
}

.text-truncate-2line {
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text-truncate-no-mobile {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.presctooltip {
    opacity: 1 !important;
}

.terms-head {
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    text-transform: uppercase;
}

.terms-subhead {
    font-size: 26px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.terms-wrapper ol {
    font-weight: 600;
}

body .terms-wrapper p {
    font-size: 16px;
    line-height: 28px;
    font-weight: normal;
}

body .terms-wrapper .terms-p-bold {
    font-weight: 600;
}

body .terms-wrapper-popup p {
    font-size: 14px;
    line-height: 23px;
}

body .terms-wrapper-popup ul {
    padding-left: 20px;
}

.download-div {
    position: absolute;
    left: 0;
    top: 0;
    color: green;
    cursor: pointer;
}

.download-div:hover {
    color: var(--theme-color);
}

.healthDocSubpopup {
    position: absolute;
    background-color: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.backPopup {
    background-color: #fff;
}

.prescription-main {
    border: 1px solid #dee2e6;
    margin-bottom: 20px;
    border-right: 0;
}

.prescription-maindate {
    width: 100px;
    font-weight: bold;
    color: #000;
    position: relative;
    display: inline-block;
    vertical-align: top;
}

.prescription-date {
    font-size: 12px;
    vertical-align: top;
    padding: 5px;
}

.prescription-drName {
    width: 100%;
    background-color: #e0ebf5;
    padding: 5px;
    font-weight: 700;
    color: #000;
}

.prescription-Subdiv {
    width: 90.9%;
    border-left: 1px solid #dee2e6;
    display: inline-block;
}

.prescription-head,
.prescription-det {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
}

.prescription-subhead {
    font-weight: 600;
}

.prescription-subhead,
.prescription-subdet {
    width: 20%;
    border: 1px solid #dee2e6;
    border-left: 0;
    border-bottom: 0;
    color: #000;
    padding: 5px;
}


/* .prescrptn-add-section-right {
} */

.prescrptn-left-main,
.prescrptn-right-main {
    font-size: 14px;
}

.presc-add-main .input-design,
.presc-add-main .react-datepicker__input-container input {
    font-size: 12px;
}

.prescrptn-left-main {
    width: 65%;
    float: left;
    padding-right: 15px;
    height: 500px;
    overflow-y: auto;
}

.prescrptn-right-main {
    width: 400px;
    float: right;
    padding-left: 15px;
}

.prescrptn-add-section-right {
    border-left: 1px solid #dcdcdc;
    padding: 10px;
    /* background-color: whitesmoke; */
}

.prescrptn-right-tab {
    margin-top: 10px;
}

.prescrptn-right-tab .react-tabs__tab--selected {
    border-color: transparent;
    font-weight: 400;
    /* box-shadow: 0px -4px 5px 0px hsl(208, 99%, 50%); */
    outline: none;
    border: 0;
    border-bottom: 1px solid #3ac451;
    padding-bottom: 2px;
    font-weight: 600;
}

.prescrptn-right-tab .react-tabs__tab-list {
    border-bottom: 0;
    margin-bottom: 0;
}

.input-design-freq {
    width: 42px;
    margin-right: 2px;
    padding: 0.25rem;
    text-align: center;
}

.presc-drug-main {
    height: 300px;
    overflow-y: auto;
    padding-top: 10px;
}

.presc-drug-sub {
    padding: 5px;
    border-bottom: 1px solid #dcdcdc;
    margin-right: 5px;
    cursor: pointer;
}

.presc-drug-sub:hover {
    background-color: #dcdcdc;
}

.prescrptn-right-tab .react-tabs__tab--selected,
.prescrptn-right-tab .react-tabs__tab {
    color: #3ac451;
}

.prescrptn-right-tab .react-tabs__tab-list {
    border-bottom: 0;
}

.presc-add-main .input-numbr-text {
    width: 65px;
    text-align: center;
}

.presc-add-main select.input-design {
    padding: 0.25rem;
}

.presc-add-main .input-week-text {
    width: 90px;
    margin-left: 10px;
}

.presc-add-main .label-text {
    font-size: 13px;
}

.searchBoxDropdownMedicine {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background-color: #f3f3f3;
    border: 1px solid #ddd;
    border-radius: 7px;
    padding: 8px 10px;
    z-index: 1;
    display: none;
    text-align: left;
    height: 100px;
    overflow-y: auto;
}

.searchBoxMedicineList {
    padding: 2px 0;
    font-size: 12px;
}

body .popup-content-lg {
    width: 950px;
}

.popup-med-template-list {
    border: 1px solid #dee2e6;
    padding: 10px 0;
    margin: 15px 0;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    flex-wrap: wrap;
    position: relative;
    cursor: pointer;
}

.delete-medicine {
    position: absolute;
    font-size: 25px;
    right: -10px;
    top: -10px;
    background-color: #ff5b5b;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    line-height: 1;
}

.prescrptn-right-tab .react-tabs__tab-panel--selected {
    background-color: #fff;
}

.appntmnt-list-tabmain {
    margin-bottom: 20px;
    text-align: right;
    border-bottom: 1px solid #f3e6ff;
    padding-bottom: 15px;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    justify-content: flex-end;
}

.appntmnt-list-tabmain-mob {
    display: none;
}

.appntmnt-list-tab {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    padding: 2px 10px;
    color: #000;
    /* background-color: #f3e6ff; */
    border-radius: 20px;
    margin-left: 5px;
    vertical-align: top;
    cursor: pointer;
}

.appntmnt-list-tab.current-appntmnt {
    color: #28a745;
    border: 1px solid #28a745;
}

.appntmnt-list-tab.today-appntmnt {
    color: #8e6a00;
    border: 1px solid #8e6a00;
}

.appntmnt-list-tab.mis-appntmnt {
    color: #dc3545;
    border: 1px solid #dc3545;
}

.appntmnt-list-tab.all-appntmnt {
    color: #17a2b8;
    border: 1px solid #17a2b8;
}

.appntmnt-list-tab.complete-appntmnt {
    color: #006ee2;
    border: 1px solid #006ee2;
}

.appntmnt-list-tab:hover {
    text-decoration: none;
}

.appntmnt-list-tab-active {
    font-weight: 600;
}

.appntmnt-list-tab-active.current-appntmnt {
    background-color: #d7ffe0;
}

.appntmnt-list-tab-active.today-appntmnt {
    background-color: #fff6da;
}

.appntmnt-list-tab-active.mis-appntmnt {
    background-color: #ffdee1;
}

.appntmnt-list-tab-active.all-appntmnt {
    background-color: #e5fbff;
}

.appntmnt-list-tab-active.complete-appntmnt {
    background-color: #cbe4ff;
}

.recordContainer {
    float: right;
    margin-top: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 2px 2px 5px #797979;
    padding: 5px 10px;
    border-radius: 20px;
    width: 150px;
    text-align: center;
}

.recordContainer span img {
    cursor: pointer;
}

.screenRecordingMain {
    color: #177301;
    text-align: center;
}

.screenRecordingText {
    margin-top: 7px;
    padding-left: 6px;
    font-size: 12px;
}


/*** pharmacy App Screens ***/

.pharmacyapp-wrapper {
    width: 100%;
    /* height: 92.8vh; */
    font-family: "Poppins Light";
    /* overflow: hidden; */
    position: fixed;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.slick-slider {
    width: 100%;
}

.pharmacyapp-wrapper,
.pharmacyapp-wrapper p {
    color: #3f3d56;
    font-size: 12px;
}

.splash-banner {
    padding: 30px 20px;
    height: 100vh;
}

.splash-banner1 {
    background-color: #fbac0e;
}

.splash-banner2 {
    background-color: #fe2d5e;
}

.splash-banner3 {
    background-color: #39007d;
}

.splash-banner4 {
    background-color: #46bbff;
}

.sp-banner-img {
    width: 100%
}

.sp-banner-content {
    width: 320px;
    margin: 50px auto 0;
}

.pharmacy-splash-btnmain {
    text-align: center;
    margin-top: 20px;
}

.pharmacy-splash-button {
    background-color: #2acb94;
    border-radius: 5px;
    color: #fff;
    border: 0;
    padding: 15px 15px;
    font-size: 12px;
    letter-spacing: 2px;
}

.pharmacy-splash-button-b {
    background-color: #3f3d56;
}

.pharmacy-splash-button:hover {
    color: #fff;
    text-decoration: none;
}

.pharmacy-splash-button-r {
    background-color: #f05050;
}

.pharmacyapp-wrapper .sp-banner-content-p {
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 1.2px;
}

.pharmacyapp-wrapper .slick-dots {
    bottom: 40px;
}

.pharmacyapp-wrapper .slick-dots li button:before {
    font-size: 10px;
    color: white;
    opacity: 1;
}

.pharmacyapp-wrapper .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #a5a4a4;
}

.pharmacyapp-splashhead {
    color: #3f3d56;
    font-size: 16px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 20px;
    font-family: "Poppins";
}

.invite-network-wrapper {
    background-color: #e6e6e6;
    padding-top: 10px;
    position: relative;
    height: 100vh;
}

.invite-network-banner {
    padding: 0 20px;
    margin-top: 20px;
    height: 43vh;
}

.pharmacyapp-head {
    color: #3f3d56;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
}

.invite-network-img {
    width: 100%;
    object-fit: contain;
    height: 100%;
}

.invite-network-dr-main {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    margin: 7vh 20px;
    height: auto;
}

.invite-network-dr-image {
    display: inline-block;
}

.invite-network-dr-name {
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
    display: inline-block;
    vertical-align: middle;
    color: #3f3d56;
    margin-left: 5px;
}

.invite-network-dr-content,
.invite-network-dr-content p {
    font-size: 12px;
}

.invite-network-dr-edit {
    position: absolute;
    right: 10px;
    top: 10px;
}

.invite-network-btn-main {
    background-color: #fff;
    border-radius: 30px 30px 0 0;
    padding: 20px 20px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.pharmacy-input {
    resize: none;
    width: 99%;
    height: 60px;
    display: none;
    border-color: #c3c3c3;
}

.pharmacy-btn {
    background-color: #2acb94;
    color: #fff;
    display: none;
    border: 0;
    margin-top: 5px;
}

.pharmacy-editActive .pharmacy-input,
.pharmacy-editActive .pharmacy-btn {
    display: block;
}

.pharmacy-editActive .pharmacy-content-p {
    display: none;
}

.invite-network-button {
    background-color: #2acb94;
    border-radius: 15px;
    color: #fff;
    border: 0;
    padding: 8px 20px;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 2px;
}

.invite-network-button img {
    float: right;
    margin-top: 5px;
}

.invitation-img {
    width: 100%;
    object-fit: contain;
    padding: 25px;
}

.pharmacy-invitation-wrapper {
    padding: 0 20px;
}

.pharmacy-invitation-banner {
    margin-top: 20px;
}

.pharmacy-invitation-main {
    padding: 0 20px;
}

.pharmacyapp-store {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
}

.pharmacyapp-store-list {
    width: 150px;
    padding: 5px 20px 0 0;
}

.promomessage {
    color: green;
    font-size: 12px;
}

.drRegistrationForm {
    border: 1px solid gainsboro;
    padding: 20px 8%;
    border-radius: 20px;
    font-size: 13x;
}

.drRegistrationForm .form-section {
    font-size: 13px;
}

.drRegistrationForm .label-text {
    text-align: left;
    color: #606060;
    position: relative;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 13px;
}

.mainBx {
    width: 100%;
    padding: 20px;
    max-height: 300px;
    overflow-y: auto;
}

.spanLeft {
    float: left;
    margin-right: 5px;
}

.spanRight {
    float: right;
    margin-right: 5px;
}

.mainBx .tdBorder {
    padding: 0.75rem;
}

.tdtxt {
    margin-left: 5px;
}

.subBx {
    width: 50%;
    display: inline-block;
}

.subBx,
.subBx:nth-child(even) {
    background-color: #fff;
    padding: 0.35rem 5% 0.35rem 0.35rem;
}

.subBx:nth-child(even) {
    background-color: #fff;
    padding-right: 0.35rem;
    padding-left: 5%;
}

.pos-rel {
    position: relative;
}

.reg-actn {
    position: absolute;
    right: 5%;
    font-size: 20px;
}

.reg-plus .fa {
    color: green;
}

.reg-close {
    color: red;
}

.drRegistrationForm .form-section-bgcolor {
    border-radius: 20px;
    padding: 15px 0 0;
    width: 94%;
}

.drRegistrationForm select.input-design,
.drRegistrationForm .input-design {
    padding: 0.35rem;
}

.drRegistrationForm input[type="radio"]:focus {
    outline: auto;
}

.blog-listbox {
    box-shadow: 2px 2px 10px #e2e2e2;
    width: 30.3%;
    margin: 1.5%;
    border-radius: 20px;
    overflow: hidden;
    padding: 0 0 20px;
}

.blog-listImgDiv {
    text-align: center;
    background-color: #f2f2f2;
}

.blog-listImg {
    object-fit: cover;
    max-height: 150px;
    width: 100%;
    object-position: top;
    min-height: 150px;
}

.blog-list-detils {
    padding: 0 10px;
}

.bloguserList-text {
    color: #000;
    padding: 5px 0 0;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin: 10px 0;
    height: 70px;
}

.blogtag-sub {
    display: inline-block;
    color: #a7a7a7;
    margin-top: 5px;
    background-color: #eaeaea;
    margin-right: 2px;
    border-radius: 10px;
    padding: 2px 9px;
    font-size: 12px;
}

.readmoreBlog,
.readmoreBlog:hover {
    padding: 5px 15px;
    border-radius: 15px;
    background-color: var(--common-color);
    font-size: 12px;
    color: #fff;
    border: 0;
    margin-right: 5px;
    text-decoration: none;
}

.blogtag-main {
    margin-bottom: 15px;
}

.blogtag-name {
    margin-top: 10px;
    color: var(--common-color);
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 2px 9px;
    width: auto;
    display: inline-block;
    font-size: 12px;
}

.blogtag-name-head {
    margin-top: 10px;
    color: var(--common-color);
    padding: 2px 9px;
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
}

.bloguserList-textPadd {
    padding: 5px 9px;
}

.sh-blogtag-name.blogtag-name {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.blog-category-menu {
    display: none;
}

.category-sub {
    margin: 5px 0;
    padding-left: 15px;
    width: 90%;
    vertical-align: top;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    background-color: #ebeef1;
    padding: 5px 15px;
    border-radius: 0 10px 10px 0;
    border: 1px solid #d6d6d6;
}

.category-sub:before {
    content: "";
    font-size: 10px;
    position: absolute;
    left: 0;
    top: 0;
    /* background-image: url("../image/icons/blogarrow.png");
  background-repeat: no-repeat;
  background-size: contain; */
    /* width: 7px; */
    /* height: 7px;*/
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 8px solid #d6d6d6;
    border-bottom: 15px solid transparent;
}

.category-sub:hover,
.category-sub-active {
    color: var(--common-color);
    border: 1px solid #dde2e6;
}

.category-sub:hover:before,
.category-sub-active:before {
    border-left: 8px solid var(--common-color);
}

.category-list:hover a {
    text-decoration: none;
}

.redactor-wrapper {
    border: 1px solid #dcdcdc;
    min-height: 300px;
}

.redactor-wrapper .rdw-dropdown-selectedtext,
.redactor-wrapper .rdw-dropdown-selectedtext:hover {
    color: #000;
    text-decoration: none;
}

.hosptalList {
    list-style: none;
    border: 1px solid #ededed;
    background-color: #fff;
    width: 100%;
    /* border-radius: 14px; */
    padding-left: 7px;
    padding: 8px 10px;
}

.hospitallistUl {
    padding: 0;
}

.inputdesignSpace {
    width: 64%;
}


/* Hide scrollbar for Chrome, Safari and Opera */

.video-appntmnt-list .react-tabs__tab-list::-webkit-scrollbar,
.drDetailServicemainTab .react-tabs__tab-list::-webkit-scrollbar {
    display: none;
}


/* Hide scrollbar for IE, Edge and Firefox */

.video-appntmnt-list .react-tabs__tab-list,
.drDetailServicemainTab .react-tabs__tab-list {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.LocalsearchImgdiv {
    position: relative;
    width: auto;
    display: inline-block;
}


/* .LocalsearchImgdiv::before{
  position: absolute;
  content: "";
  top: -10px;
  left: 0;
  right: 0px;
  width: 100%;
  height: 5px;
  background-color: var(--common-color);

} */

.bloguser-text {
    padding: 5px 0;
    font-size: 28px;
    width: 100%;
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: 0;
}

.bloguser-det {
    font-size: 14px;
}

.blog-detls {
    color: #767676;
    margin-top: 5px;
    font-size: 13px;
    padding-right: 18px;
    display: inline-block;
}

.blog-detls::after {
    content: "/";
    margin-left: 15px;
}

.blog-detls:nth-last-child(1) {
    color: #000;
}

.blog-detls:nth-last-child(1):after {
    content: "";
}

.blog-detls:nth-last-child(1):hover {
    text-decoration: underline;
}

.Blogsubtext,
.Blogsubtext p {
    font-size: 14px;
    line-height: 25px;
    font-weight: 300;
    padding: 9px 0;
    margin: 0;
    color: #000;
}

.Blogsubtext ul li,
.Blogsubtext ol li {
    font-size: 14px !important;
    line-height: 25px;
    margin: 0 0 5px;
    color: #000;
    font-weight: 300;
}

.Blogsubtext p span {
    font-size: 14px !important;
    font-weight: 300;
}

.Blogsubtext span {
    font-family: inherit !important;
    font-weight: 300;
}

.Blogsubtext b,
.Blogsubtext strong {
    font-weight: bold;
}

.Blogsubtext h1,
.Blogsubtext h2,
.Blogsubtext h3,
.Blogsubtext h4,
.Blogsubtext h5,
.Blogsubtext h6 {
    color: #000;
}

.Blogsubtext h1,
.Blogsubtext h1 span {
    font-size: 1.8rem;
    font-weight: 500;
    text-transform: unset;
    margin-bottom: 0.5rem;
}

.Blogsubtext h2,
.Blogsubtext h2 span {
    font-size: 1.6rem;
    line-height: 30px;
}

.Blogsubtext h3,
.Blogsubtext h3 span {
    font-size: 1.4rem;
    line-height: 30px;
}

.Blogsubtext h4,
.Blogsubtext h4 span {
    font-size: 1.2rem;
    line-height: 25px;
}

.Blogsubtext h5,
.Blogsubtext h5 span {
    font-size: 1.1rem;
    line-height: 25px;
}

.Blogsubtext img {
    max-height: 400px;
    float: left;
}

#blog-detailsId .Blogsubtext img {
    max-height: 400px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    float: none;
}

.redactor-wrapper .rdw-image-center {
    justify-content: left;
}

.redactor-wrapper img {
    max-height: 300px;
}

.redactor-wrapper .rdw-link-modal-label {
    font-size: 13px;
    margin: 0;
}

.redactor-wrapper .rdw-link-modal-target-option {
    font-size: 13px;
}

.redactor-wrapper .rdw-link-modal-input {
    margin-top: 2px;
    margin-bottom: 10px;
}

.redactor-wrapper .rdw-image-modal {
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

.BlogsubHead {
    font-size: 23px;
    /* font-weight: 900; */
    color: #606369;
}

.BlogsubtextLi {
    font-size: 14px;
    line-height: 25px;
}

.BlogsubtextUl {
    padding-left: 15px;
}

.fileUpload-img {
    margin-top: -10px;
}

.promoCodeBox {
    background-color: whitesmoke;
    /* width: 410px; */
    padding: 15px 2px 10px;
    border: 1px dotted #e6e6e6;
    border-radius: 5px;
}

.btn-default {
    font-weight: 400;
}

.form-head,
.healthpageDocName {
    font-weight: 600;
}

.breadcrumb {
    padding: 10px 25px;
}

.textchat-namehead {
    font-weight: 500;
    font-size: 14px;
}

.chat-outer .chat-span {
    font-size: 12px;
}

.font-bold,
.table thead th {
    font-weight: 500;
}

.video-shed .dashboard-right .table {
    font-size: 12px;
}

.doctorDetailProfile .drName {
    font-size: 1rem;
}

.main-wrapper-smwidth1,
.main-wrapper-smwidth,
.main-wrapper,
.main-wrapper1,
.drListMain,
.blog-wrapper {
    min-height: 66vh;
}

.drListMain {
    overflow: hidden;
}

.mentalSubMenu {
    z-index: 999;
}

.video-record-main {}

.video-record-list {
    width: 23%;
    height: auto;
    padding: 10px;
    border: 1px solid #eaeaea;
    margin: 1%;
    border-radius: 20px;
    text-align: center;
    vertical-align: top;
    position: relative;
    display: inline-block;
}

.video-record-list video {
    width: 100%;
}

.textleftAlign {
    text-align: left !important;
    width: 100%;
}

.marginAutoLabel {
    margin: auto;
}

.doctrlabelTop {
    top: 6px;
}

.doctrlabelTopZero:after {
    top: 0;
}

.label-text-TableVideo {
    position: relative;
    text-align: left;
    font-weight: 500;
    padding-bottom: 5px;
}

.AppointmentTimeTable {
    border: 1px solid #cac2c24d;
    text-align: center;
}

.AppointmentTimeTable thead th {
    border-top: 1px solid #cac2c24d;
    border-bottom: 1px solid #cac2c24d;
    border-left: 1px solid #cac2c24d;
    border-right: 1px solid #cac2c24d;
}

.AppointmentTimeTable td {
    border-left: 1px solid #cac2c24d;
    border-right: 1px solid #cac2c24d;
    border-top: none;
    color: #989898;
}

.AppointmentTimeTable tr {
    background-color: transparent !important;
}

.bg-border {
    border: 1px solid #dcdcdc;
    border-radius: 20px;
    width: 94%;
}

.labelcolumnaftr::after {
    position: absolute;
    content: ":";
    right: 0;
}

.prfsnlStatemntWidth {
    width: 89%;
}

.AppintmntTablewidth {
    width: 96%;
}

.labelcolorRed {
    color: #ff4040 !important;
    font-size: 14px !important;
    width: 100% !important;
}

.labelwidthMob {
    width: 45%
}

.formsectnWidth {
    width: 112%;
}


/* .labelWidthH{
  width:92%
} */

.pluiconbutnborder {
    border: none;
    background-color: transparent;
}

.pluiconbutnborder:focus {
    outline: auto;
}

.fileUpload-medical button {
    color: #3ac451;
    border: 1px solid transparent;
    background-color: transparent;
}

.loader_imgDiv-pos {
    position: relative;
}

.loader_imgDiv {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    align-items: center;
}

.loader_imgDiv img {
    margin: 0 auto;
}

.formsectnWidthAdrs {
    width: 100%;
}

.selecttitlewidth {
    width: 34%;
}

.custom-search-subWidth {
    max-width: 14% !important;
}

.formButtonsearch-width {
    min-width: 100px !important;
}


/* Health Trackers css begins here */

.health-track-card {
    box-shadow: -4px 3px 5px 0px #a59f9f;
    border-radius: 20px;
    padding: 40px;
    text-align: center;
    border: 1px solid #a59f9f4a;
    width: 80%;
    margin-top: 5px;
}

.health-track-card p {
    font-size: 14px;
    margin: 0;
    padding-top: 40px;
}

.health-trck-img {
    width: 50%;
    margin: 0 auto;
}

.health-trck-img img {
    width: 100%;
}

.d-flex-end {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    justify-content: flex-end;
    align-items: center;
}

.health-track-card-right .input-design,
.health-track-card-right .react-datepicker__input-container input {
    border-radius: 6px;
}

.health-track-card-right .ht-button {
    background: transparent;
    border: none;
}

.health-track-card-right .d-center {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    align-items: center;
}

.health-track-card-right .d-center label,
.health-track-card-right .d-end-center label {
    margin: 0;
}

.health-track-card-right .d-end-center {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    justify-content: flex-end;
    align-items: center;
}

.health-track-card-right table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 96%;
    table-layout: fixed;
}

.health-track-card-right table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
}

.health-track-card-right table th,
.health-track-card-right table td {
    padding: 2px 5px;
    text-align: left;
    font-size: 13px;
}

.health-track-card-right table th {
    font-size: 15px;
    font-weight: normal;
    padding: 8px 7px;
}

.health-track-card-right .tbody {
    border-radius: 10px;
    border: 1px solid #ccc;
    /* width: 535px; */
    width: 635px;
    float: left;
    height: 200px;
    overflow-y: scroll;
}

.health-track-card-right .tbody.babymilestne {
    width: 540px;
}

.ht-terms img {
    width: 100%;
}


/* .health-track-card-right table th:first-child,
.health-track-card-right table td:first-child {
    width: 150px;
} */

.health-track-card-right table th,
.health-track-card-right table td {
    width: 110px;
}

.health-track-card-right thead,
.health-track-card-right tr:nth-child(even) {
    background-color: transparent;
}

.ht-dwnldbutton {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.ht-dwnldbutton input {
    color: #4d4d4d;
    margin-right: 0;
    border-radius: 6px;
}

@media screen and (max-width: 991px) {
    .health-track-card-right table {
        border: 0;
    }
    .health-track-card-right table caption {
        font-size: 1.3em;
    }
    .ph-pdf-dld.health-track-card-right table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .health-track-card-right table tr {
        border-bottom: 1px solid #ccc;
        display: block;
        margin-bottom: .625em;
    }
    .ph-pdf-dld.health-track-card-right table td {
        display: block;
        font-size: 14px;
        text-align: right;
        padding: 2px 15px;
    }
    .ph-pdf-dld.health-track-card-right table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
        text-align: left;
    }
    .health-track-card-right table td:last-child {
        border-bottom: 1px solid #000;
    }
    .health-track-card-right.hlth-immunetb.hlth-trck-pdf table th,
    .health-track-card-right.hlth-immunetb.hlth-trck-pdf table td {
        width: 100%;
    }
}

.ht-terms p {
    margin: 0px;
    font-size: 15px;
}

.ht-dwnldbutton.ht-download {
    display: block;
    margin-bottom: 30px;
}

.health-track-dwd ol {
    padding-left: 25px;
}

.ht-terms {
    padding: 10px 0;
}

.health-track-card-right.hlth-trck-pdf {
    padding: 0 30px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.health-track-card-right.hlth-trck-pdf .tbody {
    border-radius: 0;
    border: none;
    /* width: 648px; */
    width: 730px;
    float: left;
    height: auto;
    overflow-y: hidden;
}

.health-track-card-right.hlth-trck-pdf.hlth-baby .tbody {
    width: 516px;
    border-radius: 0;
    border: none;
    float: left;
    height: auto;
    overflow-y: hidden;
}

.health-track-card-right.hlth-trck-pdf table th,
.health-track-card-right.hlth-trck-pdf table td {
    padding: 8px 0px;
}

.health-track-card-right.hlth-trck-pdf h1 {
    font-size: 18px;
    margin-bottom: 10px;
}

.health-track-card-right.hlth-trck-pdf h2 {
    font-size: 16px;
}

.health-track-card-right table th,
.health-track-card-right table td {
    width: 52%;
}

.health-track-card-right.hlth-immunetb table th,
.health-track-card-right.hlth-immunetb table td {
    width: 120px;
}

.health-track-card-right.hlth-immunetb.hlth-trck-pdf table th,
.health-track-card-right.hlth-immunetb.hlth-trck-pdf table td {
    /* width: 105px; */
    /* width: 122px; */
    width: 145px;
}

.health-track-card-right.hlth-immunetb.hlth-trck-pdf table th:nth-child(3),
.health-track-card-right.hlth-immunetb.hlth-trck-pdf table td:nth-child(3) {
    /* width: 215px; */
    width: 260px;
}

.health-track-card-right.hlth-immunetb table th:nth-child(3),
.health-track-card-right.hlth-immunetb table td:nth-child(3) {
    width: 242px;
}

.health-track-card-right.hlth-immunetb.hlth-trck-pdf table th:nth-child(4),
.health-track-card-right.hlth-immunetb.hlth-trck-pdf table td:nth-child(4),
.health-track-card-right.hlth-immunetb.hlth-trck-pdf table th:nth-child(5),
.health-track-card-right.hlth-immunetb.hlth-trck-pdf table td:nth-child(5) {
    width: 45px;
}

.health-track-card-right.hlth-immunetb table th:nth-child(4),
.health-track-card-right.hlth-immunetb table td:nth-child(4),
.health-track-card-right.hlth-immunetb table th:nth-child(5),
.health-track-card-right.hlth-immunetb table td:nth-child(5) {
    width: 67px;
}

.health-track-dwd p {
    padding-left: 10px;
}

.health-track-dwd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    width: 100%;
}


/* samad ivf health records css begins */

.health-records-files {
    padding: 15px;
}

.health-records-files h3 {
    color: #393939;
    float: left;
    margin: 0px;
    font-size: 14px;
}

.health-records-steps {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    justify-content: left;
    align-items: center;
}

.health-records-opt .formButton {
    margin: 10px 0px;
}

.health-records-opt .fileUpload-medical,
.fileUpload-medical:hover {
    min-width: 140px;
}

.sh-blog-infos .sh-blog-title p,
.sh-blog-infos .sh-blog-title p a {
    font-size: 13px;
    color: #464646;
    margin: 0px;
    text-align: left;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-decoration: none;
}

.sh-blog-title {
    padding: 10px 10px 0;
    width: 100%;
    float: left;
}

.sh-blog-title .sh-user-pic {
    width: 35px;
    float: left;
    padding-right: 10px;
}

.sh-blog-title .sh-user-pic img {
    width: 100%;
}

.blogtag-main.sh-blog-infos {
    margin-bottom: 8px;
}

.sh-d-center {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
}

.sh-d-left {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    justify-content: left;
    align-items: center;
    text-align: right;
}

.sh-blog-infos .sh-blog-title {
    cursor: pointer;
}

.sh-blog-head {
    padding: 0 10px;
}

.sh-blog-head .bloguserList-text {
    font-size: 16px;
    font-weight: 400;
}

.sh-blog-head .bloguserList-text-dtls {
    font-size: 30px;
    font-weight: 500;
    line-height: 33px;
    color: #000;
    padding-top: 15px;
}

.sh-blog-infos .sh-blog-title.sh-dwnld p {
    -webkit-box-orient: unset;
    text-align: left;
}


/* POp up */

.sh-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / 18%);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sh-overlay:target {
    visibility: visible;
    opacity: 1;
}

.sh-popup {
    margin: 70px auto 0;
    padding: 20px;
    background: #fff;
    border-radius: 0;
    width: 100%;
    position: absolute;
    transition: all 2s ease-in-out;
    bottom: 0;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}

.sh-popup .close {
    position: absolute;
    top: 30px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

.sh-popup .sh-content {
    max-height: 30%;
    overflow: auto;
}

.sh-overlay .bloguserList-text {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 25px;
    padding-left: 10px;
    line-height: 25px;
}

.sh-blog-title.sh-dwnld .react-datepicker-wrapper {
    width: 20%;
    float: left;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    justify-content: left;
    align-items: center;
    padding-right: 10px;
    margin-bottom: 13px;
}

.sh-blog-savebtn {
    width: 10%;
    float: left;
}

.sh-blog-savebtn .addNew-btn {
    margin: 0px;
}

.sh-blog-title.sh-dwnld .react-datepicker-wrapper input {
    padding: 5px 10px;
}

.blog-listbox.sh-blog-listbox-dtls {
    width: 100%;
}

.bloguserList-text.sh-bloguserList-text {
    -webkit-line-clamp: unset;
}

.sh-blog-infos .sh-blog-title.sh-username {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.sh-dwnld {
    padding: 15px 0 0;
}

.sh-dwnld .sh-datepckr {
    background: transparent!important;
    color: var(--common-button-bgcolor);
    border: 1px solid var(--common-button-bgcolor);
    border-radius: 20px;
}

.sh-dwnld .sh-datepckr .input-design,
.sh-dwnld .sh-datepckr .react-datepicker__input-container input {
    border: 1px solid var(--common-button-bgcolor);
    color: var(--common-button-bgcolor);
    padding: 5px 25px;
}

.sh-dwnld .sh-datepckr .input-design::placeholder,
.sh-dwnld .sh-datepckr .react-datepicker__input-container input::placeholder {
    color: var(--common-button-bgcolor);
}

.sh-newsfeedsbtn {
    width: 100%;
    height: auto;
    float: left;
}

.sh-newsfeedsbtn .addNew-btn {
    float: none;
}

.sh-pop-content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 20px;
}

.sh-links {
    width: 100%;
    float: left;
    height: auto;
    margin-bottom: 15px;
}

.sh-links .pendingLink {
    text-align: left;
    float: left;
    padding-right: 20px;
    color: var(--common-button-bgcolor);
    padding: 5px 25px;
    border-radius: 20px;
    text-transform: capitalize;
    border: 1px solid var(--common-button-bgcolor);
    background-color: #fff;
    text-decoration: none;
    font-size: 14px;
    margin-right: 15px;
}

.sh-links .pendingLink a {
    color: var(--common-button-bgcolor);
}

body .newsfeed-popup,
body .popup-attach {
    padding: 10px;
    border-radius: 10px;
    position: relative;
    background: rgb(255, 255, 255);
    width: 50%;
    margin: auto;
    border: 1px solid rgb(187, 187, 187);
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.time-date-popup .newsfeed-popup {
    padding: 40px 50px;
    width: 500px;
}

.newsfeed-popup .modal-popup>.content {
    width: 100%;
    padding: 10px 20px;
    color: #0f0f10;
    max-height: 85vh;
    overflow: inherit;
}

.sh-file-thumbnail .fileRecordThumb {
    width: 50px;
    height: 35px;
    object-fit: contain;
    object-position: center;
    overflow: hidden;
}


/*  */

.sh-ida .header {
    font-size: 20px;
    font-weight: 600;
    display: block;
    margin: 0 0 20px;
    color: #000;
    padding-left: 15px;
}

.sh-inner-cntnt {
    padding: 50px 150px;
}

.sh-textarea {
    padding-right: 0px;
}

.sh-blocks {
    width: 100%;
    float: left;
    height: auto;
    padding-left: 15px;
}

.sh-chck {
    padding-left: 15px;
}

.sh-addimg {
    padding: 15px 0 15px 30px;
}

.sh-img-align {
    display: flex;
    justify-content: left;
    align-items: center;
}

.sh-sve {
    margin-left: 5px;
}

.sh-img-tb {
    width: 40px;
    height: 40px;
    object-fit: contain;
    object-position: center;
}

.sh-table-sec {
    padding: 20px 0 40px;
}

.ph-maintitle {
    margin: 30px 0;
    font-size: 30px;
}

.sh-lbl {
    padding: 0px;
}

#selectEdit,
#bannerEdit {
    color: red;
    font-size: 14px;
    margin: 0px;
}

.content.sh-ida {
    margin-bottom: 40px;
}

.sh-blocks .w-75 {
    width: 90% !important;
}

.ph-cancel {
    color: var(--cancel-button)!important;
    border: 1px solid var(--cancel-button)!important;
}

.sh-appnd input {
    margin-bottom: 0px;
}

.slick-disabled {
    opacity: 0.5 !important;
    pointer-events: none;
}

.health-track-card-right.hlth-trck-pdf table th:first-child {
    width: 38%;
}

.formButton.formButtonBold.ht-dwbttn {
    margin: 0;
    padding: 6px 10px;
    min-width: 100px;
    margin-top: 5px;
}

.ht-pdfcenter {
    justify-content: center;
}

.health-track-card.ht-biimgs {
    width: 100%;
}

.ht-biimgs-center {
    justify-content: space-around;
}

.ph-selectDisable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.ph-calendar .react-datepicker__input-container input {
    margin-bottom: 0px!important;
}

.ht-button img {
    width: 35px;
}


/* .ph-hlthtracker-pdf {
    width: 730px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.ph-hlthtracker-pdf .ht-terms {
    width: 585px;
} */

.ph-hlthtracker-pdf th,
.ph-hlthtracker-pdf td {
    padding: 5px;
}

.edu-icon-size .fa {
    font-size: 24px;
}

.dctr-inpersn-redtext {
    font-size: 14px;
    color: red;
    text-align: center;
}

.formsectnWidth-clinic {
    width: 100% !important;
}

.input-design-widthclinic {
    width: 70% !important;
    margin-right: 5px;
}

.hospitallistUlclinic-width {
    width: 70%;
}

a.fileUpload-medical:hover {
    text-decoration: none;
}

.blog-fleupload-top {
    margin-top: 27px;
}

.attachment-cancelImg {
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 4px;
    top: -12px;
}

.attachment-downloadImg {
    padding: 1px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 0px;
    bottom: 0;
}

.attchment-adddiv {
    display: inline-block;
    width: 14%;
    margin: 4px;
    position: relative;
}


/*  */

.formButtonBold.ht-dwbttn.htnewdwnlod {
    color: var(--theme-text-color);
    padding: 5px 25px;
    border-radius: 6px;
    text-transform: capitalize;
    border: 1px solid var(--common-button-bgcolor);
    margin: 10px 10px;
    background-color: #fff;
    text-decoration: none;
    font-size: 14px;
}

.featurecenter-bannerbox {
    text-align: center;
}

.featuredListborder-baner {
    border: 3px solid #fecc00;
}

.ph-ht-head h4.mainHead {
    margin-bottom: 25px;
}

.purpleWrap.ph-mainWrap .main-wrapper1 {
    margin-top: 0px;
    padding-top: 0px;
}


/* New Health Tracker pdf css */

.ph_tbl_head1 {
    background-color: #31688c;
}

.ph_tbl_head2 {
    background-color: #7c1d80;
}

.ph_tbl_head3 {
    background-color: #c61513;
}

.ph_tbl_head {
    border-radius: 0 15px 15px 0;
    color: #fff;
    padding: 5px 30px 5px 15px!important;
}

.ph_tbl_head:first-child {
    padding: 5px 55px 5px 15px;
}

.ph_tbl_head4 {
    border-radius: 0 15px 0 15px;
    padding: 5px 30px;
    background: #f2f5fa;
    box-shadow: 3px 3px 3px 0px #e3e4e5;
}

.ph_tblWrap {
    width: 100%;
    border-collapse: separate;
    border-radius: 20px;
    table-layout: fixed;
    border-spacing: 0px 20px;
    font-size: 14px;
}

.ph_tblWrap tr td:first-child {
    background: #f2f5fa;
    border-radius: 0 0 0 15px;
}

.ph_htPdf table tr {
    border-radius: 20px;
}

.ph_htPdf table td {
    padding: 5px;
    background: #f2f5fa;
    color: #000;
    box-shadow: 3px 3px 3px 0px #e3e4e5;
}

.ph_htPdf table td:first-child {
    padding: 8px 15px;
}


/* .ph_htPdf table tr:first-child td:first-child {
    border-radius: 20px 0 0 0;
} */

.ph_htPdf table tr:first-child td:last-child {
    border-radius: 0 20px 0 0;
}

.ph_htPdf table tr:last-child td:first-child {
    border-radius: 0 0 0 20px;
}

.ph_htPdf table tr:last-child td:last-child {
    border-radius: 0 15px 0px 0;
}

.ph_htPdf table tr td:last-child {
    border-radius: 0 20px 0 0;
}

.ph_htPdf table td:nth-child(4),
.ph_htPdf table td:nth-child(5) {
    text-align: center;
    color: #46ab34;
    box-shadow: 3px 3px 3px 0px #e3e4e5;
}

.ph_htPdf table td:nth-child(5) {
    border-radius: 0 20px 0 0;
}

.ph_date_td {
    padding: 5px 50px 5px 15px!important;
}

.ht-terms.ph_disclaimer_info {
    background: #f2f5fa;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 3px 3px 3px 0px #e3e4e5;
    margin-top: 30px;
}

.ph_tblWrap .ph_babyImg {
    background: transparent;
    box-shadow: none;
    padding-left: 35px;
}

.ph_staus_no {
    color: red!important;
}

.ph_milestone_img {
    position: absolute;
    right: 0;
}

.ph_babyImg.ph_milestone_img img {
    width: 70px;
}

.ph_tblmilestne {
    margin-bottom: 40px;
    table-layout: auto;
    width: auto;
    border-spacing: 0px 30px;
}

.ph_tblWrap.ph_tblmilestne tr td:first-child {
    background: transparent;
    box-shadow: none;
    width: 200px;
    font-weight: bold;
}

.ph_htPdf table.ph_tblmilestne td span {
    width: auto;
    background: #f2f5fa;
    padding: 5px 80px;
    border-radius: 0 20px 20px 0;
    display: inline;
    box-shadow: 3px 3px 3px 0px #e3e4e5;
}

.ph_htPdf table.ph_tblmilestne tr td:last-child {
    border-radius: 0 20px 20px 0;
}

.ph_htPdf table.ph_tblmilestne td:nth-child(2)::before {
    /* padding: 15px 0px!important;
    background: none;
    box-shadow: none;
    border-left: 10px solid blue;
    border-radius: 5px!important; */
    content: "";
    position: absolute;
    border-left: 10px solid green;
    z-index: 99999;
    width: 6px;
    height: 50px;
    border-radius: 10px;
    margin-top: -15px;
}

.ph_htPdf table.ph_tblWrap.ph_tblmilestne td {
    background: transparent;
    box-shadow: none;
    font-size: 16px;
}


/* .ph_tblWrap.ph_tblmilestne tr td:first-child::after {
    position: absolute;
    border-left: 10px solid orange;
    width: 47px;
    height: 3px;
} */


/* td:nth-child(2)::before */

.ph_tblWrap.ph_tblmilestne tbody>:nth-child(1) td:nth-child(2)::before {
    content: "";
    position: absolute;
    border-left: 10px solid #70bfe6;
    z-index: 99999;
    width: 6px;
    height: 50px;
    border-radius: 10px;
    margin-top: -15px;
}

.ph_tblWrap.ph_tblmilestne tbody>:nth-child(3n+4) td:nth-child(2)::before {
    border-left: 10px solid #70bfe6;
}

.ph_tblWrap.ph_tblmilestne tbody>:nth-child(3n+2) td:nth-child(2)::before {
    border-left: 10px solid #9470e6;
}

.ph_tblWrap.ph_tblmilestne tbody>:nth-child(3n+6) td:nth-child(2)::before {
    border-left: 10px solid #e68270;
}

.ph_tblWrap.ph_tblmilestne tbody>:nth-child(3n+3) td:nth-child(2)::before {
    border-left: 10px solid #e670e1;
}

.ph_tblWrap.ph_tblmilestne tbody>:nth-child(2) td:nth-child(2)::before {
    content: "";
    position: absolute;
    border-left: 10px solid #9470e6;
    z-index: 99999;
    width: 6px;
    height: 50px;
    border-radius: 10px;
    margin-top: -15px;
}

.ph_tblWrap.ph_tblmilestne tbody>:nth-child(3) td:nth-child(2)::before {
    content: "";
    position: absolute;
    border-left: 10px solid #e670e1;
    z-index: 99999;
    width: 6px;
    height: 50px;
    border-radius: 10px;
    margin-top: -15px;
}

.ph_tblWrap.ph_tblmilestne tbody>:nth-child(4) td:nth-child(2)::before {
    content: "";
    position: absolute;
    border-left: 10px solid #e68270;
    z-index: 99999;
    width: 6px;
    height: 50px;
    border-radius: 10px;
    margin-top: -15px;
}

.ph_tblWrap.ph_tblmilestne tbody>:nth-child(5) td:nth-child(2)::before {
    content: "";
    position: absolute;
    border-left: 10px solid #70bfe6;
    z-index: 99999;
    width: 6px;
    height: 50px;
    border-radius: 10px;
    margin-top: -15px;
}

.ph_tblWrap.ph_tblmilestne tbody>:nth-child(6) td:nth-child(2)::before {
    content: "";
    position: absolute;
    border-left: 10px solid #9470e6;
    z-index: 99999;
    width: 6px;
    height: 50px;
    border-radius: 10px;
    margin-top: -15px;
}

.ph_tblWrap.ph_tblmilestne tbody>:nth-child(7) td:nth-child(2)::before {
    content: "";
    position: absolute;
    border-left: 10px solid #e670e1;
    z-index: 99999;
    width: 6px;
    height: 50px;
    border-radius: 10px;
    margin-top: -15px;
}

.ph_tblWrap.ph_tblmilestne tbody>:nth-child(8) td:nth-child(2)::before {
    content: "";
    position: absolute;
    border-left: 10px solid #e68270;
    z-index: 99999;
    width: 6px;
    height: 50px;
    border-radius: 10px;
    margin-top: -15px;
}

.ph_tblWrap.ph_tblmilestne tbody>:nth-child(9) td:nth-child(2)::before {
    content: "";
    position: absolute;
    border-left: 10px solid #70bfe6;
    z-index: 99999;
    width: 6px;
    height: 50px;
    border-radius: 10px;
    margin-top: -15px;
}

.ph_tblWrap.ph_tblmilestne tr td:first-child::after {
    content: "";
    position: absolute;
    border-left: 75px solid #f2f5fa;
    height: 6px;
    margin-top: 8px;
    margin-left: 15px;
    box-shadow: 3px 3px 3px 0px #e3e4e5;
}

.ph_htPdf table.ph_tblWrap.ph_tblmilestne td:first-child {
    padding-left: 0px;
}


/*  */

.ph_tableOuter {
    width: 100%;
    float: left;
    height: 100%;
    display: flex;
    /* overflow: hidden; */
}

.ph_tableOuterBaby {
    width: 100%;
    float: left;
    height: 100%;
    display: flex;
    /* overflow: hidden; */
}

#ph_immunization {
    width: 90%;
}

.ph_babyImageRight {
    width: 10%;
    /* transform: translate(30px, 80px); */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 70px 0 0;
}


/* .ph_babyImageRight .ph_babyImg {
    margin: 40px 0 40px;
} */

.ph_babyImageRight.ph_bbymlstne {
    transform: none;
}

.ph_babyImg {
    margin: 0 0 15px;
}

.ph_tableOuterBaby_Wrap {
    width: 100%;
}


/* Google Map */

.googleMapWrapper {
    width: 100%;
    height: 200px;
    position: relative;
}

.adrsmap-hspadmin {
    width: 100%;
    height: 100%;
}


/* hospital */

.tableHeader-promoHsp {
    width: 100%;
    height: 50px;
    clear: both;
    margin-bottom: 10px;
}

.hspInput-promo {
    padding: 1px;
    width: 100%;
    font-family: Helvetica, sans-serif;
    font-size: 12px;
    margin: 1px 0px 0px;
    border: 1px solid lightgray;
    height: 32px;
    text-indent: 5px;
    color: #8f8f8f;
    border-radius: 20px;
    padding: 8px;
}

.searchbtn-promo {
    color: var(--common-button-bgcolor);
    padding: 5px 25px;
    border-radius: 20px;
    text-transform: capitalize;
    border: 1px solid var(--common-button-bgcolor);
    margin: 1px 0px 0px;
    background-color: #fff;
    text-decoration: none;
    font-size: 12px;
    min-width: 95px;
    width: 100%;
}

.selectField-promoHsp {
    float: left!important;
    margin-right: 0.5%;
    width: 14%;
    margin-bottom: 10px;
}

.posrel-promo {
    position: relative;
}

.date-promohsp-Img {
    position: absolute;
    right: 26px;
    top: 4px;
}

.dateHeader-promohsp-Img {
    position: absolute;
    right: 8px;
    top: 5px;
}

.dashbrd-menu-leftt-hospital .videoSpan {
    font-size: 12px;
}

.right_span {
    opacity: 0;
    visibility: hidden;
    transition: 1s;
}

.dashbrd-menu-leftt-hospital:hover .right_span {
    opacity: 1;
    visibility: visible
}

.dashbrd-menu-leftt-hospital .LinksList .dashBoardMenu li:last-child {
    border-bottom: none;
}

.hspmain-wrapper-promo {
    margin-bottom: 0;
    min-height: 70vh;
    padding: 0;
}

.dashbrd-rght-bottom-padng-hsp {
    margin-bottom: 70px;
}

.hspmain-wrapper-promo table i {
    font-size: 18px;
}

.list_tab {
    padding: 14px 10px;
    color: rgba(51, 51, 51, .8);
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
}

.list_tab:hover {
    text-decoration: none;
}

.list_tab.active {
    background-color: rgb(239, 239, 239);
    color: #0f4fa1;
}

.tabmain_list {
    display: flex;
    border: 1px solid #f3e6ff;
    margin-bottom: 5px;
    margin-top: 5px;
}

.tabmain_list h6 {
    padding: 10px;
    font-weight: 500;
    font-size: 18px;
}

.dashbrd-rght-bottom-padng-hsp .input-design {
    font-size: 14px;
    padding: 6px 15px;
}

.dashbrd-rght-bottom-padng-hsp {
    font-size: 16px;
}

.custome_rgi label {
    position: relative;
    margin-bottom: 0px;
}

.custome_rgi span {
    padding: 8px 15px;
    margin-left: -1px;
    border: 1px solid gainsboro;
    display: block;
}


/* input[type="radio"]:checked+label{ font-weight: bold; }  */

.custome_rgi input {
    opacity: 0;
    position: absolute;
    transform: scale(2.5) translate(-50%, -50%);
    vertical-align: middle;
    top: 50%;
    left: 50%;
    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;
}


/* .custome_rgi label:last-child span{
    border-radius: 0px 20px 20px 0px;
} */

.custome_rgi label:first-child span {
    margin-left: 3px;
}

.border_change {
    border-radius: 20px 0px 0px 20px;
}

.custome_rgi input[type="radio"]:checked+span {
    background-color: #0f4fa1;
    color: #fff;
}

.coupon_btn input {
    font-size: 14px;
}

.appoinmnt-tblheader {
    display: inline-block;
    width: 13%;
    margin: 5px;
}

.time-input {
    width: 41px;
    border-radius: unset;
    margin: 1px;
    padding: 6px 0px !important;
    border-radius: 13px;
}

.td-width-apntTme {
    width: 15%;
}

.datepickerIcon-hsp {
    background-image: url(../image/icons/calendar.png);
    background-repeat: no-repeat;
    background-position: 99%;
    background-size: 30px;
    cursor: pointer;
}

.appoinmnt-tblheader .react-datepicker__input-container input::placeholder {
    color: #545454;
}

.hsp-appointmntt-wraper .form-head {
    padding: 10px 20px;
}

.hsp-appointmntt-wraper .breadcrumb {
    padding: 10px 6px;
    border: unset;
}

.breadcrum-li-a a {
    color: #3498db;
}

.file_upload {
    position: relative;
    font-size: 14px;
    padding: 6px 20px;
    border: 1px solid #0f4fa1;
    border-radius: 7px;
    color: #0f4fa1;
    cursor: pointer;
    margin: 0;
}

.file_upload input {
    position: absolute;
    opacity: 0;
}


/* .dashbrd-rght-bottom-padng-hsp input[type="submit"],.dashbrd-rght-bottom-padng-hsp input[type="button"]{
    background-color: #0f4fa1;
    color: #fff;
    padding: 0.35rem 1rem;
} */

#row-col-width-leftmenu .col-lg-3 {
    max-width: 22% !important;
}

.group_list.active,
.group_plicy.active,
.report_list.active,
.main_coupons.active,
.group_list.active,
.utilization_list.active,
.error_list.active {
    display: block;
}

.group_list,
.group_plicy,
.report_list,
.main_coupons,
.group_list,
.utilization_list,
.error_list {
    display: none;
    padding: 0px 10px 0px 10px
}

.report_list .list_tab {
    font-size: 13px;
}

.report_list .list_tab.active {
    font-weight: 500;
}

.grpadmin-btnupdate {
    width: 100%;
    height: 40px;
    border: 1px solid #0f4fa1;
    background: transparent;
    color: #0c4da2;
    border-radius: 5px;
    font-weight: 700;
    font-size: 15px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 40%);
}

.grpadmin-btnucancel {
    width: 100%;
    height: 40px;
    border: 1px solid #393939;
    background: transparent;
    color: #393939;
    border-radius: 5px;
    font-weight: 700;
    font-size: 15px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 40%);
}

.grpadmin-btnucancel:hover {
    color: #fff;
    background-color: #393939;
}

.grpadmin-btnupdate:hover {
    color: #fff;
    background-color: #0c4da2;
}

.prflefrm-border {
    border: 1px solid #f3e6ff;
}

.headertext-bg {
    background-color: #f1f1f1;
    padding: 5px 20px;
}

.expertdctr-bgcolor {
    background-color: #f1f1f1;
    padding: 8px 20px;
}

.label-textpos {
    text-align: right;
}

.label-text-fontpro {
    font-size: 15px;
}

.label-text-prfhsp {
    font-size: 15px;
}

.toggle_header {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 10px 15px;
    width: 100%;
    text-align: left;
    border: none;
    font-size: 14px;
    margin-bottom: 10px;
}

.faMinus {
    display: none;
}

.toggle_row-sec {
    display: none;
    border: 1px solid #f3e6ff;
}

.group_listLink_Active .toggle_row-sec {
    display: block;
}

.group_listLink_Active .faPlus {
    display: none;
}

.group_listLink_Active .faMinus {
    display: block;
}

.patient-profleWraper {
    margin: 0 auto;
}

.patientprf-subhead {
    padding: 15px;
    background-color: #f2f2f2;
}


/* .modal-popup,.popup-overlay{
    display: none;
} */

.health_record {
    margin: auto;
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 500px;
    background: #fff;
}

.health_record h5,
.health_record p {
    text-align: center;
}

.expertdctr-selectndiv {
    position: absolute;
    left: 15px;
    top: 35px;
    right: 15px;
    background-color: #ffff;
    border: 1px solid #cbc6c6;
    border-radius: 9px;
    z-index: 9999;
    padding: 10px 15px;
    max-height: 350px;
    overflow: auto;
    /* display: none; */
}

.expertdctr-selectndiv ul li {
    list-style-type: none;
    padding: 4px;
    border-bottom: 1px solid #ededed;
    cursor: pointer;
}

.expertdctr-selectndiv ul li:last-child {
    border-bottom: none;
}

.expertdctr-selectndiv ul {
    padding-left: 0;
    margin-bottom: 0;
}

.cursor-pontr {
    cursor: pointer;
}

.hsp-appointmntt-wraper .breadcrumb li a,
.hsp-appointmntt-wraper .breadcrumb li {
    font-size: 14px;
}

.sect-dctr-width {
    width: 190px;
}

.select-dcrpopup {
    height: 32px !important;
}

.dctr-btn-width {
    width: 100px;
}

.tble-width-min {
    min-width: 1000px;
}

.dashbrd-rght-bottom-padng-hsp table th {
    white-space: nowrap;
    font-weight: 600;
}

.dashbrd-rght-bottom-padng-hsp table {
    font-size: 12px;
}

.row_flex {
    counter-reset: my-sec-counter;
}

.row_flex>div::before {
    counter-increment: my-sec-counter;
    content: counter(my-sec-counter);
}

.row_flex>div {
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.row_flex>div:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.05);
}

.border_radious_right {
    margin-right: -1px;
    /* border-radius: 20px 0px 0px 20px!important; */
}

.row_common {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 12px;
}

.row_common input[type="text"] {
    width: 60px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    padding: 8px 10px;
    border-radius: 0;
    color: #545454;
}

.row_common label {
    margin-right: 5px;
}

.row_common label span {
    margin-left: 5px;
    margin-right: 10px;
}

.row_close i {
    font-size: 18px;
    cursor: pointer;
}

.file_upload {
    width: auto;
}

.fs_12 {
    font-size: 12px;
}

ol li i {
    cursor: pointer;
}

.formBtnBg {
    padding: 8px 10px;
    border: 1px solid gainsboro;
    background-color: #0f4fa1;
    border-radius: 0px;
    color: #fff;
}

.custom_label {
    white-space: nowrap;
    margin-bottom: 0px;
    margin-right: 5px;
}

.custome_input,
.react-datepicker__input-container .custome_input {
    font-size: 13px;
    width: 100%;
    padding: 11px 10px;
    border: 1px solid gainsboro;
    border-radius: 0px;
    color: #333;
}

.accordion__button:hover {
    background-color: #f4f4f4 !important;
}

.dashbrd-rght-bottom-padng-hsp [aria-label="Go to first page"],
.dashbrd-rght-bottom-padng-hsp [aria-label="Go to last page"] {
    display: none;
}

.dashbrd-rght-bottom-padng-hsp .pagination>li>a,
.dashbrd-rght-bottom-padng-hsp .pagination>li>span {
    font-size: .7777777778em;
    padding: 9px 14px;
    color: #428bca;
    font-weight: bold;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 4px;
    margin: 5px;
}

.dashbrd-rght-bottom-padng-hsp .pagination>.active>a {
    background-color: #0c4da2;
    color: #fff;
}

.dashbrd-rght-bottom-padng-hsp .pagination>.active>span,
.dashbrd-rght-bottom-padng-hsp .pagination>.active>span:hover,
.dashbrd-rght-bottom-padng-hsp .pagination>.active>a:focus,
.dashbrd-rght-bottom-padng-hsp .pagination>.active>span:focus {
    background-color: white;
    color: #0c4da2;
}

.dashbrd-rght-bottom-padng-hsp [aria-label="Go to previous page"],
.dashbrd-rght-bottom-padng-hsp [aria-label="Go to next page"] {
    width: auto !important;
}

.dashbrd-rght-bottom-padng-hsp .span_circle {
    cursor: pointer;
    width: 15px;
    text-align: center;
    height: 15px;
    border-radius: 50%;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px !important;
    margin-right: 5px;
    padding-bottom: 2px;
}

.dashbrd-rght-bottom-padng-hsp i {
    cursor: pointer;
}

.accordion__panel .custome_input {
    border: 1px solid gainsboro;
}

.border_btn {
    background: white;
    color: #0c4da2;
    border: 1px solid #0c4da2!important;
    font-size: 16px;
    padding: 10px 10px;
    border-radius: 5px;
    text-align: center;
    margin-right: 5px;
    min-width: 185px;
    font-weight: 600;
}

.border_btn:last-child {
    margin-right: 0px;
}

.dashbrd-rght-bottom-padng-hsp .accordion .accordion__button {
    border-radius: 4px;
    padding: 10px 15px;
    position: relative;
    border-bottom: 0px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.dashbrd-rght-bottom-padng-hsp .accordion .accordion__button::before {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10px;
}

.group_list.active,
.group_plicy.active {
    border: 1px solid gainsboro
}

.dashbrd-rght-bottom-padng-hsp .group_list.active label {
    font-size: 16px;
}

.dashbrd-rght-bottom-padng-hsp .search-right-section,
.search-box {
    font-size: 13px;
    width: 100%;
    padding: 8px 10px;
    border: 1px solid gainsboro;
    border-radius: 0px;
    color: #333;
}

.dashbrd-rght-bottom-padng-hsp .selected-item~.search-box {
    display: none;
}

.dashbrd-rght-bottom-padng-hsp .selected-item {
    position: absolute;
}

.dashbrd-rght-bottom-padng-hsp .close-icon {
    border: 0px;
}

.dashbrd-rght-bottom-padng-hsp .close-icon::after {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 10px;
    border: 0px;
}

.div_version {
    background: #ddd;
    padding: 2px 5px;
}

.dashbrd-rght-bottom-padng-hsp .table thead th {
    border-top: 0px;
    border-bottom: 1px solid #000;
}

.dashbrd-rght-bottom-padng-hsp .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f3f3f3;
}

.fa-pencil {
    color: #01688a;
}

.dashbrd-rght-bottom-padng-hsp .custome_input.border_change {
    border: 1px solid #b0adad;
}

input[type="text"]:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus,
select:focus,
 :focus,
button:focus,
.bootstrap-select .dropdown-toggle:focus,
textarea:focus {
    outline: 1px solid #0c4da2;
}

.dashbrd-rght-bottom-padng-hsp ::placeholder {
    color: #333;
}

.dashbrd-rght-bottom-padng-hsp .custom_table {
    border: 1px solid #b9b9b9;
}

.dashbrd-rght-bottom-padng-hsp .custom_table th {
    border-bottom: 0px !important;
    background: #efefef;
}

.dashbrd-rght-bottom-padng-hsp .custom_table th,
.custom_table td {
    border: 1px solid #b9b9b9;
    padding: 9px 5px;
    font-size: 12px;
    text-align: center;
}

.dashbrd-rght-bottom-padng-hsp .btn_basics:hover {
    background: #0c4da2;
    color: #fff;
}

.dashbrd-rght-bottom-padng-hsp .btn_gray {
    border: 1px solid #393939 !important;
    color: #393939;
}

.dashbrd-rght-bottom-padng-hsp .btn_gray:hover {
    background-color: #393939;
    color: #fff;
}

.label-text-prfhsp~.custome_input {
    margin-bottom: 15px;
}

.dashbrd-rght-bottom-padng-hsp .group_listLink {
    border: 1px solid #f3e6ff;
    padding: 8px;
}

.dashbrd-rght-bottom-padng-hsp textarea[resize=none] {
    resize: none;
}

.dashbrd-rght-bottom-padng-hsp .imp_header {
    font-size: 20px !important;
}

.dashbrd-rght-bottom-padng-hsp .time_div {
    display: flex;
    align-items: center;
}

.dashbrd-rght-bottom-padng-hsp .time_div select {
    min-width: 70px;
    margin-right: 4px;
}

.btn_danger {
    background: #e57373;
}

.doctrcheckboxDivhsp input[type="checkbox"] {
    width: 20px;
    height: 20px;
}

.tablehead-hspmng {
    border-top: 1px solid !important;
    border-bottom: 0px !important;
}

.text_decoration_underline {
    text-decoration: underline;
}

.bg_color {
    background-color: #efecec;
}

.support_div {
    color: #034da2;
    font-size: 18px;
    font-weight: 600;
}

.QckLinksNew i {
    font-size: 15px;
}

.QckLinksNew img {
    width: 15px;
}

.LinksList a.is-active,
.LinksList a:hover {
    color: #0c4da2 !important;
}

[role="button"] {
    cursor: auto !important;
}

.dashboard-doctorOuter {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.dashbrd-menu-leftt-hospital {
    width: 50px;
    background-color: #dedddd;
    transition: 1s;
    position: relative;
    z-index: 9999;
    height: 100%;
}

.main-wrapper1 {
    border: 1px solid gainsboro;
    padding: 10px 15px 0px;
    margin-top: 5px;
}

.LinksList h5 {
    white-space: nowrap;
    opacity: 0;
    color: #808080;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 5px;
    transition: 1s;
    color: black;
    width: 100%;
    letter-spacing: 1px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.show_hide {
    opacity: 0;
    transition: 1s;
}

.LinksList .dashBoardMenu li a {
    position: relative;
    outline: 0px;
    display: block;
    text-decoration: none;
    color: #808080;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 18px;
    padding-right: 5px;
    border-bottom: 1px solid white;
}

.dashbrd-menu-leftt-hospital:hover {
    z-index: 99999;
    position: absolute;
    width: 280px;
}

.dashbrd-menu-leftt-hospital:hover .show_hide,
.dashbrd-menu-leftt-hospital:hover .LinksList h5 {
    opacity: 1
}

.p_11 {
    padding: 11px 12px;
}

.date_width .react-datepicker-wrapper {
    width: auto !important;
}

.bg_ddd {
    background: #efecec;
}

.date_width .table thead th {
    border-top: 0px;
    border-bottom: 1px solid #000;
    white-space: unset;
    font-size: 14px;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.date_width .breadcrumb {
    border: 0px;
    padding: 0;
}

.date_width {
    border: 0px;
}

.date_width .form-head {
    color: #0f4fa1;
    padding: 30px 0px 20px 0px;
}

.date_width .breadcrumb a {
    color: #3498db;
}

.date_width .breadcrumb li {
    color: #bcbcbc;
}

.date_width table td {
    color: #737272;
    font-size: 12px;
}

.date_width tbody tr:nth-of-type(odd) {
    background: transparent !important;
}

.subrowtr {
    background: #dedddd !important;
}

.table_div {
    overflow: auto;
    width: 100%;
}

.table_common {
    padding: 10px;
}

.table_full {
    font-size: 12px;
    font-weight: 600;
    padding: 10px;
}

.table_divbody {
    width: fit-content;
}

.table_divhead,
.table_container {
    display: inline-flex;
    font-size: 12px;
}

.table_divcontainer {
    padding: 10px 0px;
    width: fit-content;
}

.table_divcontainer .table_divbody:nth-child(odd) {
    background: #f3f3f3;
}

.table_divhead {
    font-weight: 600;
    padding: 20px 0px;
    font-size: 12px;
    border-bottom: 1px solid #000;
}

.div_modify {
    width: 71px;
}

.div_srno {
    width: 53px;
}

.div_versions {
    width: 190px;
}

.div_share_value {
    width: 78px;
}

.div_share {
    width: 91px;
}

.div_create {
    width: 110px;
}

.div_validfrom {
    width: 110px;
}

.div_stateschange {
    width: 100px;
}

.div_remarks {
    width: 85px;
}

.div_expirydate {
    width: 110px;
}

.subrowdiv {
    padding: 10px 0px;
    background: #dedddd;
}

.dashbrd-rght-bottom-padng-hsp .pagination>li>a {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashbrd-rght-bottom-padng-hsp .pagination>li:first-child,
.dashbrd-rght-bottom-padng-hsp .pagination>li:last-child {
    display: none;
}

input[type="text"]:disabled {
    background: #ccc;
    opacity: 0.9;
}

input[readonly=""] {
    background: #ccc;
}

textarea[readonly=""] {
    background: #ccc;
}

input[disabled=""] {
    background: #fff;
}

input[type="text"] :read-only {
    background: #ccc;
}

.contentCoverWrapper {
    min-height: 600px;
}

.mediaMain-title {
    text-align: left;
    font-family: "Montserrat Regular";
    color: #404040;
    font-size: 20px;
    width: 100%;
    text-transform: uppercase;
}

.mediaMain-title-supprt {
    text-align: left;
    font-family: "Montserrat Regular";
    color: #404040;
    font-size: 20px;
    width: 100%;
}

.media-wrap {
    margin: 45px;
    padding-top: 30px;
}

.media-wrap-margin {
    margin: 0 auto;
}

.mediaMain-title-text {
    padding: 23px 0 0;
    font-size: 17px;
}

.media-outer-wrap p {
    color: #555;
    font-size: 16px;
    padding-top: 10px;
    line-height: 30px;
    font-family: "OpenSans Regular";
    padding-left: 5px;
    margin-bottom: 2px;
}

.media-outer-wrap ul {
    padding: 0;
    margin: 0;
    padding-left: 25px;
    list-style-position: inside;
    list-style: disc;
}

.media-outer-wrap ul li {
    font-family: "OpenSans Regular";
    color: #636363;
    line-height: 30px;
    font-size: 16px;
}

.media-paratexthead {
    padding: 10px 0 0;
    font-size: 17px;
}

.profileHeadTitle {
    font-family: "Montserrat Regular";
    display: inline-block;
    padding: 10px;
    background: #f2f2f2;
    width: 30%;
    color: grey;
    margin: 15px 0;
}

.contentCoverWrapper {
    min-height: 600px;
}

.joinasmember-bg {
    background-color: #f2f2f2;
}

.joinasmember-head {
    color: #0c4da2;
    text-align: center;
    font-family: "Montserrat Regular";
    font-size: 24px;
    padding-bottom: 12px;
    font-weight: 500;
}

.joinasmember-input {
    width: 100%;
    height: 50px;
    border: 1px solid gainsboro;
    font-size: 15px;
    text-indent: 15px;
    background: 0;
    border: 1px solid #c9d9f0;
    background-position: 5px 14px;
    background-repeat: no-repeat;
    padding-left: 30px;
    border-top: 0;
    border-left: none;
    border-right: 0;
    border-bottom: 1px solid gainsboro;
    font-family: "Montserrat Regular";
    font-weight: 500;
}

.joinasmember-input:focus {
    outline: none !important;
}

.joinasmember-form ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.joinasmember-form {
    width: 100%;
    background: #fff;
    border-radius: 5px;
    padding: 30px;
    border: 5px solid #c8c8c8;
    overflow: hidden;
    text-align: center;
    margin: 0 auto;
}

.joinasmember-input-policy,
.joinasmember-input-policy[readonly=""] {
    background-image: url("../image/icons/policy.png");
    background-repeat: no-repeat;
    background-position: left;
    background-color: transparent;
}

.joinasmember-input-name,
.joinasmember-input-name[readonly=""] {
    background-image: url("../image/icons/name.png");
    background-repeat: no-repeat;
    background-position: left;
    background-color: transparent;
}

.joinasmember-input-mob,
.joinasmember-input-mob[readonly=""] {
    background-image: url("../image/icons/phne.png");
    background-repeat: no-repeat;
    background-position: left;
    background-color: transparent;
}

.joinasmember-input-email,
.joinasmember-input-email[readonly=""] {
    background-image: url("../image/icons/mail.png");
    background-repeat: no-repeat;
    background-position: left;
    background-color: transparent;
}

.joinasmember-form li {
    margin-bottom: 10px;
}

.terms-div {
    text-align: left;
    padding-left: 7px;
}

.terms-text {
    padding-left: 12px;
    font-size: 16px;
}

.register-btn {
    background: #0c4da2;
    color: #fff;
    font-size: 16px;
    border: 0;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    font-family: "Montserrat Regular";
    width: 100%;
    text-align: center;
}

.joinasmember-outer {
    padding: 60px 0;
}

.terms-text a {
    color: #3498db;
}

.terms-text a:hover {
    text-decoration: none;
}

.user-first {
    width: 49%;
}

.user-last {
    width: 49%;
    width: 50%;
    border-left: 1px solid gainsboro;
}

.form-errormsg {
    color: red;
    font-size: 14px;
    text-align: center;
    padding-bottom: 10px;
}

.joinasmember-form input[readonly=""] {
    /* background-color: transparent; */
    /* background: unset; */
}

.promoCodeBox-width {
    width: 100%;
}


/* .promoCodeBox-width-textChat {
    width: 740px;
} */

.popBack {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    z-index: 5;
    overflow-y: auto;
}

.popOverlay {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.customizationPop {
    background-color: #fff;
    width: 40%;
    margin: 15px auto;
    border-radius: 5px;
    z-index: 1;
    position: absolute;
    padding: 30px 15px;
    left: 0;
    right: 0;
}

.closepopupqual {
    position: absolute;
    top: 8px;
    right: 17px;
    cursor: pointer;
}

.available-coupon-list {
    min-height: 315px;
    overflow: auto;
    max-height: 200px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 15px;
}

.available-coupnhead {
    font-size: 20px;
    font-weight: 700;
    color: #061f40;
    padding-bottom: 12px;
    border-bottom: 3px solid #061f40;
    text-align: center;
}

.available-coupn-list {
    font-size: 15px;
    font-weight: 600;
    list-style-type: none;
}

.coupn-list-name {
    width: 180px;
    display: inline-block;
    vertical-align: top;
    margin: 5px 0;
    border: 1px dashed #235bd5ad;
    border-radius: 8px;
    padding: 5px 10px;
    text-align: center;
    background-color: #eff5fb;
}

.coupn-list-applybtn {
    width: 70px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
}

.coupn-list-applybtn .form-btn-apply-mob {
    margin: 5px;
}

.formButton-apply-margin {
    margin: 0;
}

.table tbody .tble-td-verticalTop td {
    vertical-align: top;
}

.blg-wrapr-height .blog-wrapper {
    min-height: auto;
}

.form-section .label-text.label-text-font {
    font-weight: 300;
}

.delete-medicine-closesm {
    position: absolute;
    /* font-size: 25px; */
    right: -10px;
    top: -10px;
}

.coupn-list-descrptn {
    display: inline-block;
    width: 220px;
    font-size: 12px;
    text-align: center;
    padding: 6px;
    vertical-align: middle;
    color: #061f40;
}

.coupnlist-applybtn {
    color: #e70808;
    padding: 5px 25px;
    padding-bottom: 7px;
    border-radius: 20px;
    border: none;
    background-color: transparent;
    text-decoration: none;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.form-btn-border {
    border: none;
}

.promoCodeBox-width {
    width: 645px;
}

.formButton-apply-mleft {
    margin-left: 5px;
}

.promo-totalsec {
    margin-top: 10px;
    background-color: aliceblue;
    padding: 15px 20px;
    border: 1px solid #e4e9ed;
}

.grand-total-labl {
    margin-top: 5px;
    font-size: 17px;
    font-weight: 700;
}

.coupon-labl-text {
    color: #0f4fa1;
    font-weight: 600;
}

.form-section .label-text-right.grand-labl-font,
.grand-labl-font {
    font-weight: 700;
}

.coupon-labltxt-font {
    color: #0f4fa1;
    font-weight: 600;
}

.promoCodeBox-width {
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
}

.promo-marginTop {
    margin-top: 20px;
}

.formButton-apply.availble-btn-margin {
    margin: 0px 10px;
}

.formbutton-apply-light-btn {
    background-color: #fff;
    border: 1px solid var(--common-button-bgcolor);
}

.formbutton-remove-light-btn {
    background-color: #fff;
    border: 1px solid var(--cancel-button);
}

.formbutton-coupon-light-btn {
    background-color: #fff;
    border: 1px solid #059005;
}

.formbutton-apply-light-btn:hover {
    background-color: var(--common-button-bgcolor);
    color: #fff;
}

.formbutton-remove-light-btn:hover {
    background-color: var(--cancel-button);
    color: #fff;
}

.formbutton-coupon-light-btn:hover {
    background-color: #059005;
    color: #fff;
}

.book-appointments .cancelButton:hover {
    background-color: var(--cancel-button);
}

.prescription-mainhead {
    display: flex;
    justify-content: space-between;
    padding-right: 60px;
}

.hsptaltimeHead {
    display: flex !important;
    flex-wrap: wrap;
}

.addimgeDivHsp {
    margin-left: auto;
}

input[type="checkbox"] {
    vertical-align: middle;
}

a,
button {
    cursor: pointer;
}

.left_link {
    display: flex !important;
    align-items: center;
}

.span_btn {
    position: relative;
    cursor: pointer;
}

.span_btn i {
    font-size: 21px;
    margin-right: 5px;
    margin-left: 5px;
}

.available {
    font-size: 18px;
    font-weight: 600;
}

.fa_circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: blue;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #ffff;
}

.promomessage {
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

.modal-popup>.close-popup {
    background: url("../image/icons/login-close.png");
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 0;
}

.subrowdiv .div_versions {
    padding-left: 25px;
}

.addclasscolo {
    color: green;
}

.custom_scroll::-webkit-scrollbar {
    width: 10px;
    height: 17px;
}

.custom_scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom_scroll::-webkit-scrollbar-thumb {
    background: #888;
}

.custom_scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.add {
    color: red;
}

.add1 {
    color: green;
}

.thankYouMessage{
    display: flex;
    height: 100%;
   align-items: center;
   text-align: center;
}