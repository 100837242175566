@media (max-width: 1550px) {}

@media (max-width: 1024px) {}

@media (max-width: 991px) {
    .Dr-details {
        margin: 21px;
        width: 42%;
        max-width: 42%;
        flex: 0 0 50%;
    }
    .serviceWrap ul li label {
        width: 44%;
    }
    .priceCover .popField {
        width: 99px;
    }
    .dashboard-right.dashbrd-rght-bottom-padng-hsp {
        padding-bottom: 20px;
    }
    .mentalMenu {
        display: none;
        position: absolute;
        top: 78px;
        background: #034da2;
        z-index: 1;
        left: 0;
        right: 0;
        width: 100%;
        padding: 0;
        box-shadow: 0px 2px 4px #272727;
    }
    .menu_item:hover .mentalSubMenu {
        display: none;
    }
    .sliderTopHalf {
        padding: 15px 10px;
        font-size: 12px;
        line-height: 16px;
    }
    .mentalCategotyList:before,
    .mentalCategotyList:after {
        width: 30px;
        height: 30px;
        right: -15px;
        top: -15px;
    }
    .mentalCategotyList:after {
        left: -15px;
        right: auto;
    }
    .mentalCategotyList {
        width: 100%;
        padding: 20px 15px;
    }
    .mentalCategotyList span {
        font-size: 15px;
    }
    .mentalCategorySection {
        margin: 0;
        padding: 0;
    }
    .mentalCategory {
        padding: 0 0 30px;
    }
    .mentalMiddleBanner {
        padding: 20px 25px 30px;
    }
    .mentalMiddleLeft,
    .mentalMiddleRight {
        width: 100%;
        padding: 20px 0;
        line-height: 34px;
        text-align: center;
        font-size: 26px;
    }
    .mentalMiddleRight span {
        font-size: 16px;
        margin: 0 0 20px;
    }
    .mentalMiddleRight img {
        width: 85%;
    }
    .mentalMiddleRight {
        padding: 10px 0;
    }
    .mentalLogo {
        padding: 15px 0;
    }
    .mentalLogo img {
        width: 185px;
    }
    .fa-chevron-down:before {
        content: "\f078";
        right: 0;
        /* position: absolute;
    bottom: 14px; */
    }
    .sliderTopHeading {
        margin: 1px 0 0;
    }
    .footerMenuList {
        width: 100%;
        margin: 0 0 20px;
        padding: 0 25px;
    }
    .footerTop {
        padding: 30px 0px 0px;
    }
    .footerTitle {
        font-size: 20px;
        margin: 0 0 15px;
    }
    .footerMenuList li {
        margin: 0 0 8px;
        font-size: 13px;
    }
    .footerMenuList li img {
        width: 13px;
    }
    .footerSocial img {
        width: 23px;
    }
    .footerBottomLink a,
    .footerBottomLink span,
    .footerBottomRight {
        font-size: 11px;
    }
    .footerBottomLink span {
        margin: 0 0 0 5px;
    }
    .mentalToggle {
        width: 25px;
        display: block;
        height: 20px;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }
    .mentalToggle span {
        display: block;
        position: relative;
        height: 3px;
        background: #25b4fc;
    }
    .mentalToggle span:before,
    .mentalToggle span:after {
        content: "";
        position: absolute;
        height: 3px;
        background: #25b4fc;
        left: 0;
        top: 7px;
        right: 0;
    }
    .mentalToggle span:after {
        top: 14px;
    }
    .mentalMenu li {
        display: block;
        text-align: center;
        font-size: 12px;
        border-bottom: 1px solid #1d5cad;
        border-radius: 0;
    }
    .mentalSubMenu li {
        border-bottom: 0px;
    }
    .mentalMenu li a {
        padding: 10px 15px;
        font-weight: 400;
        color: #fff;
    }
    .mentalMenu li .subMenuList a {
        padding: 2px 15px;
    }
    .mentalMenu li a:hover {
        color: #fff;
    }
    .mentalSubMenu .subMenuList {
        font-size: 12px;
        border: 0;
    }
    .mentalSubMenu li a {
        padding: 7px 15px;
    }
    .dropArrow {
        position: absolute;
        right: 0;
        top: 6px;
        width: 23px;
        height: 23px;
        right: 5px;
    }
    .dropArrow:after {
        border-color: #ffffff transparent transparent transparent;
        top: 8px;
    }
    .mentalMenu li:last-child {
        border: 0;
    }
    .mentalMenu ul {
        position: relative;
        width: 100%;
        top: 0;
        box-shadow: unset;
        flex-direction: column;
    }
    .mentalMenu li{
        margin: auto;
    }
    .headerTop {
        padding: 3px 15px;
        font-size: 11px;
    }
    .mentalMenu .mentalMenuAtive a {
        color: #ffffff;
        /* font-weight: 800; */
    }
    .footerContainer {
        padding: 0;
    }
    .mentalSubMenu {
        background: #074594;
    }
    .mentalMenu li a:hover {
        background-color: #074594;
    }
    .mentalMenu li:hover {
        background-color: #074594;
    }
    .mentalCategoryContent p {
        text-align: left
    }
    .subHeading {
        font-size: 14px;
        font-weight: 400;
    }
    .footerMenuList p {
        font-size: 14px;
    }
    body .parallaxContent h2 {
        font-size: 26px;
        width: 55%;
        padding-left: 30px;
        line-height: 43px;
    }
    .bannerSearchBar {
        width: 60%;
        padding: 10px;
    }
    body .homeSpotlightBlck {
        padding: 20px;
    }
    body .homeSpotlightBlck {
        min-height: auto;
    }
    .homeSpotlightBlck .homeSpotList .homeSpotIcon {
        font-size: 25px;
        margin-bottom: 0;
    }
    .homeSpotlightBlck .homeSpotList .homeSpotPara {
        font-size: 11px;
    }
    .homeSpotlightCover .homeSpotlightBlck {
        margin-top: -69px;
    }
    .bannerLogo,
    .footerParallaxLogo {
        width: 170px;
        height: 170px;
        right: 74px;
        top: -34%
    }
    .specialitiesWrapper .slick-prev,
    .bouquetWrapper .slick-prev {
        left: -16px;
    }
    .specialitiesWrapper .slick-next,
    .bouquetWrapper .slick-next {
        right: -16px;
    }
    .bannerRightLogo,
    .footerParallaxLogo {
        top: auto;
        bottom: 40%;
    }
    .subMenuMain {
        background-color: #034da2;
        position: absolute;
    }
    .specialitiesWrapper .slick-next,
    .specialitiesWrapper .slick-prev,
    .bouquetWrapper .slick-next,
    .bouquetWrapper .slick-prev {
        height: 20px;
        width: 20px;
    }
    .specialitiesWrapper .slick-next,
    .bouquetWrapper .slick-next {
        right: 0;
    }
    .specialitiesWrapper .slick-prev,
    .bouquetWrapper .slick-prev {
        left: 0;
    }
    .bannerSerchboxDiv {
        top: 64.5px;
    }
    .mentalMenu li:after {
        content: unset;
    }
    .mentalMenu .container {
        padding: 0;
    }
    .specialitiesWrapper .slick-next:hover,
    .specialitiesWrapper .slick-prev:hover,
    .bouquetWrapper .slick-next:hover,
    .bouquetWrapper .slick-prev:hover {
        width: 20px;
        height: 20px;
    }
    .form_section_start .profileHeadTitle,
    .profileHeadTitle {
        width: 100%;
    }
    .profile_page {
        width: 100%;
        padding: 0px;
    }
    .adrsmap_google {
        height: 200px;
    }
    .customizationPop,
    .popup-contentDiv {
        width: 80% !important;
    }
    .width_custom {
        width: 64%;
    }
    .videocall_div {
        width: 80%;
    }
    .simple_step {
        width: 100%;
    }
    .powerdBy {
        top: 0;
    }
}

@media (max-width: 768px) {
    .drListHearts {
        top: 3px;
    }
    .Dr-details{
        margin-bottom: 30px;
        max-width: 100%;
        margin-left: 15px;
        margin-right: 15px;
        width: 100%;
        flex: 1;
    }
    .serviceWrap ul li label {
        width: 35%;
    }
    .accordion__heading .accordion__button:before {
        position: absolute;
        right: 13px;
        top: 13px;
    }
    .priceCover .popField {
        width: 130px;
    }
    .border_btn {
        font-size: 13px;
        min-width: 129px;
        margin: 0 auto;
    }
    .smlFollow {
        display: block;
        margin: 0;
    }
    .dashbrd-menu-leftt {
        border-right: none;
    }
    .dashboard-right {
        margin: 3px;
    }
    .blogVisitPage {
        padding: 20px;
    }
    .main-wrapper1 {
        padding: 20px 10px;
    }
    .media-wrap {
        margin: 5px;
    }
    .appnt-type,
    .appnt-brdr,
    .appnt-status-text {
        font-size: 12px;
    }
    .mentalCategoryContent {
        padding-left: 15px;
    }
    .footerBottomLeft,
    .footerBottomRight {
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
    }
    .footerBottom {
        padding: 10px 0;
    }
    .footerMenuList p {
        font-size: 12px;
    }
    .mentalLogo img {
        width: 100px;
    }
    .mentalLogo {
        padding: 15px 15px;
    }
    .mentalMenu {
        top: 65px;
    }
    .aboutContent {
        width: 100%;
    }
    .aboutVideo {
        /* width: 100%; */
        text-align: center;
        width: 95%;
        margin-left: 10px;
    }
    .homeSpotlightCover .homeSpotList {
        width: 100%;
        height: auto;
    }
    .homeSpotlightCover .homeSpotlightBlck {
        margin-top: 0;
        /* padding: 0; */
    }
    body .parallaxContent h2 {
        font-size: 12px;
        width: 100%;
        padding: 0 10px;
        line-height: initial;
        margin: 0;
        text-align: center;
    }
    body .whiteParallax {
        /* height: 150px; */
        background-size: cover!important;
    }
    body .bannerSearchBar input[type="text"] {
        font-size: 12px;
        height: 32px;
        padding: 2px 10px 2px 10px;
        margin-bottom: 0;
    }
    .bannerLogo,
    .footerParallaxLogo {
        width: 58px;
        position: absolute;
        right: 12px;
        top: -64px;
        padding: 10px;
        height: 58px;
    }
    .parallaxContent {
        top: 65%;
    }
    .bannerRightLogo,
    .footerParallaxLogo {
        top: 1px;
        /* bottom: 10%; */
    }
    .bannerSearchIcon {
        font-size: 16px;
        top: 8px;
        right: 4px;
    }
    .bannerSearchBar {
        width: 87%;
        padding: 4px;
        margin-top: 13px;
    }
    .footerLinks ul {
        padding-left: 0;
    }
    .footerLinks.footerLinks-a ul li a {
        font-size: 12px;
    }
    .slick-slide .teamContentOuter:before {
        content: unset;
    }
    .bg-whiteExperts .slick-prev {
        left: 0;
        background-size: contain;
    }
    .bg-whiteExperts .slick-next {
        right: 0;
        background-size: contain;
    }
    .teamImg img {
        height: 225px;
    }
    .bg-whiteExperts {
        padding-top: 30px;
    }
    .bouquetContent img {
        text-align: center;
        margin: 0 auto;
    }
    .reactparallaxDiv {
        height: 160px !important;
    }
    .bg {
        width: 100vw;
        height: 26vh;
        background-image: url("https://purplehealthfiles.s3.amazonaws.com/test/rHealthAssistant/banner1.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        top: 0px;
        left: 0px;
        display: block;
    }
    .ParallaxBannershow .react-parallax img {
        display: none;
    }
    .mentalMenu li {
        padding: 0px 0;
    }
    .bg-whitebouquet {
        padding: 0 15px;
    }
    .bannerSerchboxDiv {
        top: 36.5px;
    }
    .footerParallax {
        height: 150px;
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: unset;
    }
    .homeSpotlightBlck .homeSpotList .homeSpotIcon img {
        width: 32px;
    }
    .specialityMainHead h3 {
        font-size: 18px;
    }
    .specialtySubHead h4 {
        font-size: 13px;
    }
    .react-parallax-background-children {
        transform: unset !important;
        left: 0 !important;
    }
    .contentCoverWrapper {
        min-height: auto;
    }
    .support_div {
        font-size: 10px;
    }
    select.input-design {
        padding: 3px 2px;
        font-size: 10px;
    }
    .week-text {
        font-size: 10px;
    }
    .hspInfofieldinput {
        width: 100% !important;
        font-size: 12px;
    }
    .office_hours {
        overflow-x: auto;
        font-size: 10px;
        padding: 0;
    }
    .office_hours label {
        font-size: 10px;
    }
    .office_hours select {
        width: 70px;
    }
    .addimgeDivHsp {
        margin-bottom: 0px;
        /* margin-top: 25px; */
        right: 10px;
    }
    .table thead th {
        min-width: 80px;
    }
    .table_overflowtd {
        width: 100% !important;
    }
    .customizationPop,
    .popup-contentDiv {
        width: 96% !important;
    }
    .width_custom {
        width: 100%;
    }
    .videocall_div {
        width: 98%;
    }
    .simple_stepheader {
        font-size: 16px;
    }
    .step_count {
        width: 30px;
        height: 30px;
        font-size: 14px;
    }
    .step_content {
        font-size: 16px;
        width: calc(100% - 30px);
        padding: 0px 10px;
    }
    .step_button a {
        font-size: 12px;
        padding: 8px 20px;
    }
    .grand-labl-font{
        font-size: 10px;
    }
    .input-box-w-150{
        width: 120px !important;
    }
    .formButton-apply {
        padding: 5px 20px;
        font-size: 10px !important;
    }
}
