@media (max-width: 1310px) {
    .coupn-list-descrptn {
        width: 170px;
    }
}

@media (max-width: 1199px) {
    .hspinfo-contentdiv label {
        width: 145px;
    }
    .superadminDivBox label {
        width: 145px;
    }
    .offcehrDayFieldDiv label {
        width: 145px;
    }
    .seofieldDivhsp label {
        width: 95px;
    }
    .hsplink-field label {
        width: 100px;
    }
    .customizationPop {
        width: 60%;
    }
}

@media (max-width: 1024px) {
    .smssetngsWrap {
        width: 940px;
        margin: 20px auto;
    }
    .smsSetngHead {
        width: 98%;
    }
    .smssetngscontentdiv .smsItem .react-datepicker-popper {
        top: 3% !important;
        left: 22% !important;
    }
    .health-track-card-right .tbody {
        width: 530px;
    }
    .health-track-card-right.hlth-immunetb table th:nth-child(4),
    .health-track-card-right.hlth-immunetb table td:nth-child(4),
    .health-track-card-right.hlth-immunetb table th:nth-child(5),
    .health-track-card-right.hlth-immunetb table td:nth-child(5) {
        width: 38px;
    }
    .health-track-card-right .tbody.babymilestne {
        width: 445px;
    }
    .health-track-card-right table th,
    .health-track-card-right table td {
        width: 45%;
    }
    .health-track-card-right.hlth-trck-pdf table th:first-child {
        width: 45%;
    }
    /* .input.hspInfofieldinput, select.hspInfofieldinput{
       width: 264px;
    } */
    .hspinfo-contentdiv label,
    .offcehrDayFieldDiv label,
    .superadminDivBox label {
        width: 155px;
    }
    .offcehrDayFieldDiv {
        width: auto;
    }
    /* .hspinfo-contentdivwidthres{
        width: 46% !important;
    } */
    .superadminDivBoxwidtAuto {
        width: auto;
    }
    .Infoinputwidthdoctr {
        width: 264px !important;
    }
    .doctorhspwrkdetal-ofcehour {
        width: 80%;
    }
    .eductninputwidthdctr {
        width: 180px !important;
    }
    .priceinputproduredetl {
        width: 50px !important;
    }
    .seofieldDivhsp label {
        width: 100px;
    }
    .hsplink-field label {
        width: 100px;
    }
    .customizationPop {
        width: 58%;
    }
}

@media (max-width: 992px) {
    .adminMangemntdc-dashboard {
        width: 100%;
    }
    .admin-dc-dashBtns {
        width: 30%;
    }
    .smssetngscontentdiv .smsItem input[type="text"],
    .smssetngInput {
        font-size: 12px;
        width: 60%;
    }
    .smssetngscontentdiv {
        width: 100%;
    }
    .smssetngscontentdiv .smsItem label {
        font-size: 12px;
        width: 204px;
    }
    .smssetngsWrap {
        width: 100%;
        margin: 10px auto;
    }
    .smssetngsTop label {
        font-size: 14px;
    }
    .admin-dashBtns {
        width: 33%;
    }
    .admin-dashBtns a {
        font-size: 14px;
    }
    .pagination-HspList ul li a {
        height: 28px;
        width: 28px;
        font-size: 12px;
        padding: 5px 6px;
        margin: 0 2px;
    }
    .AddNewHspbtnDiv {
        width: 26%;
    }
    .pagination-HspList {
        width: 74%;
    }
    .pagination-HspList {
        width: 100%;
    }
    .offcehrDay select {
        width: 95px;
    }
    .offcehrDaydiv label {
        width: 89px;
    }
    .offcehrDayFieldDiv label {
        width: 118px;
    }
    .offcehrDayFieldDiv select {
        width: 179px;
    }
    .table-bordered-hspSpecl th,
    .table-bordered-hspSpecl td {
        width: 245px;
    }
    .tdtxtLabel-hsp {
        font-size: 12px;
    }
    select.hspInfofieldinput {
        width: 259px;
    }
    .superadminDivBox {
        width: auto;
    }
    .serviceselectHsp {
        width: 155px !important;
    }
    .serviceselectHspMob-timeservicdetl {
        width: 95px !important;
    }
    .seofieldDivhsp-textarea {
        width: 670px;
        min-width: auto;
    }
    .seofieldDivhsp label {
        width: 100px;
        text-align: left;
    }
    /* .linkhspGenerateBtn-div{
       margin-left: -29px;
   } */
    .linkhspGenerateBtn {
        margin-left: -22px;
    }
    .ph-add-images .yes {
        width: 250px;
    }
    .doctorhspwrkdetal-ofcehour {
        width: 88%;
        padding-bottom: 23px;
    }
    .eductninputwidthdctr {
        width: 110px !important;
    }
    .hspinfo-contentdiv {
        width: 96%;
    }
    .hsplink-field label {
        width: 155px;
    }
    .health-track-card-right table th,
    .health-track-card-right table td {
        width: 100%;
    }
    .siteset-textareafield {
        width: 50%;
    }
    .dash_left_border {
        width: 100%;
    }
    .dash_left_border {
        margin-left: 0;
    }
    .available-coupn-list {
        text-align: center;
    }
    .formButton-apply-mleft {
        margin-left: 0;
    }
    .promoCodeBox-width {
        width: 100%;
    }
    .LinksList .dashBoardMenu li a {
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    .feedback-form{
        width: 100%;
    }
    .admin-dc-dashBtns {
        width: 75%;
    }
    .admin-dc-dashBtns a {
        font-size: 14px;
    }
    .smssetngsTop {
        width: 100%;
    }
    .smssetngsTop label {
        font-size: 14px;
    }
    .smssetngsTop label {
        width: 100%;
        text-align: left;
        font-size: 13px;
    }
    .smssetngsTop select {
        width: 100%;
        font-size: 13px;
    }
    .smsSetngHead {
        width: 100%;
        padding-top: 15px;
    }
    .smssetngscontentdiv {
        width: 100%;
        padding: 0px;
    }
    .smssetngscontentdiv .smsItem label {
        width: 100%;
        text-align: left;
    }
    .smssetngscontentdiv .smsItem input[type="text"] {
        width: 100%;
    }
    .smssetngCheckbox .smsItem label {
        width: 60%;
    }
    .smssetngInput {
        width: 100%;
    }
    .admin-dashBtns {
        width: 70%;
    }
    .pagination-HspList ul li a {
        height: 26px;
        width: 26px;
        padding: 3px 6px;
        margin: 3px 2px;
    }
    .AddNewHspbtnDiv {
        width: 100%;
    }
    .slecthspMob {
        width: 106px;
    }
    .hspmngInputmob {
        width: 139px;
    }
    .pagination-HspList ul {
        margin-bottom: 0;
    }
    .addnewhspbtn {
        margin-bottom: 4%;
    }
    .head-hospMangmntList {
        margin-bottom: 3%;
        font-size: 16px;
    }
    .hspinfo-contentdiv label,
    .offcehrDayFieldDiv label,
    .superadminDivBox label {
        width: 100%;
        text-align: left;
    }
    .hspwrkdetal-ofcehour {
        width: 100%;
    }
    .hspworkdetails-wrapper {
        padding: 5px 15px;
    }
    .offcehrDayFieldDiv select {
        width: 268px;
    }
    .hsptimefieldDivMob-time select {
        width: 84px;
    }
    .hsptimefieldDivMob-time label {
        width: 50px;
        font-size: 13px;
    }
    /* .serviceselectHspMob{
       width: 260px !important;
   } */
    .serviceslabelHspMob {
        width: 90px !important;
        font-size: 13px !important;
    }
    /* .serviceselectHspMob-timeservicdetl{
       width: 73px !important;
   } */
    .hspManagement-GoogleMap iframe {
        padding-bottom: 0;
    }
    .hspInfoSubhead span {
        color: red;
        font-size: 20px;
    }
    .hspInfoSubhead {
        font-size: 20px;
    }
    .breadcrum-adminMangmnt a {
        font-size: 10px;
        padding: 5px 15px;
    }
    .seofieldDivhsp-textarea {
        width: 100%;
        min-width: auto;
    }
    .hspInfofieldinputMobmap {
        width: 156px;
    }
    .linkhspgenerteInput {
        width: 89%;
        margin: 0 4px 5px;
    }
    .linkhspGenerateBtn {
        width: 277px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .linkhspgenerteInput {
        width: 100%;
        margin-top: 5px;
        margin-left: 0;
    }
    .rmvbtn-imgeuplodHsp {
        right: 38px;
        top: 14px;
    }
    .ph-uploadedimg img {
        width: 60%;
        margin: 6px;
    }
    .ph-imglist .smssetng-btn {
        text-align: left;
    }
    .ph-uploadedimg {
        width: 85%;
        text-align: center;
        margin: auto;
    }
    .ph-addimagewraper .smssetng-btn {
        text-align: center;
    }
    .hidden-menuImgUplod {
        height: 244px;
        min-width: auto;
        width: 100%;
    }
    .doctorhspwrkdetal-ofcehour {
        width: 100%;
        padding-bottom: 23px;
    }
    .doctroffcehrDaydiv label {
        width: 59px;
        font-size: 13px;
    }
    .doctroffcehrDaydiv select {
        width: 49px;
        height: 32px;
    }
    .sheduledivdoctr {
        width: 49%;
        text-align: right;
        font-size: 13px;
    }
    .doctorofcrhr-contentdiv .label {
        width: 120px;
    }
    .doctorofcrhr-contentdiv select {
        width: 169px;
    }
    .educationdoctrlabelwidth {
        width: 99px !important;
        text-align: left !important;
    }
    .eductninputwidthdctr {
        width: 160px !important;
        padding-left: 7px;
    }
    .educationlabelwidthdctr {
        width: 81px !important;
        text-align: left !important;
    }
    .educationlabelwidthyear {
        width: 89px !important;
        padding-left: 7px;
        text-align: left !important;
    }
    .hsptimefieldDivMob-time label {
        width: 90px;
        /* text-align: left; */
    }
    .smssetng-cancelbtn {
        padding: 8px 14px;
        font-size: 12px;
    }
    .smssetng-svebtn {
        font-size: 12px;
        padding: 8px 26px;
    }
    .procedufleupload-width {
        width: auto !important;
    }
    .hsptimefieldDivMob-time {
        width: 100%;
    }
    .educationdoctr-contentdiv {
        width: 100%;
    }
    .procedreouterPadng {
        padding: 0 !important;
    }
    .prInpwidthMob {
        width: 84px !important;
    }
    .hsptaltimeHead {
        padding: 5px 15px;
    }
    .procedhsp-contentdiv {
        width: auto;
    }
    input.proinputMobwidt {
        width: 211px;
    }
    .addHspPage-Wrapper .react-tabs__tab {
        margin-top: 7px;
        padding: 5px 13px;
        margin-right: 5px;
        font-size: 12px;
    }
    .select.hspInfofieldinput {
        width: 100%;
    }
    input.hspInfofieldinput {
        width: 100% !important;
    }
    select.hspInfofieldinput {
        width: 100%;
    }
    .superadminDivBox {
        width: 100%;
    }
    .hspinfo-adminselct-mob label {
        width: 52%;
    }
    .offcehrDay select {
        width: 80px;
    }
    .addimgeDivHsp {
        position: absolute;
        right: 25px;
    }
    .seofieldDivhsp-textarea {
        font-size: 12px;
        text-indent: unset;
    }
    .linkhspGenerateBtn {
        margin-left: 0;
    }
    .profield-div label {
        width: 104px;
    }
    .health_record {
        width: 100%;
    }
    .siteset-textareafield {
        width: 100%;
    }
    .dash_linkHead {
        font-size: 12px !important;
    }
    .dash_linkbutton button {
        font-size: 12px !important;
        padding: 5px 10px !important;
    }
    .react_datepicker .react-datepicker-wrapper {
        width: 100% !important;
    }
    .css-6lx3xi-control {
        width: 100% !important;
    }
    .custome_input {
        margin-bottom: 5px !important
    }
    .border_btn {
        min-width: auto !important;
        padding: 6px 8px;
    }
    .bg_ddd .col-md-2 {
        margin-bottom: 0px !important;
    }
    .bg_ddd .col-md-1 {
        justify-content: end;
    }
    .coupn-list-name {
        margin: 0 auto;
        width: 260px;
        display: block;
    }
    .coupn-list-descrptn {
        width: 100%;
        margin: 0 auto;
        display: block;
    }
    .available-coupn-list {
        text-align: center;
    }
}

@media (max-width: 576px) {
    .list_tab,
    .accordion__button {
        font-size: 13px;
        padding: 4px 5px;
    }
}

@media (max-width: 360px) {
    .smssetngCheckbox .smsItem label {
        width: 73%;
    }
    /* .hsptimefieldDivMob-time label {
       width: 40px;
    } */
    .hsptimefieldDivMob-time select {
        width: 60px;
    }
    .serviceslabelHspMob {
        width: 45px !important;
    }
}